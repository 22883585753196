import React from "react";
import { Route } from "react-router-dom";
import { AuthRedirect } from "./AuthRedirect";
import { ApplicationPaths, LoginActions, LogoutActions } from "./ApiAuthorizationConstants";

const authRedirect = (action: string) => <AuthRedirect action={action} />;

export default class AuthRoutes extends React.PureComponent {
    render(): JSX.Element {
        return (
            <>
                <Route
                    path={ApplicationPaths.LoginFailed}
                    render={() => authRedirect(LoginActions.LoginFailed)}
                />
                <Route
                    path={ApplicationPaths.LoginCallback}
                    render={() => authRedirect(LoginActions.LoginCallback)}
                />
                <Route
                    path={ApplicationPaths.Logout}
                    render={() => authRedirect(LoginActions.Login)}
                />
                <Route
                    path={ApplicationPaths.LogoutCallback}
                    render={() => authRedirect(LogoutActions.LogoutCallback)}
                />
                {/* <Route
                    path={ApplicationPaths.LoggedOut}
                    render={() => logoutAction(LogoutActions.LoggedOut)}
                />
                <Route
                    path={ApplicationPaths.Register}
                    render={() => authRedirect(LoginActions.Register)}
                /> */}
            </>
        );
    }
}
