import React, { ReactNode } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import {
    Table,
    Paper,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    Hidden,
    HiddenProps,
} from "@material-ui/core";
import { Record } from "../GenericInterfaces/GenericInterfaces";
import { useListPager } from "./UseListPager";
import EmptyList from "./EmptyList";

type StringsAndNumberKeys<T> = {
    [key in keyof T]: T[key] extends string | number | Date ? key : never;
}[keyof T];

type OnlyStringAndNumber<T> = { [key in StringsAndNumberKeys<T>]: string | number | Date };

interface HeadCell<T> {
    disablePadding: boolean;
    id?: keyof OnlyStringAndNumber<T>;
    label?: string;
    alignRight: boolean;
    hidden?: HiddenProps;
}

export type Header<T> = HeadCell<T>[];

interface EnhancedTableProps<T> {
    headCells: Header<T>;
    classes: ReturnType<typeof useStyles>;
    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof T) => void;
    order: Order;
    orderBy: keyof T;
}

type Order = "asc" | "desc";

interface IEnhancedTableProps<T> {
    header: Header<T>;
    rows: T[];
    rowRender: (element: T) => JSX.Element;
    initOrderBy?: keyof OnlyStringAndNumber<T>;
    initOrder?: Order;
    isXs?: boolean;
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator<T>(order: Order, orderBy: keyof T): (a: T, b: T) => number {
    return order === "desc"
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number): T[] {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const EnhancedTableHead = <T extends Record>({
    headCells,
    classes,
    order,
    orderBy,
    onRequestSort,
}: EnhancedTableProps<T>): JSX.Element => {
    const createSortHandler = (property: keyof T) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell, index) => {
                    let tableCell: ReactNode;
                    if (headCell.id !== undefined) {
                        tableCell = (
                            <TableCell
                                className={classes.tableHead}
                                key={headCell.id.toString()}
                                align={headCell.alignRight ? "right" : "left"}
                                padding={headCell.disablePadding ? "none" : "default"}
                                sortDirection={orderBy === headCell.id ? order : false}
                            >
                                <TableSortLabel
                                    active={orderBy === headCell.id}
                                    direction={orderBy === headCell.id ? order : "asc"}
                                    onClick={createSortHandler(headCell.id)}
                                >
                                    {headCell.label}
                                    {orderBy === headCell.id ? (
                                        <span className={classes.visuallyHidden}>
                                            {order === "desc"
                                                ? "sorted descending"
                                                : "sorted ascending"}
                                        </span>
                                    ) : null}
                                </TableSortLabel>
                            </TableCell>
                        );
                    } else {
                        tableCell = (
                            <TableCell
                                key={headCell.label || index}
                                align={headCell.alignRight ? "right" : "left"}
                                padding={headCell.disablePadding ? "none" : "default"}
                            >
                                {headCell.label ? headCell.label : null}
                            </TableCell>
                        );
                    }

                    if (headCell.hidden === undefined) return tableCell;

                    return (
                        <Hidden key={headCell.label} {...headCell.hidden}>
                            {tableCell}
                        </Hidden>
                    );
                })}
            </TableRow>
        </TableHead>
    );
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        visuallyHidden: {
            border: 0,
            clip: "rect(0 0 0 0)",
            height: 1,
            margin: -1,
            overflow: "hidden",
            padding: 0,
            position: "absolute",
            top: 20,
            width: 1,
        },
        tableHead: {
            color: theme.palette.primary.main,
            fontWeight: "bold",
        },
        noShadow: {
            boxShadow: "none",
        },
    })
);

export const EnhancedTable = <T extends Record>({
    header,
    rows,
    rowRender,
    initOrderBy,
    initOrder,
    isXs,
}: IEnhancedTableProps<T>): JSX.Element => {
    const classes = useStyles();
    const [order, setOrder] = React.useState<Order>(initOrder || "asc");
    const [orderBy, setOrderBy] = React.useState<keyof T>(initOrderBy || "id");
    const [orderedRows, setOrderedRows] = React.useState<T[]>(
        stableSort(rows, getComparator(order, orderBy))
    );

    const { pagedRecords, pagination, setPage } = useListPager(orderedRows);

    // Reset table on new rows
    React.useEffect(() => {
        // Do not update state as the returned element will be <EmptyList />
        if (rows.length !== 0) {
            setOrderedRows(() => stableSort(rows, getComparator(order, orderBy)));
            setPage(() => 1);
        }
    }, [rows]);

    const handleRequestSort = React.useCallback(
        (event: React.MouseEvent<unknown>, property: keyof T) => {
            setOrderBy((prevProp) => {
                const isSorting = prevProp === property;
                if (isSorting) {
                    setOrder((prevOrder) => {
                        const nextOrder = prevOrder === "asc" ? "desc" : "asc";

                        setOrderedRows((oldRows) =>
                            stableSort(oldRows, getComparator(nextOrder, property))
                        );

                        return nextOrder;
                    });
                } else {
                    setOrder((prevOrder) => {
                        setOrderedRows((oldRows) =>
                            stableSort(oldRows, getComparator(prevOrder, property))
                        );
                        return prevOrder;
                    });
                }

                return property;
            });
        },
        []
    );

    if (rows.length === 0) {
        return <EmptyList />;
    }

    return (
        <>
            <TableContainer
                component={Paper}
                className={isXs !== undefined && isXs === true ? classes.noShadow : ""}
            >
                <Table>
                    <EnhancedTableHead
                        headCells={header}
                        classes={classes}
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                    />
                    <TableBody>{pagedRecords.map((row) => rowRender(row))}</TableBody>
                </Table>
            </TableContainer>
            {pagination}
        </>
    );
};
