import { GridSize } from "@material-ui/core";
import { ReactComponent as FragranceLogo } from "../../../../Assets/Img/icons/fragrance.svg";
import { ReactComponent as StoreLogo } from "../../../../Assets/Img/store.svg";
import { ReactComponent as FreeConceptLogo } from "../../../../Assets/Img/icons/free-concept.svg";
import { ReactComponent as FragranceMatching } from "../../../../Assets/Img/icons/scope.svg";
import { ReactComponent as InLineFinalProduct } from "../../../../Assets/Img/icons/bottle.svg";

import { ReactComponent as FemaleLogo } from "../../../../Assets/Img/icons/female.svg";
import { ReactComponent as MaleLogo } from "../../../../Assets/Img/icons/male.svg";
import { ReactComponent as UnisexLogo } from "../../../../Assets/Img/icons/unisex.svg";

import { ReactComponent as AromaticLogo } from "../../../../Assets/Img/icons/aromatic.svg";
import { ReactComponent as ChypreLogo } from "../../../../Assets/Img/icons/chypre.svg";
import { ReactComponent as CitrusLogo } from "../../../../Assets/Img/icons/citrus.svg";
import { ReactComponent as ConiphLogo } from "../../../../Assets/Img/icons/coniph.svg";
import { ReactComponent as FloralLogo } from "../../../../Assets/Img/icons/floral.svg";
import { ReactComponent as FougereLogo } from "../../../../Assets/Img/icons/fougere.svg";
import { ReactComponent as FruityLogo } from "../../../../Assets/Img/icons/fruity.svg";
import { ReactComponent as HerbalLogo } from "../../../../Assets/Img/icons/herbal.svg";
import { ReactComponent as GourmandLogo } from "../../../../Assets/Img/icons/gourmand.svg";
import { ReactComponent as GreenLogo } from "../../../../Assets/Img/icons/green.svg";
import { ReactComponent as OrientalLogo } from "../../../../Assets/Img/icons/oriental.svg";
import { ReactComponent as LeatheryLogo } from "../../../../Assets/Img/icons/leathery.svg";
import { ReactComponent as MarineLogo } from "../../../../Assets/Img/icons/marine.svg";
import { ReactComponent as UnKnownLogo } from "../../../../Assets/Img/icons/unknown.svg";
import { ReactComponent as SpicyLogo } from "../../../../Assets/Img/icons/spicy.svg";
import { ReactComponent as WoodyLogo } from "../../../../Assets/Img/icons/woody.svg";
import { GetCountry } from "../../Common/Components/Country";

import { ReactComponent as TransparentLogo } from "../../../../Assets/Img/icons/transparent.svg";
import { ReactComponent as OpaqueLogo } from "../../../../Assets/Img/icons/opaque.svg";

export const sampleReceivedLabels = [
    {
        label: "Fragrance matching",
        value: "Fragrance matching",
        logo: <FragranceMatching />,
    },
    {
        label: "In line with a Fragrance",
        value: "In line with a Fragrance",
        logo: <FragranceLogo />,
    },
    {
        label: "Final product / Benchmark matching",
        value: "Final product / Benchmark matching",
        logo: <StoreLogo />,
    },
    {
        label: "In line with final product / benchmark",
        value: "In line with final product / benchmark",
        logo: <InLineFinalProduct />,
    },
    {
        label: "Free concept",
        value: "Free concept",
        logo: <FreeConceptLogo />,
    },
];

export const genderLabels = [
    {
        value: "Female",
        label: "Female",
        logo: <FemaleLogo />,
    },
    {
        value: "Male",
        label: "Male",
        logo: <MaleLogo />,
    },
    {
        value: "Unisex",
        label: "Unisex",
        logo: <UnisexLogo />,
    },
    {
        value: "N/A",
        label: "N/A",
    },
];

export const productApperanceLabels = [
    {
        label: "Transparent",
        value: "Transparent",
        logo: <TransparentLogo />,
    },
    {
        label: "Opaque",
        value: "Opaque",
        logo: <OpaqueLogo />,        
    },
];

export const temperatureMarks = [
    {
        value: 0,
        label: "0",
    },
    {
        value: 10,
        label: "4ºC",
    },
    {
        value: 20,
        label: "40ºC*",
    },
    {
        value: 30,
        label: "50ºC**",
    },
    {
        value: 40,
        label: "UV",
    },
];

export const testDuration = [
    {
        value: 0,
        label: "0",
    },
    {
        value: 10,
        label: "15",
    },
    {
        value: 20,
        label: "30",
    },
    {
        value: 30,
        label: "40",
    },
    {
        value: 40,
        label: "60",
    },
    {
        value: 50,
        label: "90",
    },
    {
        value: 60,
        label: "120",
    },
    {
        value: 70,
        label: "150",
    },
];

export const FragranceLabels = [
    {
        value: 0,
        label: "0"
    },
    {
        value: 10,
        label: "5 gr."
    },
    {
        value: 20,
        label: "10 gr."
    },
    {
        value: 30,
        label: "20 gr."
    },
    {
        value: 40,
        label: "50 gr."
    },
    {
        value: 50,
        label: "100 gr."
    },
    {
        value: 60,
        label: "1 kg."
    },
];

export const allergenLabels = [
    {
        value: "Non-relevant",
        label: "Non-relevant"
    },
    {
        value: "Non-labelled Allergens",
        label: "Non-labelled Allergens"
    },
    {
        value: "100% Allergen Free",
        label: "100% Allergen Free"
    },
];

export const FormatValue = (val: any, controllerName?: string, unknownAge: any = "") => {
    let value = val;

    if (value === "" || value === null || value === undefined) 
    {
        value = "-";
    }
    else 
    {
        value = value.toString();
        
        switch (controllerName) 
        {
            case "Cosmos_Ecocert":
            case "Flower_Ecolabel":
            case "California":
            case "South_Korea":
            case "Blauer_Engel":
            case "Nordic_Swan":
            case "Oko_Test":
            case "Blauer_Engel":
            case "Bra_Miljoval":
            case "IFRA":
            case "VOC":
            case "Need_Stability_Test":
            case "Finished_Product":
            case "Customer_Base":
            case "Olfactory_Piramid":
            case "Custom_Presentation":
            case "Update_Presentation":
            case "Trend_Report":
            case "Market_Research":
                if (value === "1" || value === "true")
                {
                    value = "Yes";
                }
                else 
                {
                    value = "-";
                }
                break;
            case "Oven_Temperature":
                switch (val)
                {
                    case 10:
                        value = "4ºC";
                        break;
                    case 20:
                        value = "40ºC (30 days)";
                        break;
                    case 30:
                        value = "50ºC (15 days)";                            
                        break;
                    case 40:
                        value = "UV";                            
                        break;
                    default:
                        break;
                }
                break;
            case "Test_Duration":
                switch (val)
                {
                    case 10:
                        value = "15 days";
                        break;
                    case 20:
                        value = "30 days";
                        break;
                    case 30:
                        value = "40 days";
                        break;
                    case 40:
                        value = "60 days";
                        break;
                    case 50:
                        value = "90 days";
                        break;
                    case 60:
                        value = "120 days";
                        break;
                    case 70:
                        value = "150 days";
                        break;
                    default:
                        break;
                }
                break;
            case "Target_Age_Range":               
                if (unknownAge === "1" || unknownAge === "true" || unknownAge === true)
                {
                    value = "Unknown";
                }
                else 
                {
                    value = value.replace(",", " to ");
                    value += " years old";
                }
                break;
            case "PH":
                value = value.replace(",", " - ");
                break;
            case "Country":
                value = GetCountry(value);
                break;
            case "Price_KG":
            case "Fragrance_Cost":
                if (value === "0")
                {
                    value = "-";
                }
                break;
            case "Fragrance_Qty":
                switch (val)
                {
                    case 0:
                        value = "0";
                        break;
                    case 10:
                        value = "5 gr.";
                        break;
                    case 20:
                        value = "10 gr.";
                        break;
                    case 30:
                        value = "20 gr.";
                        break;
                    case 40:
                        value = "50 gr.";
                        break;
                    case 50:
                        value = "100 gr.";
                        break;
                    case 60:
                        value = "1 kg.";
                        break;
                    default:
                        break;
                }
                break;
            default:
                break;
        }
    }
    return value;
}

// #region Checkboxes
export const olfactoryProfileLabels = [
    {
        label: "Aromatic",
        index: 0,
        value: <AromaticLogo />,
    },
    {
        label: "Chypre",
        index: 1,
        value: <ChypreLogo />,
    },
    {
        label: "Citrus",
        index: 2,
        value: <CitrusLogo />,
    },
    {
        label: "Coniferous",
        index: 3,
        value: <ConiphLogo />,
    },
    {
        label: "Floral",
        index: 4,
        value: <FloralLogo />,
    },
    {
        label: "Fougère",
        index: 5,
        value: <FougereLogo />,
    },
    {
        label: "Fruity",
        index: 6,
        value: <FruityLogo />,
    },
    {
        label: "Gourmand",
        index: 7,
        value: <GourmandLogo />,
    },
    {
        label: "Green",
        index: 8,
        value: <GreenLogo />,
    },
    {
        label: "Herbaceous",
        index: 9,
        value: <HerbalLogo />,
    },
    {
        label: "Leathery",
        index: 10,
        value: <LeatheryLogo />,
    },
    {
        label: "Marine",
        index: 11,
        value: <MarineLogo />,
    },
    {
        label: "Oriental",
        index: 12,
        value: <OrientalLogo />,
    },
    {
        label: "Spicy",
        index: 13,
        value: <SpicyLogo />,
    },
    {
        label: "Woody",
        index: 14,
        value: <WoodyLogo />,
    },
    {
        xs: 12 as GridSize,
        label: "Unknown",
        index: 15,
        value: <UnKnownLogo />,
    },
];

export const colourLabels = [
    {
        index: 0,
        label: "Yellow",
        value: "#fcf738",
    },
    {
        index: 1,
        label: "Orange",
        value: "#fa9810",
    },
    {
        index: 2,
        label: "Brown",
        value: "#754c24",
    },
    {
        index: 3,
        label: "Red",
        value: "#fd2813",
    },
    {
        index: 4,
        label: "Pink",
        value: "#ed53f2",
    },
    {
        index: 5,
        label: "Violet",
        value: "#860aae",
    },
    {
        index: 6,
        label: "Blue",
        value: "#0a92cc",
    },
    {
        index: 7,
        label: "Green",
        value: "#64b035",
    },
    {
        index: 8,
        label: "White",
        value: "#FFFFFF",
    },
    {
        index: 9,
        label: "Grey",
        value: "#b3b3b3",
    },
    {
        index: 10,
        label: "Black",
        value: "#000000",
    },
    {
        index: 11,
        label: "N/A",
        value: "#FFFFFF",
    },
];
// #endregion