import React from "react";
import { Close } from "@material-ui/icons";
import * as Core from "@material-ui/core";
import { ProjectService } from "../../../Api/Services/ProjectService";
import { ProjectDTO, ProjectFullDTO } from "../../../Api/Models/ProjectModels";
import * as FormFunctions from "../ProjectForm/FormHelpersFunctions";

interface IProjectSummaryProps {
    isFormOpen: boolean;
    closeForm: () => void;
    projectRecord: ProjectDTO | undefined;
}

const ProjectSummaryDialog = ({
    isFormOpen,
    closeForm,
    projectRecord,
}: IProjectSummaryProps): JSX.Element => {
    const theme = Core.useTheme();
    const fullScreen = Core.useMediaQuery(theme.breakpoints.down("sm"));
    const [project, setProject] = React.useState<ProjectFullDTO | null>(null);
    const handleClose = React.useCallback(() => {
        closeForm();
    }, []);

    React.useEffect(() => {
        if (projectRecord !== undefined) 
        {
            ProjectService.getProject(projectRecord.id).then((res) => {
                setProject(res);
            });
        }
    }, [isFormOpen]);

    return (
        <>
            <Core.Dialog
                open={isFormOpen}
                fullScreen={fullScreen}
                fullWidth={true}
                maxWidth="sm"
                disableBackdropClick
                disableEscapeKeyDown
            >
                <Core.DialogTitle>
                    <div style={{ position: "absolute", right: "5px", top: "5px" }}>
                        <Core.IconButton onClick={handleClose}>
                            <Close fontSize="large" />
                        </Core.IconButton>
                    </div>
                    <div>
                        <Core.Typography variant="h4" color="primary">
                            Report preview
                        </Core.Typography>
                    </div>
                </Core.DialogTitle>
                <Core.DialogContent>
                    {/* -------------------------------- PROJECT DATA ----------------------------------------------- */}
                    <div>
                        <Core.Typography variant="h5" color="primary">Project Data</Core.Typography>
                        <div>
                            <div>
                                <Core.Typography variant="body1" color="textSecondary"> Name </Core.Typography>
                                
                                <Core.Typography variant="h6" color="textPrimary">
                                    {FormFunctions.FormatValue(project?.Name)}
                                </Core.Typography>
                            </div>
                            
                            <div>
                                <Core.Typography variant="body1" color="textSecondary"> Client </Core.Typography>
                                
                                <Core.Typography variant="h6" color="textPrimary">
                                    {FormFunctions.FormatValue(project?.Client_Name)}
                                </Core.Typography>
                            </div>

                            <div>
                                <Core.Typography variant="body1" color="textSecondary"> Contact Person </Core.Typography>
                                
                                <Core.Typography variant="h6" color="textPrimary">
                                    {FormFunctions.FormatValue(project?.Contact_Person)}
                                </Core.Typography>
                            </div>
                            <div>
                                <Core.Typography variant="body1" color="textSecondary"> Country </Core.Typography>

                                <Core.Typography variant="h6" color="textPrimary">
                                    {FormFunctions.FormatValue(project?.Country, "Country")}
                                </Core.Typography>
                            </div>
                            
                            <div>
                                <Core.Typography variant="body1" color="textSecondary"> Product type </Core.Typography>
                                
                                <Core.Typography variant="h6" color="textPrimary">
                                    {FormFunctions.FormatValue(project?.ProductType)}
                                </Core.Typography>
                            </div>
                        </div>
                    </div>

                    {/* -------------------------------- GENERAL INFORMATION ---------------------------------------- */}
                    <div>
                        <Core.Typography variant="h5" color="primary">General Information</Core.Typography>
                        <div>
                            <div>
                                <Core.Typography variant="body1" color="textSecondary">Price per kg of Fragrance</Core.Typography>
                                <Core.Typography variant="h6" color="textPrimary">
                                    {FormFunctions.FormatValue(project?.Price_KG, "Price_KG")}{FormFunctions.FormatValue(project?.Price_KG_Currency)}
                                </Core.Typography>
                            </div>
                            
                            <div>
                                <Core.Typography variant="body1" color="textSecondary">Fragrance cost per ton of finished product</Core.Typography>
                                <Core.Typography variant="h6" color="textPrimary">
                                    {FormFunctions.FormatValue(project?.Fragrance_Cost, "Fragrance_Cost")}{FormFunctions.FormatValue(project?.Fragrance_Cost_Currency)}
                                </Core.Typography>
                            </div>
                        
                            <div>
                                <Core.Typography variant="body1" color="textSecondary">Sample received</Core.Typography>
                                <Core.Typography variant="h6" color="textPrimary">
                                    {FormFunctions.FormatValue(project?.Sample_Received)}
                                </Core.Typography>
                            </div>
                            
                            <div>
                                <Core.Typography variant="body1" color="textSecondary">Product reference</Core.Typography>
                                <Core.Typography variant="h6" color="textPrimary">
                                    {FormFunctions.FormatValue(project?.Product_Reference)}
                                </Core.Typography>
                            </div>

                            <div>
                                <Core.Typography variant="body1" color="textSecondary">Other Comments</Core.Typography>
                                <Core.Typography variant="h6" color="textPrimary">
                                    {FormFunctions.FormatValue(project?.Price_Purchasing_Extra)}
                                </Core.Typography>
                            </div>
                        </div>
                    </div>

                    {/* -------------------------------- APPLICATION ------------------------------------------------ */}
                    <div>
                        <Core.Typography variant="h5" color="primary">Application</Core.Typography>
                        <div>

                            <div>
                                <Core.Typography variant="body1" color="textSecondary">Olfactory profiles</Core.Typography>
                                <Core.Typography variant="h6" color="textPrimary">
                                    {FormFunctions.FormatValue(project?.Olfactory_Profile)}
                                </Core.Typography>
                            </div>

                            <div>
                                <Core.Typography variant="body1" color="textSecondary">Target end customer</Core.Typography>
                                <Core.Typography variant="h6" color="textPrimary">
                                    {FormFunctions.FormatValue(project?.Target_End_Customer)}
                                </Core.Typography>
                            </div>

                            <div>
                                <Core.Typography variant="body1" color="textSecondary">
                                    Target age
                                </Core.Typography>
                                <Core.Typography variant="h6" color="textPrimary">
                                    {FormFunctions.FormatValue(project?.Target_Age_Range, "Target_Age_Range", project?.Unknown_Age)}
                                </Core.Typography>
                            </div>

                            <div>
                                <Core.Typography variant="body1" color="textSecondary">Other comments
                                </Core.Typography>
                                <Core.Typography variant="h6" color="textPrimary">
                                    {FormFunctions.FormatValue(project?.Olfactory_Extra)}
                                </Core.Typography>
                            </div>
                        </div>
                    </div>

                    {/* -------------------------------- SAMPLES REQUIRED ------------------------------------------- */}
                    <div>
                        <Core.Typography variant="h5" color="primary">Samples required</Core.Typography>
                        <div>
                            <div>
                                <Core.Typography variant="body1" color="textSecondary">Colour</Core.Typography>
                                <Core.Typography variant="h6" color="textPrimary">
                                    {FormFunctions.FormatValue(project?.Colour)}
                                </Core.Typography>
                            </div>
                            <div>
                                <Core.Typography variant="body1" color="textSecondary">Other comments</Core.Typography>
                                <Core.Typography variant="h6" color="textPrimary">
                                    {FormFunctions.FormatValue(project?.Colour_Extra)}
                                </Core.Typography>
                            </div>
                        </div>
                    </div>

                    {/* -------------------------------- SELECTION -------------------------------------------------- */}
                    <div>
                        <Core.Typography variant="h5" color="primary">Selection</Core.Typography>
                        <div>
                            <div>
                                <Core.Typography variant="body1" color="textSecondary">Finished product apperance</Core.Typography>
                                <Core.Typography variant="h6" color="textPrimary">
                                    {FormFunctions.FormatValue(project?.Finished_Product_Appearance)}
                                </Core.Typography>
                            </div>

                            <div>
                                <Core.Typography variant="body1" color="textSecondary">pH end product</Core.Typography>
                                <Core.Typography variant="h6" color="textPrimary">
                                    {FormFunctions.FormatValue(project?.PH, "PH")}
                                </Core.Typography>
                            </div>

                            <div>
                                <Core.Typography variant="body1" color="textSecondary">Dosage (%)</Core.Typography>
                                <Core.Typography variant="h6" color="textPrimary">
                                    {FormFunctions.FormatValue(project?.Dosage)}
                                </Core.Typography>
                            </div>

                            <div>
                                <Core.Typography variant="body1" color="textSecondary">Customer base</Core.Typography>

                                <Core.Typography variant="h6" color="textPrimary">
                                    {FormFunctions.FormatValue(project?.Customer_Base,"Customer_Base")}
                                </Core.Typography>
                            </div>

                            <div>
                                <Core.Typography variant="body1" color="textSecondary">Customer base quantity</Core.Typography>
                                <Core.Typography variant="h6" color="textPrimary">
                                    {FormFunctions.FormatValue(project?.Customer_Base_Qty)}
                                </Core.Typography>
                            </div>

                            <div>
                                <Core.Typography variant="body1" color="textSecondary">Needs Stability Test</Core.Typography>
                                <Core.Typography variant="h6" color="textPrimary">
                                    {FormFunctions.FormatValue(project?.Need_Stability_Test,"Need_Stability_Test")}
                                </Core.Typography>
                            </div>

                            <div>
                                <Core.Typography variant="body1" color="textSecondary">Oven Temperature</Core.Typography>

                                <Core.Typography variant="h6" color="textPrimary">
                                    {FormFunctions.FormatValue(project?.Oven_Temperature,"Oven_Temperature")}
                                </Core.Typography>
                            </div>

                            <div>
                                <Core.Typography variant="body1" color="textSecondary">Test duration</Core.Typography>
                                <Core.Typography variant="h6" color="textPrimary">
                                    {FormFunctions.FormatValue(project?.Test_Duration,"Test_Duration")}
                                </Core.Typography>
                            </div>

                            <div>
                                <Core.Typography variant="body1" color="textSecondary">Other Comments</Core.Typography>
                                <Core.Typography variant="h6" color="textPrimary">
                                    {FormFunctions.FormatValue(project?.Stability_Extra)}
                                </Core.Typography>
                            </div>
                        </div>
                    </div>

                    {/* -------------------------------- REGULATORY ------------------------------------------------- */}
                    <div>
                        <Core.Typography variant="h5" color="primary">Regulatory</Core.Typography>
                        <div>
                            <div>
                                <Core.Typography variant="body1" color="textSecondary">Cosmos Ecocert</Core.Typography>
                                
                                <Core.Typography variant="h6" color="textPrimary">
                                    {FormFunctions.FormatValue(project?.Cosmos_Ecocert,"Cosmos_Ecocert")}
                                </Core.Typography>
                            </div>
                            
                            <div>
                                <Core.Typography variant="body1" color="textSecondary">Flower Ecolabel</Core.Typography>
                                
                                <Core.Typography variant="h6" color="textPrimary">
                                    {FormFunctions.FormatValue(project?.Flower_Ecolabel,"Flower_Ecolabel")}
                                </Core.Typography>
                            </div>
                            
                            <div>
                                <Core.Typography variant="body1" color="textSecondary">California</Core.Typography>

                                <Core.Typography variant="h6" color="textPrimary">
                                    {FormFunctions.FormatValue(project?.California,"California")}
                                </Core.Typography>
                            </div>
                            
                            <div>
                                <Core.Typography variant="body1" color="textSecondary">South Korea</Core.Typography>
                                
                                <Core.Typography variant="h6" color="textPrimary">
                                    {FormFunctions.FormatValue(project?.South_Korea,"South_Korea")}
                                </Core.Typography>
                            </div>
                            
                            <div>
                                <Core.Typography variant="body1" color="textSecondary">Blauer Engel</Core.Typography>

                                <Core.Typography variant="h6" color="textPrimary">
                                    {FormFunctions.FormatValue(project?.Blauer_Engel,"Blauer_Engel")}
                                </Core.Typography>
                            </div>
                            
                            <div>
                                <Core.Typography variant="body1" color="textSecondary">Nordic Swan</Core.Typography>
                                
                                <Core.Typography variant="h6" color="textPrimary">
                                    {FormFunctions.FormatValue(project?.Nordic_Swan,"Nordic_Swan")}
                                </Core.Typography>
                            </div>
                            
                            <div>
                                <Core.Typography variant="body1" color="textSecondary">ÖKO TEST</Core.Typography>
                                
                                <Core.Typography variant="h6" color="textPrimary">
                                    {FormFunctions.FormatValue(project?.Oko_Test,"Oko_Test")}
                                </Core.Typography>
                            </div>
                            
                            <div>
                                <Core.Typography variant="body1" color="textSecondary">Bra Miljöval</Core.Typography>
                                
                                <Core.Typography variant="h6" color="textPrimary">
                                    {FormFunctions.FormatValue(project?.Bra_Miljoval,"Bra_Miljoval")}
                                </Core.Typography>
                            </div>
                            
                            <div>
                                <Core.Typography variant="body1" color="textSecondary">IFRA</Core.Typography>
                                
                                <Core.Typography variant="h6" color="textPrimary">
                                    {FormFunctions.FormatValue(project?.IFRA,"IFRA")}
                                </Core.Typography>
                            </div>
                            
                            <div>
                                <Core.Typography variant="body1" color="textSecondary">VOC</Core.Typography>
                                
                                <Core.Typography variant="h6" color="textPrimary">
                                    {FormFunctions.FormatValue(project?.VOC,"VOC")}
                                </Core.Typography>
                            </div>
                            
                            <div>
                                <Core.Typography variant="body1" color="textSecondary">Allergen restrictions</Core.Typography>

                                <Core.Typography variant="h6" color="textPrimary">
                                    {FormFunctions.FormatValue(project?.Allergen_Restrictions)}
                                </Core.Typography>
                            </div>

                            <div>
                                <Core.Typography variant="body1" color="textSecondary">Other Comments</Core.Typography>

                                <Core.Typography variant="h6" color="textPrimary">
                                    {FormFunctions.FormatValue(project?.Regulations_Extra)}
                                </Core.Typography>
                            </div>
                        </div>
                    </div>

                    {/* -------------------------------- PROJECT DEADLINE ------------------------------------------- */}
                    <div>
                        <Core.Typography variant="h5" color="primary">Project Deadline</Core.Typography>
                        <div>
                            <div>
                                <Core.Typography variant="body1" color="textSecondary">Deadline</Core.Typography>
                                
                                <Core.Typography variant="h6" color="textPrimary">
                                    {FormFunctions.FormatValue(project?.Deadline)}
                                </Core.Typography>
                            </div>

                            <div>
                                <Core.Typography variant="body1" color="textSecondary">Maximum Fragrance samples to submit (per variant)</Core.Typography>
                                
                                <Core.Typography variant="h6" color="textPrimary">
                                    {FormFunctions.FormatValue(project?.Maximum_Samples_Submit)}
                                </Core.Typography>
                            </div>

                            <div>
                                <Core.Typography variant="body1" color="textSecondary">How much fragrance oil do you want to receive from us?</Core.Typography>
                                
                                <Core.Typography variant="h6" color="textPrimary">
                                    {FormFunctions.FormatValue(project?.Fragrance_Qty,"Fragrance_Qty")}
                                </Core.Typography>
                            </div>

                            <div>
                                <Core.Typography variant="body1" color="textSecondary">Finished Product</Core.Typography>
                                
                                <Core.Typography variant="h6" color="textPrimary">
                                    {FormFunctions.FormatValue(project?.Finished_Product,"Finished_Product")}
                                </Core.Typography>
                            </div>
                        </div>
                    </div>

                    {/* -------------------------------- MARKETING -------------------------------------------------- */}
                    <div>
                        <Core.Typography variant="h5" color="primary">Marketing</Core.Typography>
                        <div>
                            <div>
                                <Core.Typography variant="body1" color="textSecondary">Olfactory pyramid</Core.Typography>
                                
                                <Core.Typography variant="h6" color="textPrimary">
                                    {FormFunctions.FormatValue(project?.Olfactory_Piramid, "Olfactory_Piramid")}
                                </Core.Typography>
                            </div>
                            <div>
                                <Core.Typography variant="body1" color="textSecondary">Customized presentation</Core.Typography>
                                
                                <Core.Typography variant="h6" color="textPrimary">
                                    {FormFunctions.FormatValue(project?.Custom_Presentation, "Custom_Presentation")}
                                </Core.Typography>
                            </div>
                            <div>
                                <Core.Typography variant="body1" color="textSecondary">Update of existing presentation</Core.Typography>
                                
                                <Core.Typography variant="h6" color="textPrimary">
                                    {FormFunctions.FormatValue(project?.Update_Presentation, "Update_Presentation")}
                                </Core.Typography>
                            </div>
                            <div>
                                <Core.Typography variant="body1" color="textSecondary">Trend report</Core.Typography>
                                
                                <Core.Typography variant="h6" color="textPrimary">
                                    {FormFunctions.FormatValue(project?.Trend_Report, "Trend_Report")}
                                </Core.Typography>
                            </div>
                            <div>
                                <Core.Typography variant="body1" color="textSecondary">Market Research</Core.Typography>
                                
                                <Core.Typography variant="h6" color="textPrimary">
                                    {FormFunctions.FormatValue(project?.Market_Research, "Market_Research")}
                                </Core.Typography>
                            </div>
                        </div>
                    </div>

                    {/* -------------------------------- EXTRA INFO ------------------------------------------------- */}
                    <div>
                        <Core.Typography variant="h5" color="primary">Extra Info</Core.Typography>
                        <div>
                            <div>
                                <Core.Typography variant="body1" color="textSecondary">
                                    Extra Information
                                </Core.Typography>
                                <Core.Typography variant="h6" color="textPrimary">
                                    {FormFunctions.FormatValue(project?.Extra_Information)}
                                </Core.Typography>
                            </div>
                        </div>
                    </div>
                </Core.DialogContent>
            </Core.Dialog>
        </>
    );
};

export default ProjectSummaryDialog;
