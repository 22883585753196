import React from "react";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { Container, Grid } from "@material-ui/core";
import FormControlLabel from "@mui/material/FormControlLabel";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import { ReactComponent as NewProjectLogo } from "../../../Assets/Img/icons/new_project.svg";
import { ProjectService } from "../../Api/Services/ProjectService";
import { ProjectDTO, ProjectFullDTO } from "../../Api/Models/ProjectModels";
import ProjectFormM from "./ProjectForm/ProjectForm";
import { AlertNotification } from "../Common/Components/Error/AlertNotification";
import { useCudDialogs } from "../Common/Components/UseCudDialogs";
import SubPanelM from "../Common/Layout/SubPanel";
import ProjectsListM from "./Components/ProjectsList";
import ProjectDeleteDialogM from "./Components/ProjectDeleteDialog";
import ProjectSummaryM from "./Components/ProjectSummaryDialog";
import ProjectArchiveM from "./Components/ProjectArchiveDialog";
import ProjectUnarchiveM from "./Components/ProjectUnarchiveDialog";
import ProjectBlockM from "./Components/ProjectBlockDialog";
import ProjectUnBlockM from "./Components/ProjectUnBlockDialog";
import ProjectUnblockDialogM from "./Components/ProjectUnBlockDialog";
//import ProjectDuplicateM from "./Components/ProjectDuplicateDialog";

const Projects = (): JSX.Element => {
    const { t } = useTranslation("app");

    const { enqueueSnackbar } = useSnackbar();

    const {
        recordToEdit,
        onNew,
        onEdit,
        onDelete,
        onSummary,
        onArchive,
        closeEditForm,
        closeDelete,
        isEditFormOpen,
        isDeleteOpen,
        isSummaryFormOpen,
        closeSummaryForm,
        isArchiveOpen,
        closeArchive,
        isUnarchiveOpen,
        closeUnarchive,
        onUnarchive,
        isBlockOpen,
        onBlock,
        closeBlock,
        isUnBlockOpen,
        onUnBlock,
        closeUnBlock,        
        // isDuplicateOpen,
        // closeDuplicate,
        // onDuplicate
    } = useCudDialogs<ProjectDTO>();

    const [projects, setProjects] = React.useState<ProjectDTO[]>();

    React.useEffect(() => {
        ProjectService.getProjects(0)
            .then((response) => {
                setProjects(response);
            })
            .catch((error) => {
                AlertNotification(error, "GetProjects", enqueueSnackbar);
            });
    }, []);

    const reloadProjects = React.useCallback(() => {
        ProjectService.getProjects(0)
            .then((response) => {
                setProjects(response);
            })
            .catch((error) => {
                AlertNotification(error, "GetProjects", enqueueSnackbar);
            });
    }, []);

    const handleDeleteProject = React.useCallback((projectToDelete: ProjectDTO) => {
        AlertNotification(
            t("projects.notifications.projectDeleted"),
            t("projects.notifications.deleteProject"),
            enqueueSnackbar,
            {
                variant: "success",
            }
        );

        setProjects((prev) => prev?.filter((project) => project.id !== projectToDelete.id));
    }, []);

    const handleArchiveProject = React.useCallback((projectToArchive: ProjectDTO) => {
        AlertNotification(
            t("projects.notifications.projectArchived"),
            t("projects.notifications.archiveProject"),
            enqueueSnackbar,
            {
                variant: "success",
            }
        );

        setProjects((prev) => prev?.filter((project) => project.id !== projectToArchive.id));
    }, []);

    const handleUnarchiveProject = React.useCallback((projectToArchive: ProjectDTO) => {
        AlertNotification(
            t("projects.notifications.projectUnarchived"),
            t("projects.notifications.unarchiveProject"),
            enqueueSnackbar,
            {
                variant: "success",
            }
        );

        setProjects((prev) => prev?.filter((project) => project.id !== projectToArchive.id));
    }, []);

    const handleBlockProject = React.useCallback((projectToBlock: ProjectDTO) => {
        AlertNotification(
            t("projects.notifications.projectBlocked"),
            t("projects.notifications.blockProject"),
            enqueueSnackbar,
            {
                variant: "success",
            }
        );

        window.location.reload();
    }, []);

    const handleUnBlockProject = React.useCallback((projectToBlock: ProjectDTO) => {
        AlertNotification(
            t("projects.notifications.projectUnBlocked"),
            t("projects.notifications.unblockProject"),
            enqueueSnackbar,
            {
                variant: "success",
            }
        );

        window.location.reload();
    }, []);

    // const handleDuplicateProject = React.useCallback((finished: boolean) => {
    //     if (finished) {
    //         AlertNotification(
    //             t("projects.notifications.duplicateProject"),
    //             t("projects.notifications.duplicateProjectText"),
    //             enqueueSnackbar,
    //             {
    //                 variant: "success",
    //             }
    //         );
    //     }

    //     // setProjects((prev) => prev?.filter((project) => project.Id !== projectToDuplicate.Id));
    // }, []);

    const loadUnarchivedProjects = React.useCallback(() => {
        setProjects(undefined);
        ProjectService.getProjects(0)
            .then((response) => {
                setProjects(response);
            })
            .catch((error) => {
                //console.log(error);
                AlertNotification(error, "GetProjects", enqueueSnackbar);
            });
    }, []);

    const loadArchivedProjects = React.useCallback(() => {
        setProjects(undefined);
        ProjectService.getProjects(1)
            .then((response) => {
                setProjects(response);
            })
            .catch((error) => {
                //console.log(error);
                AlertNotification(error, "GetProjects", enqueueSnackbar);
            });
    }, []);

    const subPanelItems = () => [
        {
            action: onNew,
            label: t("projects.newProject"),
            icon: <NewProjectLogo style={{ height: 24, width: 24 }} />,
        },
    ];

    const [selectedValue, setSelectedValue] = React.useState("Actived");

    //const [duplicateRecord, setDuplicateRecord] = React.useState<ProjectFullDTO>();

    return (
        <Container maxWidth="lg">
            <SubPanelM title={t("projects.projects")} itemsList={subPanelItems()}>
                <Grid>
                    <RadioGroup row aria-labelledby="demo-radio-buttons-group-label" defaultValue="female" name="radio-buttons-group">
                        <FormControlLabel
                            defaultChecked={true}
                            control={<Radio />}
                            label="Actived"
                            value="Actived"
                            checked={selectedValue === "Actived"}
                            onClick={loadUnarchivedProjects}
                            onChange={(_, value) => { setSelectedValue("Actived"); }}
                        />
                        <FormControlLabel
                            control={<Radio />}
                            label="Archived"
                            value="Archived"
                            onClick={loadArchivedProjects}
                            onChange={(_, value) => { setSelectedValue("Archived"); }}
                        />
                    </RadioGroup>
                    <ProjectsListM
                        //setDuplicateRecord={setDuplicateRecord}
                        records={projects}
                        onEdit={onEdit}
                        onDelete={onDelete}
                        onArchive={onArchive}
                        onUnArchive={onUnarchive}
                        onSummary={onSummary}
                        onBlock={onBlock}
                        onUnBlock={onUnBlock}
                        //onDuplicate={onDuplicate}
                    />
                </Grid>
            </SubPanelM>
            <ProjectFormM
                isFormOpen={isEditFormOpen}
                closeForm={closeEditForm}
                projectRecord={recordToEdit.current}
                reloadProjects={reloadProjects}
            />
            <ProjectDeleteDialogM
                isOpen={isDeleteOpen}
                closeDialog={closeDelete}
                record={recordToEdit.current}
                deleteRecord={handleDeleteProject}
            />
            <ProjectSummaryM
                isFormOpen={isSummaryFormOpen}
                closeForm={closeSummaryForm}
                projectRecord={recordToEdit.current}
            />
            <ProjectArchiveM
                isOpen={isArchiveOpen}
                closeDialog={closeArchive}
                record={recordToEdit.current}
                archiveRecord={handleArchiveProject}
            />
            <ProjectUnarchiveM
                isOpen={isUnarchiveOpen}
                closeDialog={closeUnarchive}
                record={recordToEdit.current}
                unarchiveRecord={handleUnarchiveProject}
            />
            <ProjectBlockM
                isOpen={isBlockOpen}
                closeDialog={closeBlock}
                record={recordToEdit.current}
                blockRecord={handleBlockProject}
            />
            <ProjectUnblockDialogM
                isOpen={isUnBlockOpen}
                closeDialog={closeUnBlock}
                record={recordToEdit.current}
                unblockRecord={handleUnBlockProject}
            />            
            {/* <ProjectDuplicateM
                isOpen={isDuplicateOpen}
                closeDialog={closeDuplicate}
                record={recordToEdit.current}
                duplicatedRecord={duplicateRecord}
                finished={handleDuplicateProject}
            /> */}
        </Container>
    );
};

export default Projects;
