import React, { Suspense } from "react";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Add } from "@material-ui/icons";
import { userService } from "../../Api/Services/UserService";
import { UserDTO } from "../../Api/Models/UserModels";
import { AlertNotification } from "../Common/Components/Error/AlertNotification";
import { useCudDialogs } from "../Common/Components/UseCudDialogs";
import Loading from "../Common/Components/Loading";
import SubPanelM from "../Common/Layout/SubPanel";
import UsersListM from "./Components/UsersList";
import UserFormM from "./Components/UserForm";
import UserDeleteDialogM from "./Components/UserDeleteDialog";

const useStyles = makeStyles((theme) => ({
    root: {
        [theme.breakpoints.down("sm")]: {
            padding: 0,
        },
    },
}));

const Users = (): JSX.Element => {
    const classes = useStyles();
    const { t } = useTranslation("app");
    const { enqueueSnackbar } = useSnackbar();

    const {
        recordToEdit,
        onNew,
        onEdit,
        onDelete,
        closeEditForm,
        closeDelete,
        isEditFormOpen,
        isDeleteOpen,
    } = useCudDialogs<UserDTO>();

    const [users, setUsers] = React.useState<UserDTO[]>();

    function GetUsers() {
        userService
            .getUsers()
            .then((response) => {
                setUsers(response);
            })
            .catch((error) => {
                AlertNotification(error, "GetUsers", enqueueSnackbar);
            });
    }

    React.useEffect(() => {
        GetUsers();
    }, []);

    const handleCreateUser = React.useCallback(() => {
        GetUsers();
    }, []);

    const handleDeleteUser = React.useCallback((userToDelete: UserDTO) => {
        setUsers((prev) => prev?.filter((user) => user.id !== userToDelete.id));
    }, []);

    const subPanelItems = () => [
        {
            action: onNew,
            label: t("users.newUser"),
            icon: <Add />,
        },
    ];

    return (
        <Container maxWidth="lg" className={classes.root}>
            <SubPanelM title={t("users.users")} itemsList={subPanelItems()}>
                <Suspense fallback={<Loading />}>
                    <UsersListM
                        records={users}
                        onEdit={onEdit}
                        onDelete={onDelete}
                    />
                    <UserFormM
                        isFormOpen={isEditFormOpen}
                        closeForm={closeEditForm}
                        handleSuccess={handleCreateUser}
                        userRecord={recordToEdit.current}
                        users={users?.filter((user) => user.rol && (user.rol.name !== "user" && user.email_verified_at)) || []}
                    />
                    <UserDeleteDialogM
                        isOpen={isDeleteOpen}
                        closeDialog={closeDelete}
                        record={recordToEdit.current}
                        deleteRecord={handleDeleteUser}
                    />
                </Suspense>
            </SubPanelM>
        </Container>
    );
};

export default Users;
