import React from "react";
import { makeStyles, CssBaseline } from "@material-ui/core";
import TopBarM from "./Topbar";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flex: "1 1 auto",
        minHeight: "100vh",
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        display: "flex",
        flexDirection: "column",
        flex: "1 1 auto",
    },
}));

interface LayoutProps {
    children: JSX.Element;
}

export const Layout = ({ children }: LayoutProps): JSX.Element => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <CssBaseline />
            <TopBarM />
            <main className={classes.content}>
                <div className={classes.appBarSpacer} />
                {children}
            </main>
        </div>
    );
};
