import React from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
    makeStyles,
    AppBar,
    Toolbar,
    IconButton,
    Menu,
    MenuItem,
    Theme,
    createStyles,
} from "@material-ui/core";
import { PowerSettingsNew, AccountCircle, Person } from "@material-ui/icons";
import { AppRoutes } from "../../../Helpers/ApplicationConstants";
import { authService } from "../../../Api/Services/AuthService";
import Logo from "../../../../Assets/Img/Iberchem_app_logo.svg";
import LogoDbrief from "../../../../Assets/Img/Iberchem_app_dbrief.svg";
import { ApplicationPaths } from "../../../Auth/ApiAuthorizationConstants";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: "flex",
        },
        appBar: {
            zIndex: theme.zIndex.drawer + 2,
            backgroundColor: theme.palette.background.default,
            [theme.breakpoints.down("xs")]: {
                boxShadow: `0  -2px 6px 0px ${theme.palette.background.default}`,
            },
            [theme.breakpoints.up("sm")]: {
                boxShadow: `0  -2px 6px 0px ${theme.palette.secondary.main}`,
            },
        },
        logoContainerSize: theme.mixins.toolbar,
        logoContainer: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            [theme.breakpoints.down("xs")]: {
                height: "35px",
            },
            [theme.breakpoints.up("sm")]: {
                height: "45px",
            },
        },
        logo: {
            height: "45%",
            marginBottom: theme.spacing(0.5),
        },
        logoDbrief: {
            height: "25%",
        },
        toolBar: {
            justifyContent: "space-between",
        },
        toolBarActions: {
            display: "flex",
            flexDirection: "row",
        },
        userMenuItemIcon: {
            paddingLeft: 10,
        },
        typography: {
            padding: theme.spacing(2),
        },
    })
);

const TopBar = (): JSX.Element => {
    const history = useHistory();
    const { t } = useTranslation("app");

    const classes = useStyles();

    const [anchorProfile, setAnchorProfile] = React.useState<null | HTMLElement>(null);
    const handleClickProfile = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorProfile(event.currentTarget);
    };
    const handleCloseProfile = () => {
        setAnchorProfile(null);
    };

    const [anchorSettings, setAnchorSettings] = React.useState<null | HTMLElement>(null);
    const handleClickSettings = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorSettings(event.currentTarget);
    };
    const handleCloseSettings = () => {
        setAnchorSettings(null);
    };

    const handleMenuItemClick = (route: string) => {
        history.push(route);
        handleCloseSettings();
        handleCloseProfile();
    };

    const handleLogOut = () => {
        history.push(ApplicationPaths.LogoutCallback);
        authService
            .logout(() => {
                history.push(ApplicationPaths.DefaultLoginRedirectPath);
            })
            .then((response) => {})
            .catch((error) => {})
            .finally(() => {});
    };

    return (
        <div className={classes.root}>
            <AppBar className={classes.appBar} position="fixed" color="secondary">
                <Toolbar className={classes.toolBar}>
                    <div className={`${classes.logoContainerSize} ${classes.logoContainer}`}>
                        <img src={Logo} className={classes.logo} alt="logo" />
                        <img src={LogoDbrief} className={classes.logoDbrief} alt="logofont" />
                    </div>

                    <div className={classes.toolBarActions}>
                        <IconButton onClick={handleClickProfile}>
                            <AccountCircle />
                        </IconButton>
                        <Menu
                            anchorEl={anchorProfile}
                            open={Boolean(anchorProfile)}
                            onClose={handleCloseProfile}
                        >
                            <MenuItem
                                onClick={() =>
                                    handleMenuItemClick(AppRoutes.Administration.Profile)
                                }
                            >
                                <Person />{" "}
                                <span className={classes.userMenuItemIcon}>
                                    {t("appbar.profile")}
                                </span>
                            </MenuItem>
                            <MenuItem onClick={handleLogOut}>
                                <PowerSettingsNew />
                                <span className={classes.userMenuItemIcon}>
                                    {t("appbar.logout")}
                                </span>
                            </MenuItem>
                        </Menu>
                    </div>
                </Toolbar>
            </AppBar>
        </div>
    );
};

const TopBarM = React.memo(TopBar);

export default TopBarM;
