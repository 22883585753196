import * as Core from "@material-ui/core";

const useStyles = Core.makeStyles((theme: Core.Theme) =>
    Core.createStyles({
        root: {
            padding: 20,
        },
    })
);
  

export default function Privacy(): JSX.Element {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Core.Typography color="textPrimary" paragraph variant="h6">IBERCHEM, S.A. provides information about its privacy policy regarding personal data below. The use of the website does not imply that the user must provide any information regarding their identity, and it cannot be associated with a specific user by the company. Where personal data is provided, this data will be collected and used in accordance with the limitations and rights set out in the abovementioned standard.</Core.Typography>
            <Core.Typography color="textPrimary" paragraph variant="h4">Privacy policy</Core.Typography>
            <Core.Typography color="textPrimary" paragraph variant="h5">WHO IS THE DATA CONTROLLER?</Core.Typography>
            <div style={{fontSize: "20px", fontFamily: "arial"}}>
                <ul>
                    <li><b>DATA controller:</b> IBERCHEM, S.A.</li>
                    <li><b>Tax ID Code:</b> A-85023463</li>
                    <li><b>Post Address:</b> Avda. del Descubrimiento, P.9/9. Poli.Ind,Oeste, 30820, Alcantarilla, MURCIA</li>
                    <li><b>Email:</b> data.privacy@iberchem.com</li>
                </ul>
            </div>
            <Core.Typography color="textPrimary" paragraph variant="h5">WHAT KIND OF DATA DO WE PROCESS?</Core.Typography>
            <Core.Typography color="textPrimary" paragraph variant="h6">Depending on the products, services or functionalities that you want to enjoy at any time, we will need to process some data or other. In general, said data will be, depending on the case, the following:</Core.Typography>
            <div style={{fontSize: "20px", fontFamily: "arial"}}>
                <ul>
                    <li>Your identification data (for example, your name, surname, language, the country from which you are interacting with us, contact information, etc.)</li>
                    <li>Connection, geolocation and navigation data (where you are interacting with us from your mobile phone, for example).</li>
                    <li>Data about your tastes and preferences.</li>
                    <li>Academic and employment data if you send us your CV.</li>
                </ul>
                <p>
                    Remember that, when we ask you to fill in your personal data to give you access to some of the website’s features or services, we will mark some fields as mandatory, since they are data that we need to be able to provide the service or give you access to the feature in question.
                    Please note that if you decide not to provide us with this data, you may not be able to complete your registration as a user or you may not be able to enjoy those services or features.
                </p>
            </div>
            <Core.Typography color="textPrimary" paragraph variant="h5">FOR WHAT PURPOSE DO WE PROCESS YOUR PERSONAL DATA?</Core.Typography>
                <div style={{fontSize: "20px", fontFamily: "arial"}}>
                    <ol>
                        <li>
                            To attend to the requests or applications that you make through the different means incorporated into our website.
                            We only process the personal data that is strictly necessary to manage or resolve your request or application.
                        </li>
                        <li>
                            To take part in our personnel selection processes.We will process your data to enable you to take part in our personnel selection processes.
                        </li>
                        <li>
                            Usability and quality analysis to improve our services (Cookies):
                            <br />
                            If you access our Platform, we inform you that we will process your browsing data for analytical and statistical purposes, that is, 
                            to understand the way in which users interact with our Platform and thus be able to make improvements to it. 
                            Likewise, occasionally, we carry out quality improvements and surveys aimed at finding out our customers and users’ degree of satisfaction and to establish those fields 
                            where there is room for improvement.
                        </li>
                    </ol>
                </div>
            <Core.Typography color="textPrimary" paragraph variant="h5">ON WHAT LEGAL GROUNDS DO WE PROCESS YOUR DATA?</Core.Typography>
                <Core.Typography color="textPrimary" paragraph variant="h6">
                    The legal grounds allowing us to process your personal data also depend on the purpose for which we process them, thus, 
                    there are different legal grounds based on the following purposes:
                </Core.Typography>
                <div style={{fontSize: "20px", fontFamily: "arial"}}>
                    <ol>
                        <li>
                            Replying to requests for information.
                            The legal grounds for the processing your data is informed consent so we can respond to the queries raised through the website, 
                            which implies the acceptance of these conditions by the data subject.
                        </li>
                        <li>
                            To take part in our personnel selection processes. The legal ground is pre-contractual.
                        </li>
                    </ol>
                </div>
            <Core.Typography color="textPrimary" paragraph variant="h5">FOR HOW LONG WILL WE KEEP YOUR DATA?</Core.Typography>
                <Core.Typography color="textPrimary" paragraph variant="h6">
                    We will process your data for as long as it is necessary to handle your request or for you to take part in our selection processes and it will be deleted after one year.
                </Core.Typography>
            <Core.Typography color="textPrimary" paragraph variant="h5">WILL WE SHARE YOUR DATA WITH THIRD PARTIES?</Core.Typography>
                <Core.Typography color="textPrimary" paragraph variant="h6">
                    To comply with the purposes indicated in this Privacy Policy, it is necessary that we give third parties access to the personal data you have provided to us to support the services 
                    that we offer and that we detail below:
                </Core.Typography>
                <div style={{fontSize: "20px", fontFamily: "arial"}}>
                    <ul>
                        <li>Technology service providers</li>
                        <li>Group companies</li>
                    </ul>
                </div>
            <Core.Typography color="textPrimary" paragraph variant="h5">WHAT ARE YOUR RIGHTS WHEN YOU PROVIDE US WITH YOUR DATA?</Core.Typography>
                <Core.Typography color="textPrimary" paragraph variant="h6">
                    We promise to respect the confidentiality of your personal data and guarantee you will be allowed to exercise your rights.                
                    You can exercise them for free by sending an email to our address data.privacy@iberchem.com, simply indicating the reason for your request and the right you want to exercise.                
                    Should we consider it necessary to be able to identify you, we may request a copy of a document proving your identity. In particular, regardless of the purpose or legal grounds under which we process your data, you have the right to:
                    Ask us to access any data of yours that we may have.
                    Ask us to rectify the data we already have. Please note that by actively providing us with your personal data by any means, you guarantee that they are true and accurate and undertake to notify us of any changes or modifications to them.
                    Ask us to erase your data where they are no longer necessary for the purpose for which we need to process them as we have previously informed you, or where we no longer have the legitimacy to do so.
                    Ask us to limit the processing of your data, which means that in certain cases you can ask us to temporarily suspend the processing of the data or to keep them beyond the time necessary when you may require them. If you have given us your consent to process your data for any purpose, you also have the right to withdraw it at any time.
                    On the other hand, when processing your data is based on our legitimate interest, you will also have the right to object to it being processed.
                    Finally, we inform you of your right to make a claim to the relevant data protection monitoring authority, in particular, with the Spanish Data Protection Agency.
            </Core.Typography>

            <a style={{fontSize: "20px", fontFamily: "arial"}} href="https://www.agpd.es/portalwebAGPD/index-ides-idphp.php">https://www.agpd.es/portalwebAGPD/index-ides-idphp.php</a>
            <br />
            <br />

            <Core.Typography color="textPrimary" paragraph variant="h5">CHANGES TO THE PRIVACY POLICY</Core.Typography>
                <Core.Typography color="textPrimary" paragraph variant="h6">
                    We use cookies and similar devices to facilitate your navigation on the Platform, to become aware of how you interact with us and, in certain cases, to be able to show you advertising based on your browsing habits. 
                    Please read our Cookies Policy to learn more about the cookies and similar items we use, their purpose and other information of interest.
                </Core.Typography>

            <Core.Typography color="textPrimary" paragraph variant="h5">INFORMATION ABOUT COOKIES</Core.Typography>
                <Core.Typography color="textPrimary" paragraph variant="h6">
                We use cookies and similar devices to facilitate your navigation on the Platform, to become aware of how you interact with us and, in certain cases, to be able to show you advertising based on your browsing habits. Please read our Cookies Policy to learn more about the cookies and similar items we use, their purpose and other information of interest.
                </Core.Typography>
        </div>
    );
}
