import React, { Suspense } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Card, Container, Grid, Typography } from "@material-ui/core";
import { Edit } from "@material-ui/icons";
import Loading from "../Common/Components/Loading";
import ProjectFormM from "../Projects/ProjectForm/ProjectForm";
import { ProjectDTO } from "../../Api/Models/ProjectModels";
import { ProjectService } from "../../Api/Services/ProjectService";
import { authService } from "../../Api/Services/AuthService";
import { ReactComponent as RocketLogo } from "../../../Assets/Img/icons/logo_bench.svg";
import { useCudDialogs } from "../Common/Components/UseCudDialogs";
import { useSnackbar } from "notistack"
import { AlertNotification } from "../Common/Components/Error/AlertNotification";

const useStyles = makeStyles((theme) => ({
    root: {
        height: "100%",
    },
    card: {
        height: "100%",
        padding: theme.spacing(3),
        borderRadius: theme.spacing(2),
        position: "relative",
        cursor: "pointer",
    },
    header: {
        marginTop: theme.spacing(2),
    },
    lastProject: {
        cursor: "pointer",
        padding: theme.spacing(2),
        backgroundColor: "#DDD",
        borderRadius: theme.spacing(2),
        display: "flex",
        alignItems: "center",
        flexWrap: "wrap",
    },
    wrapIcon: {
        textAlign: "right",
        display: "block",
    },
}));

const Dashboard = (): JSX.Element => {
    const classes = useStyles();
    const { recordToEdit, onNew, onEdit, closeEditForm, isEditFormOpen } = useCudDialogs<ProjectDTO>();
    const [lastProjectForm, setLastProjectForm] = React.useState<ProjectDTO>();
    const { enqueueSnackbar } = useSnackbar()
    const user: string = authService.getUser().name;

    React.useEffect(() => {
        ProjectService.getLastProject()
        .then((response) => {
            setLastProjectForm(() => response);
        })
        .catch((error) => {
            AlertNotification(error, "GetProjectForm", enqueueSnackbar, { variant: "warning" });
        });
    }, []);

    return (
        <Container maxWidth="lg" className={classes.root}>
            <Suspense fallback={<Loading />}>
                <Grid
                    container
                    className={classes.root}
                    direction="column"
                    alignContent="stretch"
                    alignItems="stretch"
                    spacing={4}
                >
                    <Grid item>
                        <Typography className={classes.header} variant="h4">
                            Welcome back {user}
                        </Typography>
                    </Grid>
                    <Grid item xs>
                        <Card elevation={0} variant="outlined" className={classes.card} onClick={onNew}>
                            <Grid
                                style={{ width: "100%", height: "100%" }}
                                container
                                direction="column"
                                justify="space-between"
                                alignContent="center"
                            >
                                <Grid item xs style={{ width: "100%" }}>
                                    <div style={{ width: "100%", height: "100%", position: "relative", }} >
                                        <RocketLogo style={{width: "100%", height: "100%", position: "absolute", }} />
                                    </div>
                                </Grid>
                                <Grid item>
                                    <Typography variant="h5">Start a new project</Typography>
                                </Grid>
                            </Grid>
                        </Card>
                    </Grid>
                    {lastProjectForm && (
                        <Grid item>
                            <Card elevation={0} className={classes.lastProject} onClick={() => onEdit(lastProjectForm)}>
                                <Grid item xs={11} sm={11}>
                                    <Typography variant="body1">
                                        Continue unfinished project
                                    </Typography>
                                </Grid>
                                <Grid item xs={1} sm={1} className={classes.wrapIcon}>
                                    <Edit />
                                </Grid>
                            </Card>
                        </Grid>
                    )}
                </Grid>
            </Suspense>
            <ProjectFormM
                isFormOpen={isEditFormOpen}
                closeForm={closeEditForm}
                projectRecord={recordToEdit.current}
            />
        </Container>
    );
};

export default Dashboard;
