import React from "react";
import clsx from "clsx";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { CircularProgress, Grid, Typography } from "@material-ui/core";
import Logo from "../../../../Assets/Img/Iberchem_app_logo.svg";

export const LoadingModes = {
    Circular: "circular",
    Linear: "linear",
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            height: "100%",
            zIndex: 3,
        },
        absolute: {
            position: "absolute",
        },
        backgroundBlack: {
            backgroundColor: "#00000022",
        },
        backgroundWhite: {
            backgroundColor: "#ffffffc0",
        },
    })
);

interface LoadingProps {
    label?: string;
    useCapital?: boolean;
    useLogo?: boolean;
    useAbsolute?: boolean;
    useBackground?: boolean;
    darkMode?: boolean;
}

const Loading = ({
    label,
    useCapital,
    useLogo,
    useAbsolute,
    useBackground,
    darkMode,
}: LoadingProps): JSX.Element => {
    const classes = useStyles();

    return (
        <Grid
            container
            direction="column"
            justify="center"
            alignContent="center"
            alignItems="center"
            className={clsx(classes.root, {
                [classes.absolute]: useAbsolute,
                [classes.backgroundWhite]: useBackground && !darkMode,
                [classes.backgroundBlack]: useBackground && darkMode,
            })}
        >
            {useLogo && (
                <Grid item>
                    <img src={Logo} alt="logo" />
                </Grid>
            )}
            <Grid item>
                <CircularProgress color={!darkMode ? "primary" : "secondary"} />
            </Grid>
            {label && (
                <Grid item>
                    <Typography
                        variant={useCapital ? "button" : "h5"}
                        color={!darkMode ? "primary" : "textPrimary"}
                    >
                        {label}
                    </Typography>
                </Grid>
            )}
        </Grid>
    );
};

export default Loading;
