import React from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    useMediaQuery,
} from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { IDeleteDialog } from "../../Common/GenericInterfaces/GenericInterfaces";
import { userService } from "../../../Api/Services/UserService";
import { DeleteUserCommand, UserDTO } from "../../../Api/Models/UserModels";
import { AlertNotification } from "../../Common/Components/Error/AlertNotification";

const UserDeleteDialog = ({
    isOpen,
    closeDialog,
    record,
    deleteRecord,
}: IDeleteDialog<UserDTO>): JSX.Element => {
    const { t } = useTranslation("app");
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));
    const { enqueueSnackbar } = useSnackbar();

    const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);

    const handleDelete = () => {
        if (!record) {
            return;
        }

        const data: DeleteUserCommand = {
            id: record.id,
        };

        userService
            .deleteUser(data)
            .then((res) => {
                AlertNotification(
                    t("users.notifications.userDeleted"),
                    t("users.notifications.deleteUser"),
                    enqueueSnackbar,
                    {
                        variant: "success",
                    }
                );

                deleteRecord(record);
                closeDialog();
            })
            .catch((error) => {
                console.log("ERROR AL BORRAR USUARIO", error.response);
                AlertNotification(error, t("users.notifications.deleteUser"), enqueueSnackbar);
                closeDialog();
            })
            .finally(() => {
                setIsSubmitting(false);
            });
    };

    return (
        <Dialog
            open={isOpen}
            onClose={closeDialog}
            fullScreen={fullScreen}
            fullWidth={true}
            maxWidth="xs"
            disableBackdropClick={isSubmitting}
            disableEscapeKeyDown={isSubmitting}
        >
            <DialogTitle>
                {t("users.deleteUser")} {record?.name}
            </DialogTitle>
            <DialogContent>
                <DialogContentText>{t("users.deleteUserText")}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={closeDialog} color="default">
                    {t("general.cancel")}
                </Button>
                <Button onClick={handleDelete} color="primary">
                    {t("general.delete")}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const UserDeleteDialogM = React.memo(UserDeleteDialog);

export default UserDeleteDialogM;
