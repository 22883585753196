/*  eslint-disable */
import axios from "../AxiosConfig";
import * as Constants from "../ApiConstants";
import { LoginUserCommand, RegisterUserCommand, IUserMailData } from "../Models/UserModels";

function decodeToken(token: string) {
    if (token === "undefined" || token === undefined || token === null) {
        return null;
    }
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace('-', '+').replace('_', '/');
    return JSON.parse(window.atob(base64));
}

const tDataName = "t";
const authDataName = "auth";
const userDataName = "user";

const moment = require('moment');

export class AuthService {

    async isAuthenticated(): Promise<boolean> {
        const user = this.getUser();
        const auth = this.getAuth();

        const noStoredUser = (!user || user === "");
        const noStoredAuth = (!auth || auth === "");

        if(noStoredUser || noStoredAuth){
            this.removeData();
            return false;
        }

        const expm = moment.utc(new Date(auth.expires_at)).valueOf();
        const utcOffset = moment().utcOffset();
        const nowm = moment().utc().subtract(utcOffset, "m").valueOf();

        if(expm < nowm){
            this.removeData();
            return false;
        }

        return true;
    }

    getUserStatus(){
        return localStorage.getItem(userDataName);
    }

    getUser(){
        try{
            const userStatus = this.getUserStatus(); 
            if(userStatus){
                return JSON.parse(userStatus);
            }
            return null;
        }
        catch(exception){
            // //console.log(exception);
        }
    }

    getUserRole(){
        try{
            const userStatus = this.getUserStatus(); 
            if(userStatus){
                const user = JSON.parse(userStatus);
                return user.rol.name;
            }
            return null;
        }
        catch(exception){
            // //console.log(exception);
        }
    }

    getAuthStatus(){
        return localStorage.getItem(authDataName);
    }

    getAuth(){
        try{
            const authStatus = this.getAuthStatus();
            if(authStatus){
                return JSON.parse(authStatus);
            }
            return null;
        }
        catch(exception){
            // //console.log(exception);
        }
    }

    setData(authData: string, userData: string, t: string){
        localStorage.setItem(tDataName, t);
        localStorage.setItem(authDataName, authData);
        localStorage.setItem(userDataName, userData);
        axios.defaults.headers.common.Authorization = `Bearer ${t}`;
    }

    setUserData(userData: Record<string, unknown>) {
        localStorage.setItem(userDataName, JSON.stringify(userData));
    }

    removeData(){
        localStorage.removeItem(tDataName);
        localStorage.removeItem(authDataName);
        localStorage.removeItem(userDataName);
        delete axios.defaults.headers.common["Authorization"];
    }

    async register(data: RegisterUserCommand){
        return await axios
        .post(`${Constants.APIHost}/auth/register`, data, {
            headers: {
                "Content-Type": "application/json",
            },
        })
        .then((response) => {
            // //console.log(response);
            return response;
        })
        .catch((error) => {
            //console.log(error);
            if (error.response) {
                //console.log(error.response);
            }
            throw error;
        });
    }

    async login(data: LoginUserCommand, onSuccess: () => void) {
        return await axios
            .post(`${Constants.APIHost}/auth/login`, data, {
                headers: {
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
                    "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token"
                },
            })
            .then((response) => {
                // //console.log(response);
                this.setData(JSON.stringify(response.data.access_token), JSON.stringify(response.data.user), response.data.access_token.token);
                onSuccess();
                
                return response;
            })
            .catch((error) => {
                //console.log(error);
                if (error.response) {
                    //console.log(error.response);
                }
                throw error;
            });
    }

    async logout(onSuccess: () => void) {
        return await axios
            .get(`${Constants.APIHost}/auth/logout`, {
                headers: {
                    "Content-Type": "application/json",
                },
            })
            .then((response) => {
                return response;
            })
            .catch((error) => {
                return error;
            })
            .finally(() => {
                this.removeData();
                onSuccess();
            });
    }

    async sendVerificationMail(data: IUserMailData) {
        // //console.log(data);
        //console.log(`${Constants.APIHost}${Constants.APIMailVerification}/accountValidated`);
        return await axios
            .post(`${Constants.APIHost}${Constants.APIMailVerification}/accountValidated`, data, {
            //.post<string>(`${Constants.APIHost}${Constants.APIProject}/create`, data)
                headers: {
                    "Content-Type": "application/json",
                },
            })
            .then((response) => {
                return response;
            })
            .catch((error) => {
                //console.log(error);
                if (error.response) {
                    //console.log(error.response);
                }
                throw error;
            });
    }
}

export const authService = new AuthService();
