export const ApplicationName = "iberchem";

export const QueryParameterNames = {
    ReturnUrl: "returnUrl",
    Message: "message",
};

export const LogoutActions = {
    Logout: "logout",
    LogoutCallback: "logout-callback",
    LoggedOut: "logged-out",
};

export const LoginActions = {
    Login: "login",
    LoginCallback: "login-callback",
    LoginFailed: "login-failed",
    Profile: "profile",
    Register: "register",
    ForgotPassword: "forgot-password",
    ResetPassword: "reset-password/:email/:token",
};

export const APIAuthPrefix = "/auth";

export const ApplicationPaths = {
    DefaultLoginRedirectPath: "/app",
    ApiAuthorizationPrefix: APIAuthPrefix,
    Register: `${APIAuthPrefix}/${LoginActions.Register}`,
    Login: `${APIAuthPrefix}/${LoginActions.Login}`,
    LoginFailed: `${APIAuthPrefix}/${LoginActions.LoginFailed}`,
    LoginCallback: `${APIAuthPrefix}/${LoginActions.LoginCallback}`,
    Logout: `${APIAuthPrefix}/${LogoutActions.Logout}`,
    LoggedOut: `${APIAuthPrefix}/${LogoutActions.LoggedOut}`,
    LogoutCallback: `${APIAuthPrefix}/${LogoutActions.LogoutCallback}`,
    ForgotPassword: `${APIAuthPrefix}/${LoginActions.ForgotPassword}`,
    ResetPassword: `${APIAuthPrefix}/${LoginActions.ResetPassword}`,
};
