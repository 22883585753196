import { FieldPath } from "react-hook-form";

interface ISubSection {
    order: number;
    name: string;
    section: string;
    fields: FieldPath<IFieldsSubsections>[] | readonly FieldPath<IFieldsSubsections>[];
}

type SubSections = ISubSection[];

export interface IFieldsSubsections {
    // Subsection 0
    Name: string;
    Client_Name: string;
    Contact_Person: string;
    Country: string;
    ProductType: string;

    // Subsection 1
    Price_KG: number;
    Price_KG_Currency: string;
    Fragrance_Cost: number;
    Fragrance_Cost_Currency: string;
    Price_Purchasing_Extra: string;

    // Subsection 2
    Sample_Received: string;
    Product_Reference: string;
    Product_Reference_Image: string | null;

    // Subsection 3
    Olfactory_Profile: string;
    Olfactory_Extra: string;

    // Subsection 4
    Target_End_Customer: string;
    Target_Age_Range: number[];
    Unknown_Age: number;

    // Subsection 5
    Colour: string;
    Colour_Extra: string;

    // Subsection 6
    Finished_Product_Appearance: string;
    PH: number[];
    Dosage: number;
    Customer_Base: number;
    Customer_Base_Qty: number;

    // Subsection 7
    Need_Stability_Test: number;
    Oven_Temperature: number;
    Test_Duration: number;
    Stability_Extra: string;

    // Subsection 8
    Cosmos_Ecocert: number;
    Flower_Ecolabel: number;
    California: number;
    South_Korea: number;
    Blauer_Engel: number;
    Nordic_Swan: number;
    Oko_Test: number;
    Bra_Miljoval: number;
    IFRA: number;
    VOC: number;
    Regulations_Extra: string;
    Allergen_Restrictions: string;

    // Subsection 9
    Deadline: Date | null;
    Maximum_Samples_Submit: string;
    Fragrance_Qty: number;
    Finished_Product: number;

    // Subsection 10
    Olfactory_Piramid: number;
    Custom_Presentation: number;
    Update_Presentation: number;
    Trend_Report: number;
    Market_Research: number;

    // Subsection 11
    Extra_Information: string;
}

export const subSections: SubSections = [
    {
        order: 0,
        name: "Project",
        section: "Project Data",
        fields: ["Name", "Client_Name", "Contact_Person", "Country", "ProductType"],
    },
    {
        order: 1,
        name: "Price & Purchasing Potential",
        section: "General Information",
        fields: ["Price_KG","Price_KG_Currency","Fragrance_Cost","Fragrance_Cost_Currency","Price_Purchasing_Extra",],
    },
    {
        order: 2,
        name: "What Kind of Service Do You Need From Us",
        section: "General Information",
        fields: ["Sample_Received", "Product_Reference", "Product_Reference_Image"],
    },
    {
        order: 3,
        name: "Olfactory profile",
        section: "Application",
        fields: ["Olfactory_Profile", "Olfactory_Extra"],
    },
    {
        order: 4,
        name: "Target end customer",
        section: "Application",
        fields: ["Target_End_Customer", "Target_Age_Range", "Unknown_Age"],
    },
    {
        order: 5,
        name: "What's the colour of the final product?",
        section: "Samples required",
        fields: ["Colour", "Colour_Extra"],
    },
    {
        order: 6,
        name: "Appearance &  pH",
        section: "Selection",
        fields: ["Finished_Product_Appearance", "PH", "Dosage","Customer_Base", "Customer_Base_Qty"],
    },
    {
        order: 7,
        name: "Customer Stability Conditions",
        section: "Selection",
        fields: ["Need_Stability_Test", "Oven_Temperature", "Test_Duration", "Stability_Extra"],
    },
    {
        order: 8,
        name: "Legal Requirements",
        section: "Regulatory Affairs",
        fields: ["Cosmos_Ecocert","Flower_Ecolabel","California","South_Korea","Blauer_Engel","Nordic_Swan","Oko_Test","Bra_Miljoval","IFRA","VOC","Regulations_Extra","Allergen_Restrictions"],
    },
    {
        order: 9,
        name: "Project Deadline",
        section: "Deadline",
        fields: ["Deadline", "Maximum_Samples_Submit", "Fragrance_Qty", "Finished_Product"],
    },
    {
        order: 10,
        name: "Marketing",
        section: "Marketing",
        fields: ["Olfactory_Piramid", "Custom_Presentation", "Update_Presentation", "Trend_Report", "Market_Research"],
    },
    {
        order: 11,
        name: "Extra information",
        section: "",
        fields: ["Extra_Information"],
    },
];
