import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider, createMuiTheme } from "@material-ui/core";
import { esES } from "@material-ui/core/locale";
import "./i18n";
import { SnackbarProvider } from "notistack";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import App from "./App/App";
import { authService } from "./App/Api/Services/AuthService";
import Loading from "./App/Dbrief/Common/Components/Loading";
import "./index.css";

const theme = createMuiTheme(
    {
        palette: {
            primary: {
                main: "#386098",
            },
            secondary: {
                main: "#e1e0dc",
            },
            background: {
                default: "#FFFFFF",
                paper: "#FFFFFF",
            },
        },
        typography: {
            fontFamily: "Franklin Gothic",
        },
        overrides: {
            MuiFormControl: {
                root: {
                    //marginTop: "50px",
                    border: 0,
                    "& fieldset": {
                        borderRadius: "10px",
                    },
                },
            },
            MuiTextField: {
                root: {
                    //marginTop: "50px",
                    border: 0,
                    "& fieldset": {
                        borderRadius: "10px",
                    },
                },
            },
            MuiButton: {
                contained: {
                    backgroundColor: "#FFF",
                    border: 0,
                    borderRadius: "10px",
                    color: "#858585",
                    "&$disabled": {
                        backgroundImage:
                            "linear-gradient(to right, rgba(0, 0, 0, 0.12) 0%, rgba(0, 0, 0, 0.12) 100%)",
                    },
                },
                containedPrimary: {
                    border: 0,
                    borderRadius: "10px",
                    color: "white",
                },
            },
        },
    },
    esES
);

authService
    .isAuthenticated()
    .then((isAuth) => {
        ReactDOM.render(
            <React.StrictMode>
                <ThemeProvider theme={theme}>
                    <SnackbarProvider
                        maxSnack={5}
                        anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
                        autoHideDuration={3500}
                        style={{
                            whiteSpace: "pre-line",
                        }}
                    >
                        <Suspense fallback={<Loading />}>
                            <BrowserRouter>
                                <App />
                            </BrowserRouter>
                        </Suspense>
                    </SnackbarProvider>
                </ThemeProvider>
            </React.StrictMode>,
            document.getElementById("root")
        );
    })
    .catch((error) => {});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(//console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
