/*  eslint-disable @typescript-eslint/no-explicit-any */
import React, { ChangeEvent } from "react";
import { Box, Grid } from "@material-ui/core";
import { Pagination } from "@material-ui/lab";

// page numbers start with 1, so we reduce 1 in the first argument
const paginate = (array: any, page_size: any, page_number: any) =>
    array.slice((page_number - 1) * page_size, page_number * page_size);

export function useListPager<T>(records: T[]): {
    pagedRecords: T[];
    pagination: JSX.Element | null;
    setPage: React.Dispatch<React.SetStateAction<number>>;
    setRows: React.Dispatch<React.SetStateAction<number>>;
} {
    const [pagedRecords, setPagedRecords] = React.useState<T[]>([]);
    const [page, setPage] = React.useState<number>(1);
    const [rows, setRows] = React.useState<number>(10);
    const [pages, setPages] = React.useState<number>(1);

    React.useEffect(() => {
        // Do not update state as it will not show pager
        if (records.length !== 0) {
            setPagedRecords(() => paginate(records, rows, page));
        }
    }, [records, page, rows]);

    React.useEffect(() => {
        // Do not update state as it will not show pager
        if (records.length !== 0) {
            setPages(() => Math.ceil(records.length / rows));
        }
    }, [records.length, rows]);

    const handlePageChange = React.useCallback((event: ChangeEvent<unknown>, pageToSet: number) => {
        setPage(() => pageToSet);
    }, []);

    const pagination = React.useMemo(
        () =>
            pages > 1 ? (
                <Box pt={3} pb={3}>
                    <Grid container justify="center" alignContent="center" alignItems="center">
                        <Grid item>
                            <Pagination
                                color="primary"
                                page={page}
                                count={pages}
                                onChange={handlePageChange}
                            />
                        </Grid>
                    </Grid>
                </Box>
            ) : null,
        [page, pages]
    );

    return {
        pagedRecords,
        pagination,
        setPage,
        setRows,
    };
}
