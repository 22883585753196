import React from "react";
import { useTranslation } from "react-i18next";
import Moment from "moment";
import * as Core from "@material-ui/core";
import { Edit, Delete } from "@material-ui/icons";
import { UserDTO } from "../../../Api/Models/UserModels";

const useStyles = Core.makeStyles((theme) => ({
    root: {},
    container: {
        alignItems: "center",
        [theme.breakpoints.down("sm")]: {},
    },
    collapse: {
        marginTop: theme.spacing(1),
    },
    expand: {
        transform: "rotate(0deg)",
        marginLeft: "auto",
        padding: "0",
        transition: theme.transitions.create("transform", {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: "rotate(180deg)",
    },
    icon: {
        margin: theme.spacing(1),
    },
}));

interface UserCardProps {
    user: UserDTO;
    onEdit: (user: UserDTO) => void;
    onDelete: (user: UserDTO) => void;
}

const UserCard = ({ user, onEdit, onDelete }: UserCardProps): JSX.Element => {
    const classes = useStyles();
    const theme = Core.useTheme();
    const smDown = Core.useMediaQuery(theme.breakpoints.down("sm"));
    const { t } = useTranslation("app");

    const renderActions = () => {
        return (
            <Core.Grid container direction="row" alignItems="center" justify="flex-end">
                <Core.IconButton className="MuiIconButton-root" onClick={() => onEdit(user)}>
                    <Edit />
                </Core.IconButton>
                <Core.IconButton className="MuiIconButton-root" onClick={() => onDelete(user)}>
                    <Delete />
                </Core.IconButton>
            </Core.Grid>
        );
    };

    if (smDown) {
        return (
            <Core.Grid container direction="column" className={classes.root}>
                <Core.Grid container className={classes.container} alignContent="flex-start" alignItems="flex-end" justify="flex-start">
                    <Core.Grid item xs>
                        <Core.Typography color="primary" variant="body1">
                            {user.name}
                        </Core.Typography>
                    </Core.Grid>
                    <Core.Grid item>{renderActions()}</Core.Grid>
                </Core.Grid>
                <Core.Grid container direction="column">
                    <Core.Grid container>
                        <Core.Grid xs>
                            <Core.Typography color="textSecondary" variant="subtitle2">
                                {t("users.user.email")}
                            </Core.Typography>
                        </Core.Grid>
                        <Core.Grid xs>
                            <Core.Typography variant="subtitle2">{user.email}</Core.Typography>
                        </Core.Grid>
                    </Core.Grid>
                    <Core.Grid container>
                        <Core.Grid xs>
                            <Core.Typography color="textSecondary" variant="subtitle2">
                                User actions
                            </Core.Typography>
                        </Core.Grid>
                        <Core.Grid xs>
                            {user.email_verified_at === null && (
                                <Core.Chip label="Email not verified" size="small" variant="outlined" />
                            )}
                            {(user.rol === null || user.supervisor_id === null) && (
                                <Core.Chip label="No permission set" size="small" variant="outlined" />
                            )}
                        </Core.Grid>
                    </Core.Grid>

                    <Core.Grid container>
                        <Core.Grid xs>
                            <Core.Typography color="textSecondary" variant="subtitle2">
                                {t("users.user.userType")}
                            </Core.Typography>
                        </Core.Grid>
                        <Core.Grid xs>
                            <Core.Typography variant="subtitle2">{user.rol?.name}</Core.Typography>
                        </Core.Grid>
                    </Core.Grid>
                    <Core.Grid container>
                        <Core.Grid item xs>
                            <Core.Typography color="textSecondary" variant="subtitle2">
                                {t("users.user.createdAt")}
                            </Core.Typography>
                        </Core.Grid>
                        <Core.Grid item xs>
                            <Core.Typography variant="body2">
                                {new Date(user.created_at).toLocaleString()}
                            </Core.Typography>
                        </Core.Grid>
                    </Core.Grid>
                </Core.Grid>
            </Core.Grid>
        );
    }

    return (
        <Core.TableRow key={user.id}>
            <Core.TableCell>
                <Core.Typography variant="subtitle2">{user.name}</Core.Typography>
            </Core.TableCell>
            <Core.TableCell>
                <Core.Typography variant="subtitle2">{user.email}</Core.Typography>
            </Core.TableCell>
            <Core.TableCell>{Moment(user.created_at).format("YY/MM/DD - hh:mm")}</Core.TableCell>
            <Core.TableCell>
                <Core.Typography variant="subtitle2">{user.rol?.name}</Core.Typography>
            </Core.TableCell>
            <Core.TableCell>
                <Core.Grid item xs>
                    {renderActions()}
                </Core.Grid>
            </Core.TableCell>
        </Core.TableRow>
    );
};

export default UserCard;
