import axios from "../AxiosConfig";
import * as Constants from "../ApiConstants";
import * as Models from "../Models/UserModels";

export class UserService {

    async getUsers(): Promise<Models.UserDTO[]> {
        return await axios
            .get(`${Constants.APIHost}${Constants.APIUser}/users`)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                //console.log(error);
                if (error.response) {
                    console.log(error.response);
                }
                throw error;
            });
    }

    static async getUser(id: string): Promise<Models.UserDTO> {
        return await axios
            .get(`${Constants.APIHost}${Constants.APIUser}/user`, {
                params:{
                    id: id
                },
                headers: {
                    "Content-Type": "application/json",
                },
            })
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                // //console.log(error);
                if (error.response) {
                    console.log(error.response);
                }
                throw error;
            });
    }

    async createUser(data: Models.CreateUserCommand) {
        return await axios
            .post(`${Constants.APIHost}${Constants.APIUser}/create`, data)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                // //console.log(error);
                if (error.response) {
                    console.log(error.response);
                }
                throw error;
            });
    }

    async updateUser(data: Models.EditUserCommand) {
        return await axios
            .put(`${Constants.APIHost}${Constants.APIUser}/update`, data)
            .then((response) => {
                // //console.log(response);
                return response;
            })
            .catch((error) => {
                // //console.log(error);
                if (error.response) {
                    console.log(error.response);
                }
                throw error;
            });
    }

    static async updateUserOwn(data: Models.EditUserOwnCommand) {
        // //console.log(data);
        return await axios
            .put(`${Constants.APIHost}${Constants.APIUser}/updateown`, data)
            .then((response) => {
                // //console.log(response);
                return response;
            })
            .catch((error) => {
                // //console.log(error);
                if (error.response) {
                    console.log(error.response);
                }
                throw error;
            });
    }

    async deleteUser(data: Models.DeleteUserCommand) {
        return await axios
            .delete(`${Constants.APIHost}${Constants.APIUser}/delete`, {
                params: data,
            })
            .then((response) => {
                return response;
            })
            .catch((error) => {
                if (error.response) {
                    console.log(error.response);
                }
                throw error;
            });
    }

    async resetPassword(data: Models.DeleteUserCommand) {
        return await axios
            .delete(`${Constants.APIHost}${Constants.APIUser}/delete`, {
                params: data,
            })
            .then((response) => {
                return response;
            })
            .catch((error) => {
                if (error.response) {
                    console.log(error.response);
                }
                throw error;
            });
    }

    async accountValidated(data: Models.UserDTO) {
        return await axios
            .delete(`${Constants.APIHost}${Constants.APIValidatedMAil}/AccountValidated`, {
                params: data,
            })
            .then((response) => {
                return response;
            })
            .catch((error) => {
                if (error.response) {
                    console.log(error.response);
                }
                throw error;
            });
    }
}

export const userService = new UserService();
