import React from "react";
import { Fade } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

const SkeletonList = ({
    rowHeight = 80,
    rowNumber = 4,
}: {
    rowHeight?: number;
    rowNumber?: number;
}): JSX.Element => {
    const skeleton = Array(rowNumber)
        .fill(0)
        // For this simple component there is no need to use uuids
        // eslint-disable-next-line react/no-array-index-key
        .map((v, i) => <Skeleton key={i} height={rowHeight} />);

    return (
        <Fade in={true}>
            <div>{skeleton}</div>
        </Fade>
    );
};

export default SkeletonList;
