import React, { Suspense } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { AppRoutes } from "../Helpers/ApplicationConstants";
import Loading from "./Common/Components/Loading";
import { Layout } from "./Common/Layout/Layout";
import SidePanelM from "./Common/Layout/SidePanel";
import Profile from "./Profile/Profile";
import Dashboard from "./Dashboard/Dashboard";
import Users from "./Users/Users";
import Projects from "./Projects/Projects";

export default function Dbrief(): JSX.Element {
    return (
        <Layout>
            <SidePanelM>
                <Suspense fallback={<Loading />}>
                    <Switch>
                        <Redirect exact path="/" to={AppRoutes.Dashboard} />
                        <Route exact path={AppRoutes.Dashboard} component={Dashboard} />
                        <Route path={AppRoutes.Administration.Profile} component={Profile} />
                        <Route path={AppRoutes.Administration.Users} component={Users} />
                        <Route path={AppRoutes.Projects} component={Projects} />
                    </Switch>
                </Suspense>
            </SidePanelM>
        </Layout>
    );
}
