export const APIHost = process.env.REACT_APP_API_HOST;
export const APIMailVerification = "/email";
export const APIValidatedMAil = "/validated";
export const APIProject = "/projects";
export const APIUser = "/user";
export const APIResetPassword = "/resetPassword";

export enum UserRolesType {
    Administrator = 1,
    Manager = 2,
    User = 3,
    Communications = 4,
}
