import axios from "axios";

const axiosInstance = axios.create();
axiosInstance.defaults.headers.common["Content-Type"] = "application/json";

axiosInstance.interceptors.request.use(
    async (config) => {
        const configUpdate = config;

        configUpdate.headers = {
            Authorization: `Bearer ${localStorage.getItem("t")}`,
        };

        return configUpdate;
    },
    (error) => {
        Promise.reject(error);
    }
);

export default axiosInstance;
