import React from "react";
import * as Core from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { IDeleteDialog } from "../../Common/GenericInterfaces/GenericInterfaces";
import { ProjectService } from "../../../Api/Services/ProjectService";
import { DeleteProjectCommand, ProjectDTO } from "../../../Api/Models/ProjectModels";
import { AlertNotification } from "../../Common/Components/Error/AlertNotification";

const ProjectDeleteDialog = ({
    isOpen,
    closeDialog,
    record,
    deleteRecord,
}: IDeleteDialog<ProjectDTO>): JSX.Element => {
    const { t } = useTranslation("app");
    const theme = useTheme();
    const fullScreen = Core.useMediaQuery(theme.breakpoints.down("xs"));
    const { enqueueSnackbar } = useSnackbar();

    const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);

    const handleDelete = () => {
        if (!record) {
            return;
        }

        const data: DeleteProjectCommand = {
            id: record.id,
        };

        ProjectService.deleteProject(data)
            .then((res) => {
                deleteRecord(record);
                closeDialog();
            })
            .catch((error) => {
                AlertNotification(error, "DeleteProject", enqueueSnackbar, {
                    variant: "warning",
                });
                closeDialog();
            })
            .finally(() => {
                setIsSubmitting(false);
            });
    };

    return (
        <Core.Dialog
            open={isOpen}
            onClose={closeDialog}
            fullScreen={fullScreen}
            fullWidth={true}
            maxWidth="xs"
            disableBackdropClick={isSubmitting}
            disableEscapeKeyDown={isSubmitting}
        >
            <Core.DialogTitle>
                {t("projects.deleteProject")} {record?.Name}
            </Core.DialogTitle>
            <Core.DialogContent>
                <Core.DialogContentText>{t("projects.deleteProjectText")}</Core.DialogContentText>
            </Core.DialogContent>
            <Core.DialogActions>
                <Core.Button onClick={closeDialog} color="default">
                    {t("general.cancel")}
                </Core.Button>
                <Core.Button onClick={handleDelete} color="primary">
                    {t("general.delete")}
                </Core.Button>
            </Core.DialogActions>
        </Core.Dialog>
    );
};

const ProjectDeleteDialogM = React.memo(ProjectDeleteDialog);

export default ProjectDeleteDialogM;
