import React from "react";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { createStyles, makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import {
    DialogContent,
    DialogContentText,
    TextField,
    Button,
    useMediaQuery,
    DialogActions,
    Dialog,
    Typography,
    Grid,
} from "@material-ui/core";
import { Controller, useForm } from "react-hook-form";
import { AlertNotification } from "../Common/Components/Error/AlertNotification";
import Loading from "../Common/Components/Loading";
import { ForgotPasswordCommand } from "../../Api/Models/UserModels";
import { resetPasswordService } from "../../Api/Services/ResetPasswordService";

interface IFormValues {
    email: string;
}

const InitialValues: IFormValues = {
    email: "",
};

interface IForgotPasswordFormProps {
    isFormOpen: boolean;
    closeForm: () => void;
    handleSuccess: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            height: "100%",
            backgroundColor: "#FFFFFF",
            minHeight: 600,
        },
        logoContainer: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            [theme.breakpoints.down("xs")]: {
                width: "150px",
            },
            [theme.breakpoints.up("sm")]: {
                width: "175px",
            },
        },
        logo: {
            width: "65%",
        },
        logoDbrief: {
            width: "100%",
        },
        logoByIberchem: {
            marginTop: 10,
            width: 50,
        },
    })
);

const ForgotPassword = ({
    isFormOpen,
    closeForm,
    handleSuccess,
}: IForgotPasswordFormProps): JSX.Element => {
    const classes = useStyles();
    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.down("xs"));
    const { t } = useTranslation("app");
    const { enqueueSnackbar } = useSnackbar();

    const { handleSubmit, reset, watch, control } = useForm<IFormValues>({
        defaultValues: InitialValues,
    });

    const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);

    const handleCloseForm = () => {
        reset(InitialValues);
        closeForm();
    };

    const handleEmailSendSuccess = () => {
        AlertNotification(
            t("forgotPassword.notifications.emailSended"),
            t("forgotPassword.notifications.forgotPassword"),
            enqueueSnackbar,
            {
                variant: "success",
            }
        );
        handleSuccess();
        handleCloseForm();
    };

    const handleSendForgotPassword = (data: IFormValues) => {
        setIsSubmitting(() => true);

        const forgotPasswordCommand: ForgotPasswordCommand = {
            email: data.email,
        };

        resetPasswordService
            .sendForgotPasswordMail(forgotPasswordCommand)
            .then((response) => {
                handleEmailSendSuccess();
            })
            .catch((error) => {
                AlertNotification(
                    error,
                    t("forgotPassword.notifications.forgotPassword"),
                    enqueueSnackbar
                );
            })
            .finally(() => {
                setIsSubmitting(() => false);
            });
    };

    return (
        <Dialog
            open={isFormOpen}
            onClose={handleCloseForm}
            fullScreen={isXs}
            fullWidth={true}
            maxWidth="xs"
            disableBackdropClick
            disableEscapeKeyDown
        >
            {isSubmitting && (
                <Loading label={t("general.processing")} useCapital useAbsolute useBackground />
            )}
            <form onSubmit={handleSubmit(handleSendForgotPassword)}>
                <DialogContent>
                    <DialogContentText align="center">
                        <Typography color="primary" variant="h4">
                            {t("forgotPassword.title")}
                        </Typography>
                    </DialogContentText>
                    <Controller
                        name="email"
                        control={control}
                        render={({ field, fieldState }) => (
                            <TextField
                                {...field}
                                fullWidth
                                variant="outlined"
                                label={t("forgotPassword.email")}
                                error={!!fieldState.error}
                                helperText={fieldState.error?.message}
                                disabled={isSubmitting}
                            />
                        )}
                        rules={{
                            required: "User email is required",
                            pattern: {
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                message: t("forgotPassword.notifications.invalidEmail"),
                            },
                        }}
                    />
                </DialogContent>
                <DialogActions style={{ marginTop: 20 }}>
                    <Button
                        disabled={isSubmitting}
                        size="large"
                        onClick={handleCloseForm}
                        variant="contained"
                    >
                        {t("general.cancel")}
                    </Button>
                    <Button
                        type="submit"
                        disabled={isSubmitting}
                        color="primary"
                        variant="contained"
                        size="large"
                    >
                        {t("forgotPassword.send")}
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};

export default ForgotPassword;
