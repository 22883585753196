import React, { useState } from "react";
import { Grid, useMediaQuery, Button, IconButton, Fab } from "@material-ui/core";
import { useTheme, createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { sidePanelSize, subSidePanelSize } from "./SidePanel";
import CategoryHeader from "../Components/CategoryHeader";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            height: "100%",
        },
        container: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
            marginBottom: theme.spacing(5),
            [theme.breakpoints.down("xs")]: {
                paddingLeft: 0,
                paddingRight: 0,
                paddingBottom: sidePanelSize,
            },
        },
        containerPadded: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
            marginBottom: theme.spacing(5),
            [theme.breakpoints.down("xs")]: {
                paddingLeft: theme.spacing(2),
                paddingRight: theme.spacing(2),
                paddingBottom: sidePanelSize,
            },
        },
        topPanel: {
            paddingTop: theme.spacing(3),
            paddingBottom: theme.spacing(3),
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
        },
        bottomPanel: {
            position: "fixed",
            height: subSidePanelSize,
            width: "100%",
            bottom: sidePanelSize,
            zIndex: 1,
            backgroundColor: theme.palette.background.default,
            boxShadow: `0  -1px 3px 0px ${theme.palette.secondary.main}`,
        },
        itemTopPanel: {
            marginLeft: theme.spacing(1),
        },
        fab: {
            position: "fixed",
            bottom: theme.spacing(2) + sidePanelSize, // theme.spacing(2),
            right: theme.spacing(2),
        },
    })
);

interface IItem {
    action: () => void;
    label: string;
    icon?: JSX.Element;
}

interface ISubPanelProps {
    title?: string;
    itemsList: IItem[];
    children: JSX.Element;
    padContent?: boolean;
}

const SubPanel = ({ title, itemsList, children, padContent }: ISubPanelProps) => {
    const theme = useTheme();
    const classes = useStyles();
    const isXs = useMediaQuery(theme.breakpoints.down("xs"));

    const [selectedItem, setSelectedItem] = useState<IItem | undefined>(undefined);

    const handleSubsidePanelItemChange = (value: string) => {
        let selected: IItem;
        Object.values(itemsList).forEach((itemGroup) => {
            Object.values(itemGroup).forEach((item) => {
                if (item.label === value) {
                    selected = item;
                }
            });
        });

        setSelectedItem(() => selected);
    };

    const renderTitle = () => {
        if (!isXs) {
            return (
                <Grid item xs>
                    <CategoryHeader title={title} />
                </Grid>
            );
        }

        return (
            <Grid item>
                <CategoryHeader title={title} />
            </Grid>
        );
    };

    const renderItem = (item: IItem) => {
        return (
            <Grid item key={item.label} className={classes.itemTopPanel}>
                {isXs ? (
                    <IconButton
                        color="primary"
                        onClick={(e) => {
                            item.action();
                        }}
                    >
                        {item.icon || null}
                        {/* {item.label} */}
                    </IconButton>
                ) : (
                    <Button
                        startIcon={item.icon || undefined}
                        variant="contained"
                        color="primary"
                        onClick={(e) => {
                            item.action();
                        }}
                    >
                        {item.label}
                    </Button>
                )}
            </Grid>
        );
    };

    if (isXs && itemsList.length === 1) {
        const item = itemsList[0];

        return (
            <Grid
                container
                className={classes.root}
                direction="column"
                alignContent="stretch"
                alignItems="stretch"
                wrap="nowrap"
            >
                <Grid item className={padContent ? classes.containerPadded : classes.container} xs>
                    {children}
                </Grid>
                <Fab
                    key={item.label}
                    aria-label={item.label}
                    className={classes.fab}
                    color="primary"
                    onClick={(e) => {
                        item.action();
                    }}
                >
                    {item.icon || <></>}
                </Fab>
            </Grid>
        );
    }

    return (
        <Grid
            container
            className={classes.root}
            direction="column"
            alignContent="stretch"
            alignItems="stretch"
            wrap="nowrap"
        >
            {!isXs && (
                <Grid
                    container
                    className={classes.topPanel}
                    direction="row"
                    alignContent="flex-start"
                    alignItems="flex-start"
                    justify="flex-start"
                >
                    {title ? renderTitle() : null}
                    <Grid
                        container
                        direction="row"
                        alignContent="flex-end"
                        alignItems="flex-end"
                        justify="flex-end"
                    >
                        {itemsList.map((item) => renderItem(item))}
                    </Grid>
                </Grid>
            )}

            <Grid item className={padContent ? classes.containerPadded : classes.container} xs>
                {children}
            </Grid>

            {isXs &&
                (itemsList.length === 1 ? (
                    itemsList.map((item) => {
                        return (
                            <Fab
                                key={item.label}
                                aria-label={item.label}
                                className={classes.fab}
                                color="primary"
                                onClick={(e) => {
                                    item.action();
                                }}
                            >
                                {item.icon || <></>}
                            </Fab>
                        );
                    })
                ) : (
                    <Grid
                        container
                        className={classes.bottomPanel}
                        direction="row"
                        alignContent="center"
                        alignItems="center"
                        justify="space-between"
                    >
                        {title ? renderTitle() : null}
                        {itemsList.map((item) => renderItem(item))}
                    </Grid>
                ))}
        </Grid>
    );
};

const SubPanelM = React.memo(SubPanel);

export default SubPanelM;
