import { OptionsObject } from "notistack";

const maxErrorsDiplayed = 2;
const maxErrorLines = 3;

// TODO specify error from API
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-throw-literal */
export const AlertNotification = (
    ex: any,
    context: string,
    alert: any,
    options?: OptionsObject
): void => {
    let optionsMod: OptionsObject = { variant: "warning" };

    if (options) {
        optionsMod = { ...options };
    }

    let statusMessage = "";
    let errorMessage = "";

    if (ex && ex.response) {
        if (ex.response.status && ex.response.statusText) {
            statusMessage = `${context || ""} :: ${ex.response.status} - ${ex.response.statusText}`;

            alert(statusMessage, optionsMod);
        }
        if (ex.response.data && ex.response.data.errors) {
            Object.keys(ex.response.data.errors).forEach((errorKey, errorIndex) => {
                if (errorIndex === maxErrorsDiplayed) {
                    alert("...", optionsMod);
                } else if (errorIndex < maxErrorsDiplayed) {
                    errorMessage = `${errorKey} ::`;
                    const errorObject = ex.response.data.errors[errorKey];

                    Object.keys(errorObject).forEach((errorObjectKey, keyIndex) => {
                        if (keyIndex < maxErrorLines) {
                            errorMessage = `${errorMessage}\n${errorObject[errorObjectKey]}`;
                        }
                    });

                    alert(errorMessage, optionsMod);
                }
            });
        }
    } else if (ex) {
        if (ex.status && ex.statusText) {
            statusMessage = `${context || ""} :: ${ex.status} - ${ex.statusText}`;

            alert(statusMessage, optionsMod);
        }
        if (ex.errors) {
            Object.keys(ex.errors).forEach((errorKey, errorIndex) => {
                if (errorIndex >= maxErrorsDiplayed) {
                    alert("...", optionsMod);
                    throw {};
                } else if (errorIndex < maxErrorsDiplayed) {
                    errorMessage = `${errorKey} ::`;
                    const errorObject = ex.errors[errorKey];

                    Object.keys(errorObject).forEach((errorObjectKey, keyIndex) => {
                        if (keyIndex < maxErrorLines) {
                            errorMessage = `${errorMessage}\n${errorObject[errorObjectKey]}`;
                        }
                    });
                    alert(errorMessage, optionsMod);
                }
            });
        }
        if (ex) {
            let alertMessage = `${context || ""}`;
            alertMessage = `${alertMessage} :: ${ex}`;

            alert(alertMessage, optionsMod);
        }
    }
};
