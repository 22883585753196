import React from "react";
import { Controller, FieldPath, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import * as Core from "@material-ui/core";
import * as icons from "@material-ui/icons";
import { ProjectService } from "../../../Api/Services/ProjectService";
import * as Models from "../../../Api/Models/ProjectModels";
import { AlertNotification } from "../../Common/Components/Error/AlertNotification";
import * as FormFunctions from "./FormHelpersFunctions";
import { IFieldsSubsections, subSections } from "./SubSections";
import "./RadioButton.css";
import SkeletonList from "../../Common/Components/SkeletonList";
import { ReactComponent as LogoOk } from "../../../../Assets/Img/icons/logo_ok.svg";
import Select from 'react-select'
import countryList from 'react-select-country-list'

const InitialValues: IFieldsSubsections = {
    // Subsection 0
    Name: "",
    Client_Name: "",
    Country: "",
    Contact_Person: "",
    ProductType: "",

    // Subsection 1
    Price_KG: 0,
    Price_KG_Currency: "",
    Fragrance_Cost: 0,
    Fragrance_Cost_Currency: "",
    Price_Purchasing_Extra: "",

    // Subsection 2
    Sample_Received: "",
    Product_Reference: "",
    Product_Reference_Image: "",

    // Subsection 3
    Olfactory_Profile: "",
    Olfactory_Extra: "",

    // Subsection 4
    Target_End_Customer: "",
    Target_Age_Range: [0, 45],
    Unknown_Age: 0,

    // Subsection 5
    Colour: "",
    Colour_Extra: "",

    // Subsection 6
    Finished_Product_Appearance: "",
    PH: [0, 14],
    Dosage: 0,
    Customer_Base: 0,
    Customer_Base_Qty: 0,

    // Subsection 7
    Need_Stability_Test: 0,
    Oven_Temperature: 0,
    Test_Duration: 0,
    Stability_Extra: "",

    // Subsection 8
    Cosmos_Ecocert: 0,
    Flower_Ecolabel: 0,
    California: 0,
    South_Korea: 0,
    Blauer_Engel: 0,
    Nordic_Swan: 0,
    Oko_Test: 0,
    Bra_Miljoval: 0,
    IFRA: 0,
    VOC: 0,
    Regulations_Extra: "",
    Allergen_Restrictions: "",

    // Subsection 9
    Deadline: null,
    Maximum_Samples_Submit: "",
    Fragrance_Qty: 0,
    Finished_Product: 0,

    //Subsection 10
    Olfactory_Piramid: 0,
    Custom_Presentation: 0,
    Update_Presentation: 0,
    Trend_Report: 0,
    Market_Research: 0,

    // Subsection 11
    Extra_Information: "",
};

const useStyles = Core.makeStyles((theme) => ({
    rootCore: {
        height: "100%",
    },
    GridChild: {
        minHeight: "100px",
    },
    Grid: {
        width: "30%",
    },
    GridImages: {
        // width: "30%",
        marginTop: "50 px",
    },
    reportSection: {
        marginBottom: theme.spacing(2),
    },
}));

const Switch = Core.withStyles((theme) => ({
    root: {
        width: 90,
        height: 47,
        padding: 14,
        marginRight: 10,
        "& .MuiSwitch-switchBase.Mui-checked": {
            transform: "translateX(45px)",
        },
    },
    thumb: {
        width: 30,
        height: 30,
    },
    track: {
        width: 80,
        height: 5,
        borderRadius: 40,
        marginTop: 9,
    },
}))(Core.Switch);

interface IProjectFormProps {
    isFormOpen: boolean;
    closeForm: () => void;
    projectRecord: Models.ProjectDTO | undefined;
    reloadProjects?: () => void;
}

const ProjectFormDialogs = ({
    isFormOpen,
    closeForm,
    projectRecord,
    reloadProjects,
}: IProjectFormProps): JSX.Element => {
    const theme = Core.useTheme();
    const classes = useStyles();
    const fullScreen = Core.useMediaQuery(theme.breakpoints.down("sm"));
    const { t } = useTranslation("app");
    const { enqueueSnackbar } = useSnackbar();
    const canEdit = true;

    // #region USESTATE FUNCTIONS
    const [isLoadingForm, setIsLoadingForm] = React.useState<boolean>(false);
    const [isReportOpen, setIsReportOpen] = React.useState<boolean>(false);
    const [isCompletedOpen, setIsCompletedOpen] = React.useState<boolean>(false);
    const [isSendingMail, setIsSendingMail] = React.useState<boolean>(false);
    const [subSection, setSubSection] = React.useState<number>(0);
    const [projectId, setProjectId] = React.useState<string | undefined>(projectRecord?.id);
    const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);

    //OLFACTORY
    const [haveSelectedOlfactory, setHaveSelectedOlfactory] = React.useState<boolean>(false);
    const [olfactorySelection, setOlfactorySelection] = React.useState<string>("");
    const [checkedOlfactory, setCheckedOlfactory] = React.useState(new Array(FormFunctions.olfactoryProfileLabels.length).fill(false));

    //COLOUR
    const [haveSelectedColour, setHaveSelectedColour] = React.useState<boolean>(false);
    const [colourSelection, setColourSelection] = React.useState<string>("");
    const [checkedColour, setCheckedColour] = React.useState(new Array(FormFunctions.colourLabels.length).fill(false));

    // #endregion

    // #region PROJECTS

    const handleCreateProject = async () => {
        const isValid = await trigger(subSections[subSection].fields);
        if (isValid) {
            setIsSubmitting(true);

            const values = getValues(["Name", "Client_Name", "Contact_Person", "Country", "ProductType"]);

            const createProjectCommand: Models.CreateProjectCommand = {
                Name: values[0],
                Client_Name: values[1], 
                Contact_Person: values[2],
                Country: values[3],
                ProductType: values[4],
            };

            ProjectService.createProject(createProjectCommand)
                .then((response) => {
                    setProjectId(() => response);
                    goSection();
                    if (reloadProjects) {
                        reloadProjects();
                    }
                })
                .catch((error) => {
                    AlertNotification(error, "Could not create project", enqueueSnackbar, {
                        variant: "error",
                    });
                })
                .finally(() => {
                    setIsSubmitting(() => false);
                });
        }
    };

    const handleUpdateProject = (
        step: number,
        fields: | FieldPath<IFieldsSubsections>[] | readonly FieldPath<IFieldsSubsections>[]) => {
        if (projectId === undefined) {
            throw new Error("Project id is not set");
        }

        const values = getValues(fields);
        const dataToSave = (fields as string[]).reduce(
            (prev, current, index) => ({ ...prev, [current]: values[index] }),
            {}
        );

        setIsSubmitting(() => true);

        const editProjectCommand: Models.EditProjectCommand = { id: projectId, LastStep: step, data: dataToSave, };

        ProjectService.updateProject(editProjectCommand)
            .then((response) => {
                // //console.log(response);
            })
            .catch((error) => {
                AlertNotification(error, "Error saving last changes", enqueueSnackbar, {
                    variant: "error",
                });
            })
            .finally(() => {
                setIsSubmitting(() => false);
            });
    };

    // #endregion

    // #region REPORT
    const openReport = async () => {
        const isValid = await trigger(subSections[subSection].fields);
        if (isValid) {
            handleUpdateProject(subSection, subSections[subSection].fields);
            setIsReportOpen(() => true);
        }
    };

    const closeReport = React.useCallback(() => { setIsReportOpen(() => false); }, []);
    // #endregion

    // #region SECTIONS

    const handleCloseCompleted = React.useCallback(() => { setIsCompletedOpen(() => false); closeForm(); }, []);

    const { register, control, reset, trigger, watch, clearErrors, getValues, setValue } =
        useForm<IFieldsSubsections>({
            defaultValues: InitialValues,
            mode: "all",
        });

    const watchProductReferenceImage = watch("Product_Reference_Image");
    const watchSampleReceived = watch("Sample_Received");

    const options = React.useMemo(() => countryList().getData(), []);

    const getBase64 = (file: File): Promise<string> => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result as string);
            reader.onerror = (error) => reject(error);
        });
    };

    const handleClick = () => {
        if (subSection < 10) {
            if (projectId === undefined) {
                handleCreateProject();
            }
            else {
                if ((subSection !== 3 && subSection !== 5) || (subSection === 3 && haveSelectedOlfactory) || (subSection === 5 && haveSelectedColour)) {
                    handleGoSection();
                }
            }
        }
        else {
            openReport();
        }
    }

    const handleGoSection = async () => {
        const isValid = await trigger(subSections[subSection].fields);
        if (isValid) {
            handleUpdateProject(subSection, subSections[subSection].fields);
            goSection();
        }
    };

    const goSection = React.useCallback(
        () =>
            setSubSection((prev) => {
                const sub = Math.min(prev + 1, subSections.length - 1);

                let serviceValue = getValues("Sample_Received");

                if (prev === 2) 
                {
                    if (serviceValue === "Free concept") 
                    {
                        return 3;
                    }
                    else 
                    {
                        return 4;
                    }
                }
                else if (prev === 3) 
                {
                    return 4;
                }
                else 
                {
                    return sub;
                }
            }),
        []
    );

    const handleBackSection = async () => {
        const isValid = await trigger(subSections[subSection].fields);
        if (isValid) 
        {
            if ((subSection !== 3 && subSection !== 5) || (subSection === 3 && haveSelectedOlfactory) || (subSection === 5 && haveSelectedColour)) {
                handleUpdateProject(subSection, subSections[subSection].fields);
                backSection();
            }
        }
    };

    const backSection = React.useCallback(
        () =>
            setSubSection((prev) => {

                const sub = Math.max(0, prev - 1);

                let serviceValue = getValues("Sample_Received");

                if (prev === 4) {
                    if (serviceValue === "Free concept") {
                        return 3;
                    }
                    else {
                        return 2;
                    }
                }
                else if (prev === 3) {
                    return 2;
                }
                else {
                    return sub;
                }
            }),
        []
    );

    // #endregion

    // #region OTHERS

    const handleMailProject = () => {
        if (projectId === undefined) return;
        setIsSendingMail(() => true);

        const mailProjectCommand: Models.MailProjectCommand = {
            id: projectId,
        };

        const blockProjectCommand: Models.BlockProjectCommand = {
            id: projectId,
            blocked: 1
        }

        ProjectService.mailProject(mailProjectCommand)
        .then((response) => {
            setIsCompletedOpen(() => true);
            ProjectService.blockProject(blockProjectCommand)
                .then()
                .catch((error) => {
                    AlertNotification(error, t("projects.notifications.blockProject"), enqueueSnackbar);
            })
            .finally(() => {
                closeReport();
                setIsSendingMail(() => false);
            });            
        })
        .catch((error) => {
            AlertNotification(error, t("projects.notifications.mailProject"), enqueueSnackbar);
        })
    }

    const handleClickLabel = () => {
        if (subSection === 0 && projectId === undefined) {
            return "Create";
        }
        if (subSections.length - 1 === subSection) {
            return "Send";
        }
        return "Next";
    }

    const getMinDate = (): string => {
        const date = new Date();
        date.setDate(date.getDate() + 15);
        return date.toISOString().slice(0, 10);
    }

    const valuetext = (value: number, index: number) => {
        let sValue = `${value}`;
        if (value === 45) {
            sValue = `+ ${value}`;
        }
        return sValue;
    }

    const styleSelectCountries = {
        control: styles => ({
            ...styles,
            marginTop: '20px',
            height: "50px"
        }),
    }

    const AddRemoveOlfactoryValue = (value: string, checked: boolean, position: number) => {
        const updatedCheckedState = checkedOlfactory.map((item, index) =>
            index === position ? !item : item
        );

        setCheckedOlfactory(updatedCheckedState);

        let newValue = "";

        if (checked) {
            newValue = value + "," + olfactorySelection;
        }
        else {
            newValue = olfactorySelection.replace(value + ",", "");
        }

        setOlfactorySelection(() => newValue);
        setValue("Olfactory_Profile", newValue);

        if (newValue !== "") {
            setHaveSelectedOlfactory(() => true);
        }
        else {
            setHaveSelectedOlfactory(() => false);
        }
    }

    const AddRemoveColourValue = (value: string, checked: boolean, position: number) => {
        const updatedCheckedState = checkedColour.map((item, index) =>
            index === position ? !item : item
        );

        setCheckedColour(updatedCheckedState);

        let newValue = "";

        if (checked) {
            newValue = value + "," + colourSelection;
        }
        else {
            newValue = colourSelection.replace(value + ",", "");
        }

        setColourSelection(() => newValue);
        setValue("Colour", newValue);

        if (newValue !== "") {
            setHaveSelectedColour(() => true);
        }
        else {
            setHaveSelectedColour(() => false);
        }
    }
    // #endregion

    // LAST PROJECT
    React.useEffect(() => {
        if (isFormOpen) {
            if (projectRecord !== undefined) {
                setIsLoadingForm(() => true);
                ProjectService.getProject(projectRecord.id)
                    .then((res) => {
                        const { id, LastStep, ...rest } = res;
                        setProjectId(() => id);
                        setSubSection(() => LastStep);
                        reset(rest);
                    })
                    .finally(() => {
                        setIsLoadingForm(() => false);
                    });
            } else {
                reset(InitialValues);
                setProjectId(() => undefined);
                setSubSection(() => 0);
            }
        }
    }, [isFormOpen]);

    React.useEffect(() => {
        let olfactoryValues = getValues("Olfactory_Profile");
        let colourValues = getValues("Colour");

        if (olfactoryValues != null && olfactoryValues != "") {
            let arrayValues = olfactoryValues.toString().split(",");
            setHaveSelectedOlfactory(true);

            arrayValues.forEach(
                function (x) {
                    FormFunctions.olfactoryProfileLabels.forEach(
                        function (l) {
                            if (l.label == x) {
                                checkedOlfactory[l.index] = true;
                            }
                        }
                    )
                }
            )
        }

        if (colourValues != null && colourValues != "") {
            let arrayValues = colourValues.toString().split(",");
            setHaveSelectedColour(true);

            arrayValues.forEach(
                function (x) {
                    FormFunctions.colourLabels.forEach(
                        function (l) {
                            if (l.label == x) {
                                checkedColour[l.index] = true;
                            }
                        }
                    )
                }
            )
        }
    });

    return (
        <>
            <Core.Dialog open={isFormOpen} onClose={closeForm} fullScreen={fullScreen} fullWidth={true} maxWidth="sm" disableBackdropClick disableEscapeKeyDown>
                {isLoadingForm && (
                    <div style={{ padding: 20 }}>
                        <SkeletonList rowHeight={100} rowNumber={5} />
                    </div>
                )}
                {!isLoadingForm && (
                    <>
                        <div style={{ textAlign: "center", position: "absolute", top: "0px", width: "100%", }}>
                            <Core.LinearProgress
                                style={{ height: "8px" }}
                                variant={isSubmitting ? "indeterminate" : "determinate"}
                                value={(subSection / (subSections.length - 1)) * 100}
                            />
                        </div>

                        <Core.DialogTitle>
                            <div style={{ position: "absolute", right: "5px", top: "5px" }}>
                                <Core.IconButton onClick={closeForm}>
                                    <icons.Close fontSize="large" />
                                </Core.IconButton>
                            </div>
                            <div>
                                <Core.Typography variant="subtitle1">
                                    {subSections[subSection].section}
                                </Core.Typography>
                                <Core.Typography variant="h4" color="primary">
                                    {subSections[subSection].name}
                                </Core.Typography>
                            </div>
                        </Core.DialogTitle>

                        <Core.DialogContent>
                            <Core.Grid>
                                {subSection === 0 && (
                                    <>
                                        <Core.Grid item className={classes.rootCore}>
                                            <Controller name="Name" control={control} render={({ field, fieldState }) => (
                                                <Core.TextField
                                                    {...field}
                                                    fullWidth
                                                    variant="outlined"
                                                    InputProps={{ readOnly: !canEdit }}
                                                    label="Project name"
                                                    error={!!fieldState.error}
                                                    helperText={fieldState.error?.message}
                                                    style={{ marginTop: "20px" }}
                                                />
                                            )}
                                                rules={{ required: "Project name is required" }}
                                            />

                                            <Controller name="Client_Name" control={control} render={({ field, fieldState }) => (
                                                <Core.TextField
                                                    {...field}
                                                    fullWidth
                                                    variant="outlined"
                                                    InputProps={{ readOnly: !canEdit }}
                                                    label="Client name"
                                                    error={!!fieldState.error}
                                                    helperText={fieldState.error?.message}
                                                    style={{ marginTop: "20px" }}
                                                />
                                            )}
                                                rules={{ required: "Client name is required" }}
                                            />

                                            <Controller name="Contact_Person" control={control} render={({ field, fieldState }) => (
                                                <Core.TextField
                                                    {...field}
                                                    fullWidth
                                                    variant="outlined"
                                                    InputProps={{ readOnly: !canEdit }}
                                                    label="Contact Person & Job Title"
                                                    error={!!fieldState.error}
                                                    helperText={fieldState.error?.message}
                                                    style={{ marginTop: "20px" }}
                                                />
                                            )}
                                                rules={{ required: "Contact person is required" }}
                                            />                                            

                                            <Controller name="ProductType" control={control} render={({ field, fieldState }) => (
                                                <Core.TextField
                                                    {...field}
                                                    fullWidth
                                                    variant="outlined"
                                                    InputProps={{ readOnly: !canEdit }}
                                                    label="Product type"
                                                    error={!!fieldState.error}
                                                    helperText={fieldState.error?.message}
                                                    style={{ marginTop: "20px" }}
                                                />
                                            )}
                                                rules={{ required: "Product Type is required" }}
                                            />

                                            <Controller name="Country" control={control} render={({ field: { onChange, value }, fieldState }) => (
                                                <Core.Grid>
                                                    <Select
                                                        options={options}
                                                        value={options.find(c => c.value === value)}
                                                        onChange={(x) => (onChange(x.value))}
                                                        styles={styleSelectCountries}
                                                    />
                                                    {!!fieldState.error && (
                                                        <Core.Grid item xs={12}>
                                                            <Core.Typography color="error" variant="subtitle2">
                                                                {fieldState.error.message}
                                                            </Core.Typography>
                                                        </Core.Grid>
                                                    )}  
                                                </Core.Grid>
                                                )}
                                                rules={{ required: "Country is required", }}
                                            />

                                        </Core.Grid>
                                    </>
                                )}
                                {subSection === 1 && (
                                    <>
                                        <Core.Grid direction="column">
                                            <label style={{ fontSize: "20px" }}>Choose one of the following options (if known): </label>
                                            <br /><br />

                                            <Core.Grid container>
                                                <Controller name="Price_KG" control={control} render={({ field, fieldState }) => (
                                                    <Core.TextField
                                                        {...field}
                                                        type="number"
                                                        variant="outlined"
                                                        InputProps={{ readOnly: !canEdit }}
                                                        label="Price per kg of Fragrance"
                                                        style={{ width: "70%" }}
                                                    />
                                                )}
                                                />

                                                <Core.Grid style={{ marginLeft: "10px", marginTop: "-1px" }}>
                                                    <Controller name="Price_KG_Currency" control={control} render={({ field }) => (
                                                        <Core.Grid container>
                                                            <Core.Grid key={1}>
                                                                <label htmlFor="Currency_KG_Dollar">
                                                                    <input
                                                                        className="radioButtonInput"
                                                                        type="radio"
                                                                        id="Currency_KG_Dollar"
                                                                        name={field.name}
                                                                        value="$"
                                                                        defaultChecked={field.value === "$"}
                                                                        onChange={field.onChange}
                                                                        ref={field.ref}
                                                                    />
                                                                    <div className="radioButtonSelector radioButtonSelectorLeft">
                                                                        <Core.Typography align="center" variant="h6"> $ </Core.Typography>
                                                                    </div>
                                                                </label>
                                                            </Core.Grid>

                                                            <Core.Grid key={2}>
                                                                <label htmlFor="Currency_KG_Euro">
                                                                    <input
                                                                        className="radioButtonInput"
                                                                        type="radio"
                                                                        id="Currency_KG_Euro"
                                                                        name={field.name}
                                                                        value="€"
                                                                        defaultChecked={field.value === "€"}
                                                                        onChange={field.onChange}
                                                                        ref={field.ref}
                                                                    />
                                                                    <div className="radioButtonSelector radioButtonSelectorRight">
                                                                        <Core.Typography align="center" variant="h6"> € </Core.Typography>
                                                                    </div>
                                                                </label>
                                                            </Core.Grid>
                                                        </Core.Grid>
                                                    )}
                                                    />
                                                </Core.Grid>
                                            </Core.Grid>

                                            <Core.Grid container style={{ marginTop: "50px" }}>
                                                <Controller name="Fragrance_Cost" control={control} render={({ field }) => (
                                                    <Core.TextField
                                                        {...field}
                                                        type="number"
                                                        variant="outlined"
                                                        InputProps={{ readOnly: !canEdit }}
                                                        label="Fragrance cost per ton of finished product"
                                                        style={{ width: "70%" }}
                                                    />
                                                )}
                                                />

                                                <Core.Grid style={{ marginLeft: "10px", marginTop: "-3px" }}>
                                                    <Controller name="Fragrance_Cost_Currency" control={control} render={({ field }) => (
                                                        <Core.Grid container>
                                                            <Core.Grid key={1} item>
                                                                <label htmlFor="Currency_Cost_Dollar">
                                                                    <input
                                                                        className="radioButtonInput"
                                                                        type="radio"
                                                                        id="Currency_Cost_Dollar"
                                                                        name={field.name}
                                                                        value="$"
                                                                        defaultChecked={field.value === "$"}
                                                                        onChange={field.onChange}
                                                                        ref={field.ref}
                                                                    />
                                                                    <div className="radioButtonSelector radioButtonSelectorLeft">
                                                                        <Core.Typography align="center" variant="h6"> $ </Core.Typography>
                                                                    </div>
                                                                </label>
                                                            </Core.Grid>
                                                            <Core.Grid key={2} item>
                                                                <label htmlFor="Currency_Cost_Euro">
                                                                    <input
                                                                        className="radioButtonInput"
                                                                        type="radio"
                                                                        id="Currency_Cost_Euro"
                                                                        name={field.name}
                                                                        value="€"
                                                                        defaultChecked={field.value === "€"}
                                                                        onChange={field.onChange}
                                                                        ref={field.ref}
                                                                    />
                                                                    <div className="radioButtonSelector radioButtonSelectorRight">
                                                                        <Core.Typography align="center" variant="h6"> € </Core.Typography>
                                                                    </div>
                                                                </label>
                                                            </Core.Grid>
                                                        </Core.Grid>
                                                    )}
                                                    />
                                                </Core.Grid>
                                            </Core.Grid>

                                            <Core.Grid style={{ marginTop: "50px" }}>
                                                <Controller name="Price_Purchasing_Extra" control={control}
                                                    render={({ field }) => (
                                                        <Core.TextField
                                                            {...field}
                                                            type="string"
                                                            variant="outlined"
                                                            label="Other comments"
                                                            style={{ width: "100%" }}
                                                        />
                                                    )}
                                                />
                                            </Core.Grid>
                                        </Core.Grid>
                                    </>
                                )}
                                {subSection === 2 && (
                                    <>
                                        <Core.Grid>
                                            <Controller name="Sample_Received" control={control} render={(props) => (
                                                <Core.Grid>
                                                    {FormFunctions.sampleReceivedLabels.map(
                                                        (p) =>
                                                        (
                                                            <Core.Grid key={p.value} item style={{ marginTop: "20px" }}>
                                                                <label htmlFor={`${props.field.name}-${p.value}`}>
                                                                    <input
                                                                        className="radioButtonInput"
                                                                        type="radio"
                                                                        id={`${props.field.name}-${p.value}`}
                                                                        name={props.field.name}
                                                                        value={p.value}
                                                                        defaultChecked={p.value === props.field.value}
                                                                        onChange={(e) => {
                                                                            props.field.onChange(e);
                                                                            if (e.target.value !== "Final product / Benchmark matching") {
                                                                                setValue("Product_Reference", "");
                                                                                setValue("Product_Reference_Image", null);
                                                                            }
                                                                        }}
                                                                        ref={props.field.ref}
                                                                    />
                                                                    <div className="radioButtonImage">
                                                                        <Core.Grid container>
                                                                            <Core.Grid item xs>
                                                                                <Core.Typography variant="h6">
                                                                                    {p.label}
                                                                                </Core.Typography>
                                                                            </Core.Grid>
                                                                            <Core.Grid item>
                                                                                {p.logo}
                                                                            </Core.Grid>
                                                                        </Core.Grid>
                                                                    </div>
                                                                </label>

                                                                {p.value === "Final product / Benchmark matching" &&
                                                                    (
                                                                        <Core.Grid item>
                                                                            <Core.Grid container>
                                                                                <Core.Grid item xs={9}>
                                                                                    <Controller name="Product_Reference" control={control} defaultValue=""
                                                                                        render={(productProps) => (
                                                                                            <>
                                                                                                <Core.TextField
                                                                                                    {...productProps.field}
                                                                                                    fullWidth
                                                                                                    variant="outlined"
                                                                                                    InputProps={{ readOnly: !canEdit, }}
                                                                                                    disabled={props.field.value !== "Final product / Benchmark matching"}
                                                                                                    label="Product reference"
                                                                                                    error={!!productProps.fieldState.error}
                                                                                                    helperText={productProps.fieldState.error?.message}
                                                                                                    style={{ marginTop: "30px", }}
                                                                                                />
                                                                                            </>
                                                                                        )}
                                                                                        rules={{required: watchSampleReceived === "Final product / Benchmark matching" ? "Product reference is required" : "",}}
                                                                                    />
                                                                                </Core.Grid>
                                                                                <Core.Grid item xs={3} style={{ marginTop: "25px", textAlign: "center", }}>
                                                                                    <label htmlFor="image_input">
                                                                                        <input hidden {...register("Product_Reference_Image")} />
                                                                                        <input
                                                                                            accept=".jpeg, .jpg, .png, .gif, .bmp, .tiff"
                                                                                            hidden
                                                                                            id="image_input"
                                                                                            multiple
                                                                                            type="file"
                                                                                            disabled={props.field.value !== "Final product / Benchmark matching"}
                                                                                            onClick={(e) => {
                                                                                                if (watchProductReferenceImage && watchProductReferenceImage !== "") 
                                                                                                {
                                                                                                    e.preventDefault();
                                                                                                    setValue("Product_Reference_Image", null);
                                                                                                }
                                                                                            }}
                                                                                            onChange={async (e) => {
                                                                                                if (e.target.files && e.target.files.length > 0) 
                                                                                                {
                                                                                                    const img = await getBase64(e.target.files[0]);
                                                                                                    setValue("Product_Reference_Image", img);
                                                                                                }
                                                                                                else 
                                                                                                {
                                                                                                    setValue("Product_Reference_Image", null);
                                                                                                }
                                                                                            }}
                                                                                        />
                                                                                        <Core.Typography variant="h6" style={{textDecorationLine: "underline", color: props.field.value !== "Final product / Benchmark matching" ? theme.palette.text.disabled : theme.palette.text.primary,}}>
                                                                                            {watchProductReferenceImage && watchProductReferenceImage !== "" ? "Remove Picture" : "Add Picture"}
                                                                                        </Core.Typography>
                                                                                    </label>
                                                                                </Core.Grid>
                                                                            </Core.Grid>
                                                                        </Core.Grid>
                                                                    )}
                                                            </Core.Grid>
                                                        )
                                                    )}
                                                    {!!props.fieldState.error && (
                                                        <Core.Grid item>
                                                            <Core.Typography color="error" variant="subtitle2">
                                                                {
                                                                    props.fieldState.error.message
                                                                }
                                                            </Core.Typography>
                                                        </Core.Grid>
                                                    )}
                                                </Core.Grid>
                                            )}
                                                rules={{ required: "Sample information is required", }}
                                            />
                                        </Core.Grid>
                                    </>
                                )}
                                {subSection === 3 && (
                                    <>
                                        <Core.Grid item className={classes.GridChild}>
                                            <Controller name="Olfactory_Profile" control={control} render={(props) => (
                                                <Core.Grid container>
                                                    <Core.Box>
                                                        <Core.Collapse in={!haveSelectedOlfactory}>
                                                            <Core.Typography color="error" variant="h6" align="center">
                                                                The olfactory profile information is required
                                                            </Core.Typography>
                                                        </Core.Collapse>
                                                    </Core.Box>

                                                    {FormFunctions.olfactoryProfileLabels.map(
                                                        (p) => (
                                                            <Core.Grid key={p.label} item xs={p.xs ? p.xs : 4}>
                                                                <label htmlFor={`${p.label}`}>
                                                                    <input
                                                                        {...props}
                                                                        className="radioButtonInput"
                                                                        type="checkbox"
                                                                        id={`${p.label}`}
                                                                        name={props.field.name}
                                                                        value={p.label}
                                                                        checked={checkedOlfactory[p.index]}
                                                                        ref={props.field.ref}
                                                                        onChange={(e) => { AddRemoveOlfactoryValue(e.target.value, e.target.checked, p.index); }}
                                                                    />
                                                                    <div className="radioButtonImageBase">
                                                                        {p.value}
                                                                        <Core.Typography variant="subtitle2">
                                                                            {p.label}
                                                                        </Core.Typography>
                                                                    </div>
                                                                </label>
                                                            </Core.Grid>
                                                        )
                                                    )}
                                                </Core.Grid>
                                            )}
                                            />
                                        <Controller name="Olfactory_Extra" control={control} defaultValue="" render={({ field, fieldState }) => (
                                            <Core.TextField
                                                {...field}
                                                fullWidth
                                                variant="outlined"
                                                InputProps={{ readOnly: !canEdit }}
                                                label="Other comments"
                                                error={!!fieldState.error}
                                                helperText={fieldState.error?.message}
                                                style={{ marginTop: "20px" }}
                                            />
                                        )}
                                        />
                                        </Core.Grid>
                                    </>
                                )}
                                {subSection === 4 && (
                                    <>
                                        <Core.Grid style={{ marginTop: "50px" }}>
                                            <Controller name="Target_End_Customer" control={control} render={({ field, fieldState }) => (
                                                <Core.Grid container>
                                                    {FormFunctions.genderLabels.map((p) => (
                                                        <Core.Grid style={{ width: ((p.value !== "N/A") ? "33%" : "100%") }}>
                                                            <label htmlFor={`${field.name}-${p.value}`}>
                                                                <input
                                                                    className="radioButtonInput"
                                                                    type="radio"
                                                                    id={`${field.name}-${p.value}`}
                                                                    name={field.name}
                                                                    value={p.value}
                                                                    defaultChecked={p.value === field.value}
                                                                    onChange={field.onChange}
                                                                    ref={field.ref}
                                                                />
                                                                <div className="radioButtonImageBase">
                                                                    {p.logo}
                                                                    <Core.Typography variant="subtitle2">
                                                                        {p.label}
                                                                    </Core.Typography>
                                                                </div>
                                                            </label>
                                                        </Core.Grid>
                                                    ))}
                                                    {!!fieldState.error && (
                                                        <Core.Grid item xs={12}>
                                                            <Core.Typography color="error" variant="subtitle2">
                                                                {fieldState.error.message}
                                                            </Core.Typography>
                                                        </Core.Grid>
                                                    )}
                                                </Core.Grid>
                                            )}
                                                rules={{ required: "Gender information is required", }}
                                            />
                                        </Core.Grid>
                                        <Controller
                                            name="Target_Age_Range"
                                            control={control}
                                            render={(props) => (
                                                <Core.Grid container direction="column" style={{ marginTop: "50px" }}>
                                                    <Core.FormControlLabel labelPlacement="top"
                                                        label={
                                                            <Core.Typography color={"primary"} variant="h6" style={{ marginBottom: "30px" }}>
                                                                Age Range
                                                            </Core.Typography>
                                                        }
                                                        control={
                                                            <Core.Slider
                                                                {...props}
                                                                min={0}
                                                                max={45}
                                                                valueLabelDisplay="on"
                                                                valueLabelFormat={valuetext}
                                                                onChange={(_, value) => { props.field.onChange(value); }}
                                                                value={props.field.value}
                                                            />
                                                        }
                                                    />
                                                </Core.Grid>
                                            )}
                                        />
                                        <Controller
                                            name="Unknown_Age"
                                            control={control}
                                            render={(props) => (
                                                <label htmlFor="unknownAge" style={{ marginTop: "50px" }}>
                                                    <input
                                                        className="radioButtonInput"
                                                        type="checkBox"
                                                        id="unknownAge"
                                                        name="unknownAge"
                                                        ref={props.field.ref}
                                                        onChange={props.field.onChange}
                                                        value={props.field.value}
                                                    />
                                                    <div className="radioButtonImageBase">
                                                        <Core.Typography variant="subtitle2">
                                                            Unknown
                                                        </Core.Typography>
                                                    </div>
                                                </label>
                                            )}
                                        />


                                    </>
                                )}
                                {subSection === 5 && (
                                    <>
                                        <Core.Box>
                                            <Core.Collapse in={!haveSelectedColour}>
                                                <Core.Typography color="error" variant="h6" align="center">
                                                    Product apperance is required
                                                </Core.Typography>
                                            </Core.Collapse>
                                        </Core.Box>

                                        <Controller name="Colour" control={control} render={(props) => (
                                            <Core.Grid container>
                                                {FormFunctions.colourLabels.map((p) => (
                                                    <Core.Grid xs={4} key={p.label} item>
                                                        <label htmlFor={`${p.label}`} style={{ width: "30%" }}>
                                                            <input
                                                                {...props}
                                                                className="radioButtonInput"
                                                                type="checkbox"
                                                                id={`${p.label}`}
                                                                name={p.label}
                                                                value={p.label}
                                                                ref={props.field.ref}
                                                                checked={checkedColour[p.index]}
                                                                onChange={(e) => { AddRemoveColourValue(e.target.value, e.target.checked, p.index); }}
                                                            />
                                                            <div className="radioButtonColor" style={{ backgroundColor: p.value }}>
                                                            {checkedColour[p.index] && <icons.CheckCircleRounded fontSize="large" style={{color: "blue", paddingLeft: "20px"}}/>}
                                                                <Core.Typography style={{ visibility: ((p.label !== "N/A") ? "hidden" : "visible"), color: "black" }} variant="h6" align="center">
                                                                    {p.label}
                                                                </Core.Typography>
                                                            </div>
                                                        </label>
                                                    </Core.Grid>
                                                ))}
                                            </Core.Grid>
                                        )}
                                        />
                                        <Controller name="Colour_Extra" control={control} defaultValue="" render={({ field, fieldState }) => (
                                            <Core.TextField
                                                {...field}
                                                fullWidth
                                                variant="outlined"
                                                InputProps={{ readOnly: !canEdit }}
                                                label="Other comments"
                                                error={!!fieldState.error}
                                                helperText={fieldState.error?.message}
                                                style={{ marginTop: "20px" }}
                                            />
                                        )}
                                        />
                                    </>
                                )}
                                {subSection === 6 && (
                                    <>
                                        <Core.Grid container direction="column">
                                            <Core.FormControlLabel labelPlacement="top" control={
                                                <Controller name="Finished_Product_Appearance" control={control} render={({ field, fieldState }) => (
                                                    <Core.Grid container direction="column">
                                                        {FormFunctions.productApperanceLabels.map((p) => (
                                                            <Core.Grid key={p.value} style={{ marginTop: "20px" }}>
                                                                <label htmlFor={`${field.name}-${p.value}`}>
                                                                    <input
                                                                        className="radioButtonInput"
                                                                        type="radio"
                                                                        id={`${field.name}-${p.value}`}
                                                                        name={field.name}
                                                                        value={p.value}
                                                                        defaultChecked={p.value === field.value}
                                                                        onChange={field.onChange}
                                                                        ref={field.ref}
                                                                    />
                                                                         <div className="radioButtonImage">
                                                                            <Core.Grid container justify="center" alignContent="center" alignItems="center">
                                                                                <Core.Grid item xs>
                                                                                    <Core.Typography variant="h6">
                                                                                        {p.label}
                                                                                    </Core.Typography>
                                                                                </Core.Grid>
                                                                                <Core.Grid item>
                                                                                    {p.logo}
                                                                                </Core.Grid>
                                                                            </Core.Grid>
                                                                        </div>
                                                                </label>
                                                                {!!fieldState.error && (
                                                                    <Core.Grid item xs={12}>
                                                                        <Core.Typography color="error" variant="subtitle2">
                                                                            {fieldState.error.message}
                                                                        </Core.Typography>
                                                                    </Core.Grid>
                                                                )}  
                                                            </Core.Grid>
                                                        ))}                                                      
                                                    </Core.Grid>
                                                )}
                                                    rules={{ required: "Product apperance is required", }}
                                                />
                                            }
                                                label=
                                                {
                                                    <Core.Typography color={"primary"} variant="h6">
                                                        Appearance
                                                    </Core.Typography>
                                                }
                                            />

                                            <Core.FormControlLabel labelPlacement="top" control={
                                                <Controller name="PH" control={control} render={(props) => (
                                                    <Core.Slider
                                                        {...props}
                                                        min={0} max={14}
                                                        valueLabelDisplay="on"
                                                        onChange={(_, value) => { props.field.onChange(value); }}
                                                        value={props.field.value}
                                                    />)}
                                                />
                                            }
                                                label={
                                                    <Core.Typography color={"primary"} variant="h6" style={{ marginTop: "50px", marginBottom: "50px" }}>
                                                        PH in product
                                                    </Core.Typography>
                                                }
                                            />

                                            <Core.Grid style={{ marginTop: "50px" }}>
                                                <Controller name="Dosage" control={control} render={({ field, fieldState }) => (
                                                    <Core.TextField
                                                        {...field}
                                                        fullWidth
                                                        variant="outlined"
                                                        InputProps={{ readOnly: !canEdit }}
                                                        label="Dosage (%)"
                                                        error={!!fieldState.error}
                                                        helperText={fieldState.error?.message}
                                                    />
                                                )}
                                                />

                                                <Core.Grid style={{ marginTop: "20px" }}>
                                                    <Controller name="Customer_Base" control={control}
                                                        render={({ field }) => (
                                                            <Core.FormControlLabel
                                                                control={
                                                                    <Switch
                                                                        color="primary"
                                                                        name={field.name}
                                                                        inputRef={field.ref}
                                                                        onChange={(_, val) => field.onChange(val)}
                                                                        inputProps={{ readOnly: !canEdit, }}
                                                                        defaultChecked={field.value === 1}
                                                                    />
                                                                }
                                                                label={
                                                                    <Core.Typography color={field.value ? "primary" : "textSecondary"} variant="h6" >
                                                                        Customer base
                                                                    </Core.Typography>
                                                                }
                                                            />
                                                        )}
                                                    />
                                                </Core.Grid>

                                                <Core.Grid style={{ marginTop: "20px" }}>
                                                    <Controller name="Customer_Base_Qty" control={control}
                                                        render={({ field, fieldState }) => (
                                                            <Core.TextField
                                                                {...field}
                                                                fullWidth
                                                                type="number" variant="outlined"
                                                                InputProps={{ readOnly: !canEdit, endAdornment: (<Core.InputAdornment position="end"> g </Core.InputAdornment>), }}
                                                                label="Customer base quantity"
                                                                error={!!fieldState.error}
                                                                helperText={fieldState.error?.message}
                                                            />
                                                        )}
                                                        rules={{required: "Customer base quantity is required",}}
                                                    />
                                                </Core.Grid>

                                            </Core.Grid>
                                        </Core.Grid>
                                    </>
                                )}
                                {subSection === 7 && (
                                    <>
                                        <Core.Grid container direction="column" >
                                            <Controller name="Need_Stability_Test" control={control}
                                                render={({ field }) => (
                                                    <Core.FormControlLabel
                                                        control={
                                                            <Switch
                                                                color="primary"
                                                                name={field.name}
                                                                inputRef={field.ref}
                                                                onChange={(_, val) => field.onChange(val)}
                                                                inputProps={{ readOnly: !canEdit, }}
                                                                defaultChecked={field.value === 1}
                                                            />
                                                        }
                                                        label={
                                                            <Core.Typography color={field.value ? "primary" : "textSecondary"} variant="h6" >
                                                                Needs stability test
                                                            </Core.Typography>
                                                        }
                                                    />
                                                )}
                                            />

                                            <Core.Grid container direction="column" style={{ marginTop: "50px" }}>
                                                <Core.FormControlLabel labelPlacement="top" control={
                                                    <Controller name="Oven_Temperature" control={control}
                                                        render={(props) => (
                                                            <Core.Slider
                                                                {...props}
                                                                min={0}
                                                                max={40}
                                                                step={10}
                                                                marks={FormFunctions.temperatureMarks}
                                                                onChange={(_, value) => { props.field.onChange(value); }}
                                                                value={props.field.value}
                                                            />
                                                        )}
                                                    />
                                                }
                                                    label={
                                                        <Core.Typography color={"primary"} variant="h6">
                                                            Oven Temperature
                                                        </Core.Typography>
                                                    }
                                                />
                                            </Core.Grid>

                                            <div>
                                                <label>* 40ºC (30 days)</label>
                                                <br />
                                                <label>** 50ºC (15 days)</label>
                                            </div>

                                            <Core.Grid container direction="column" style={{ marginTop: "50px" }}>
                                                <Core.FormControlLabel labelPlacement="top" control={
                                                    <Controller name="Test_Duration" control={control} render={(props) => (
                                                        <Core.Slider
                                                            {...props}
                                                            min={0}
                                                            max={70}
                                                            step={10}
                                                            marks={FormFunctions.testDuration}
                                                            valueLabelDisplay="off"
                                                            onChange={(_, value) => { props.field.onChange(value); }}
                                                            value={props.field.value}
                                                        />
                                                    )}
                                                        rules={{ required: "Test duration is required", }}
                                                    />
                                                }
                                                    label={
                                                        <Core.Typography color={"primary"} variant="h6">
                                                            Test Duration (Days)
                                                        </Core.Typography>
                                                    }
                                                />
                                            </Core.Grid>

                                            <Core.Grid container direction="column" style={{ marginTop: "50px" }}>
                                                <Controller name="Stability_Extra" control={control}
                                                    render={({ field, fieldState }) => (
                                                        <Core.TextField
                                                            {...field}
                                                            fullWidth
                                                            type="string"
                                                            variant="outlined"
                                                            label="Other comments"
                                                            error={!!fieldState.error}
                                                            helperText={fieldState.error?.message}
                                                        />
                                                    )}
                                                />
                                            </Core.Grid>
                                        </Core.Grid>
                                    </>
                                )}
                                {subSection === 8 && (
                                    <>
                                        <Core.Grid direction="column">
                                            <Core.FormControlLabel labelPlacement="top" control={
                                                <Core.Grid xs={11} md={6} lg={4} xl={6} style={{ marginLeft: 0, marginRight: "auto" }}>
                                                    <Controller name="Cosmos_Ecocert" control={control} render={({ field }) => (
                                                        <Core.FormControlLabel
                                                            control={
                                                                <Switch
                                                                    color="primary"
                                                                    name={field.name}
                                                                    inputRef={field.ref}
                                                                    onChange={(_, val) => field.onChange(val)}
                                                                    inputProps={{ readOnly: !canEdit, }}
                                                                    defaultChecked={field.value === 1}
                                                                />
                                                            }
                                                            label={
                                                                <Core.Typography color={field.value ? "primary" : "textSecondary"} variant="h6" >
                                                                    Cosmos-Ecocert
                                                                </Core.Typography>
                                                            }
                                                        />
                                                    )}
                                                    />

                                                    <Controller name="Flower_Ecolabel" control={control} render={({ field }) => (
                                                        <Core.FormControlLabel
                                                            control={
                                                                <Switch
                                                                    color="primary"
                                                                    name={field.name}
                                                                    inputRef={field.ref}
                                                                    onChange={(_, val) => field.onChange(val)}
                                                                    inputProps={{ readOnly: !canEdit, }}
                                                                    defaultChecked={field.value === 1}
                                                                />
                                                            }
                                                            label={
                                                                <Core.Typography color={field.value ? "primary" : "textSecondary"} variant="h6" >
                                                                    EU Flower-Ecolabel
                                                                </Core.Typography>
                                                            }
                                                        />
                                                    )}
                                                    />

                                                    <Controller name="California" control={control} render={({ field }) => (
                                                        <Core.FormControlLabel
                                                            control={
                                                                <Switch
                                                                    color="primary"
                                                                    name={field.name}
                                                                    inputRef={field.ref}
                                                                    onChange={(_, val) => field.onChange(val)}
                                                                    inputProps={{ readOnly: !canEdit, }}
                                                                    defaultChecked={field.value === 1}
                                                                />
                                                            }
                                                            label={
                                                                <Core.Typography color={field.value ? "primary" : "textSecondary"} variant="h6" >
                                                                    California
                                                                </Core.Typography>
                                                            }
                                                        />
                                                    )}
                                                    />

                                                    <Controller name="South_Korea" control={control} render={({ field }) => (
                                                        <Core.FormControlLabel
                                                            control={
                                                                <Switch
                                                                    color="primary"
                                                                    name={field.name}
                                                                    inputRef={field.ref}
                                                                    onChange={(_, val) => field.onChange(val)}
                                                                    inputProps={{ readOnly: !canEdit, }}
                                                                    defaultChecked={field.value === 1}
                                                                />
                                                            }
                                                            label={
                                                                <Core.Typography color={field.value ? "primary" : "textSecondary"} variant="h6" >
                                                                    South Korea
                                                                </Core.Typography>
                                                            }
                                                        />
                                                    )}
                                                    />

                                                    <Controller name="Blauer_Engel" control={control} render={({ field }) => (
                                                        <Core.FormControlLabel
                                                            control={
                                                                <Switch
                                                                    color="primary"
                                                                    name={field.name}
                                                                    inputRef={field.ref}
                                                                    onChange={(_, val) => field.onChange(val)}
                                                                    inputProps={{ readOnly: !canEdit, }}
                                                                    defaultChecked={field.value === 1}
                                                                />
                                                            }
                                                            label={
                                                                <Core.Typography color={field.value ? "primary" : "textSecondary"} variant="h6" >
                                                                    Blauer Engel
                                                                </Core.Typography>
                                                            }
                                                        />
                                                    )}
                                                    />

                                                    <Controller name="Nordic_Swan" control={control} render={({ field }) => (
                                                        <Core.FormControlLabel
                                                            control={
                                                                <Switch
                                                                    color="primary"
                                                                    name={field.name}
                                                                    inputRef={field.ref}
                                                                    onChange={(_, val) => field.onChange(val)}
                                                                    inputProps={{ readOnly: !canEdit, }}
                                                                    defaultChecked={field.value === 1}
                                                                />
                                                            }
                                                            label={
                                                                <Core.Typography color={field.value ? "primary" : "textSecondary"} variant="h6" >
                                                                    Nordic Swan
                                                                </Core.Typography>
                                                            }
                                                        />
                                                    )}
                                                    />

                                                    <Controller name="Oko_Test" control={control} render={({ field }) => (
                                                        <Core.FormControlLabel
                                                            control={
                                                                <Switch
                                                                    color="primary"
                                                                    name={field.name}
                                                                    inputRef={field.ref}
                                                                    onChange={(_, val) => field.onChange(val)}
                                                                    inputProps={{ readOnly: !canEdit, }}
                                                                    defaultChecked={field.value === 1}
                                                                />
                                                            }
                                                            label={
                                                                <Core.Typography color={field.value ? "primary" : "textSecondary"} variant="h6" >
                                                                    ÖKO-TEST
                                                                </Core.Typography>
                                                            }
                                                        />
                                                    )}
                                                    />

                                                    <Controller name="Bra_Miljoval" control={control} render={({ field }) => (
                                                        <Core.FormControlLabel
                                                            control={
                                                                <Switch
                                                                    color="primary"
                                                                    name={field.name}
                                                                    inputRef={field.ref}
                                                                    onChange={(_, val) => field.onChange(val)}
                                                                    inputProps={{ readOnly: !canEdit, }}
                                                                    defaultChecked={field.value === 1}
                                                                />
                                                            }
                                                            label={
                                                                <Core.Typography color={field.value ? "primary" : "textSecondary"} variant="h6" >
                                                                    Bra Miljöval
                                                                </Core.Typography>
                                                            }
                                                        />
                                                    )}
                                                    />

                                                    <Controller name="IFRA" control={control} render={({ field }) => (
                                                        <Core.FormControlLabel
                                                            control={
                                                                <Switch
                                                                    color="primary"
                                                                    name={field.name}
                                                                    inputRef={field.ref}
                                                                    onChange={(_, val) => field.onChange(val)}
                                                                    inputProps={{ readOnly: !canEdit, }}
                                                                    defaultChecked={field.value === 1}
                                                                />
                                                            }
                                                            label={
                                                                <Core.Typography color={field.value ? "primary" : "textSecondary"} variant="h6" >
                                                                    IFRA
                                                                </Core.Typography>
                                                            }
                                                        />
                                                    )}
                                                    />

                                                    <Controller name="VOC" control={control} render={({ field }) => (
                                                        <Core.FormControlLabel
                                                            control={
                                                                <Switch
                                                                    color="primary"
                                                                    name={field.name}
                                                                    inputRef={field.ref}
                                                                    onChange={(_, val) => field.onChange(val)}
                                                                    inputProps={{ readOnly: !canEdit, }}
                                                                    defaultChecked={field.value === 1}
                                                                />
                                                            }
                                                            label={
                                                                <Core.Typography color={field.value ? "primary" : "textSecondary"} variant="h6" >
                                                                    VOC
                                                                </Core.Typography>
                                                            }
                                                        />
                                                    )}
                                                    />                                                    
                                                </Core.Grid>
                                            }
                                                label={
                                                    <Core.Typography color={"primary"} variant="h6" >
                                                        Regulations
                                                    </Core.Typography>
                                                }
                                            />

                                            <Controller name="Regulations_Extra" control={control} render={({ field }) => (
                                                <Core.TextField
                                                    {...field}
                                                    fullWidth
                                                    type="string"
                                                    variant="outlined"
                                                    label="Other comments"
                                                    style={{ marginTop: "20px" }}
                                                />
                                            )}
                                            />

                                            <Core.FormControlLabel labelPlacement="top" style={{ width: "90%", marginTop: "20px" }} control={
                                                <Controller name="Allergen_Restrictions" control={control} render={({ field, fieldState }) => (
                                                    <Core.Grid container direction="column">
                                                        {FormFunctions.allergenLabels.map((p) => (
                                                            <Core.Grid key={p.value} style={{ marginTop: "20px" }}>
                                                                <label htmlFor={`${field.name}-${p.value}`} >
                                                                    <input
                                                                        className="radioButtonInput"
                                                                        type="radio"
                                                                        id={`${field.name}-${p.value}`}
                                                                        name={field.name}
                                                                        value={p.value}
                                                                        defaultChecked={p.value === field.value}
                                                                        onChange={field.onChange}
                                                                        ref={field.ref}
                                                                    />
                                                                    <div className="radioButtonImage">
                                                                        <Core.Typography variant="h6">
                                                                            {p.label}
                                                                        </Core.Typography>
                                                                    </div>
                                                                </label>
                                                                {!!fieldState.error && (
                                                                    <Core.Grid item xs={12}>
                                                                        <Core.Typography color="error" variant="subtitle2">
                                                                            {fieldState.error.message}
                                                                        </Core.Typography>
                                                                    </Core.Grid>
                                                                )}  
                                                            </Core.Grid>
                                                        ))}
                                                    </Core.Grid>
                                                )}
                                                    rules={{ required: "Allergen restrictions is required", }}
                                                />
                                            }
                                                label={
                                                    <Core.Typography color={"primary"} variant="h6" >
                                                        Allergens
                                                    </Core.Typography>
                                                }
                                            />
                                        </Core.Grid>
                                    </>
                                )}
                                {subSection === 9 && (
                                    <>
                                        <Core.Grid item className={classes.GridChild}>
                                            <Controller name="Deadline" control={control} defaultValue={new Date()}
                                                render={({ field }) => (
                                                    <Core.TextField
                                                        {...field}
                                                        fullWidth type="date" variant="outlined"
                                                        InputProps={{ readOnly: !canEdit, inputProps: { min: getMinDate(), }, }}
                                                        label="Deadline"
                                                        InputLabelProps={{ shrink: true }}
                                                    />
                                                )}
                                            />
                                        </Core.Grid>

                                        <Core.Grid item className={classes.GridChild}>
                                            <Controller name="Maximum_Samples_Submit" control={control}
                                                render={({ field, fieldState }) => (
                                                    <Core.Grid>
                                                        <Core.TextField
                                                            {...field}
                                                            fullWidth
                                                            type="number" variant="outlined"
                                                            InputProps={{ readOnly: !canEdit, inputProps: { min: 1, }, }}
                                                            label="Maximum samples to submit (per variant)"
                                                            error={!!fieldState.error}
                                                            helperText={fieldState.error?.message}
                                                        />
                                                        {!!fieldState.error && (
                                                            <Core.Grid item xs={12}>
                                                                <Core.Typography color="error" variant="subtitle2">
                                                                    {fieldState.error.message}
                                                                </Core.Typography>
                                                            </Core.Grid>
                                                        )}  
                                                    </Core.Grid>
                                                )}
                                                rules={{
                                                    required: "The samples to submit is required",
                                                    min: {
                                                        message: "The value of samples to submit should be greater than 0",
                                                        value: 1,
                                                    },
                                                }}
                                            />
                                        </Core.Grid>

                                        <Controller name="Fragrance_Qty" control={control} render={() => (
                                            <Core.Grid container>
                                                <Core.FormControlLabel labelPlacement="top" control=
                                                    {
                                                        <Controller name="Fragrance_Qty" control={control}
                                                            render={(props) => (
                                                                <Core.Slider
                                                                    {...props}
                                                                    min={0}
                                                                    max={60}
                                                                    step={10}
                                                                    marks={FormFunctions.FragranceLabels}
                                                                    valueLabelDisplay="off"
                                                                    onChange={(_, value) => { props.field.onChange(value); }}
                                                                    value={props.field.value}
                                                                />
                                                            )}
                                                        />
                                                    }
                                                    label={
                                                        <Core.Typography color={"primary"} variant="h6">
                                                            How much fragrance oil do you want to receive from us?
                                                        </Core.Typography>
                                                    }
                                                />
                                            </Core.Grid>
                                        )}
                                            rules={{
                                                required: "Fragrance quantity is required",
                                            }}
                                        />

                                        <div>
                                            <label>* More than 1kg needs to be approved</label>
                                        </div>

                                        <Core.Grid item className={classes.GridChild} style={{ marginTop: "50px" }}>
                                            <Controller name="Finished_Product" control={control} render={({ field, fieldState }) => (
                                                <Core.FormControlLabel
                                                    control={
                                                        <Switch
                                                            color="primary"
                                                            name={field.name}
                                                            inputRef={field.ref}
                                                            onChange={(_, val) => field.onChange(val)}
                                                            inputProps={{ readOnly: !canEdit, }}
                                                            defaultChecked={field.value === 1}
                                                        />
                                                    }
                                                    label={
                                                        <Core.Typography color={field.value ? "primary" : "textSecondary"} variant="h6">
                                                            Finished product
                                                        </Core.Typography>
                                                    }
                                                />
                                            )}
                                            />
                                        </Core.Grid>
                                    </>
                                )}
                                {subSection === 10 && (
                                    <>
                                        <Core.Grid direction="column" xs={11} md={8} lg={10} xl={10} style={{ marginLeft: 0, marginRight: "auto" }}>
                                                <Controller name="Olfactory_Piramid" control={control} render={({ field }) => (
                                                    <Core.FormControlLabel
                                                        control={
                                                            <Switch
                                                                color="primary"
                                                                name={field.name}
                                                                inputRef={field.ref}
                                                                onChange={(_, val) => field.onChange(val)}
                                                                inputProps={{ readOnly: !canEdit, }}
                                                                defaultChecked={field.value === 1}
                                                            />
                                                        }
                                                        label={
                                                            <Core.Typography color={field.value ? "primary" : "textSecondary"} variant="h6" >
                                                                Olfactory
                                                            </Core.Typography>
                                                        }
                                                    />
                                                )}
                                                />

                                                <Controller name="Custom_Presentation" control={control} render={({ field }) => (
                                                    <Core.FormControlLabel
                                                        control={
                                                            <Switch
                                                                color="primary"
                                                                name={field.name}
                                                                inputRef={field.ref}
                                                                onChange={(_, val) => field.onChange(val)}
                                                                inputProps={{ readOnly: !canEdit, }}
                                                                defaultChecked={field.value === 1}
                                                            />
                                                        }
                                                        label={
                                                            <Core.Typography color={field.value ? "primary" : "textSecondary"} variant="h6" >
                                                                Customized Presentation
                                                            </Core.Typography>
                                                        }
                                                    />
                                                )}
                                                />

                                                <Controller name="Update_Presentation" control={control} render={({ field }) => (
                                                    <Core.FormControlLabel
                                                        control={
                                                            <Switch
                                                                color="primary"
                                                                name={field.name}
                                                                inputRef={field.ref}
                                                                onChange={(_, val) => field.onChange(val)}
                                                                inputProps={{ readOnly: !canEdit, }}
                                                                defaultChecked={field.value === 1}
                                                            />
                                                        }
                                                        label={
                                                            <Core.Typography color={field.value ? "primary" : "textSecondary"} variant="h6" >
                                                                Update of existing presentation
                                                            </Core.Typography>
                                                        }
                                                    />
                                                )}
                                                />

                                                <Controller name="Trend_Report" control={control} render={({ field }) => (
                                                    <Core.FormControlLabel
                                                        control={
                                                            <Switch
                                                                color="primary"
                                                                name={field.name}
                                                                inputRef={field.ref}
                                                                onChange={(_, val) => field.onChange(val)}
                                                                inputProps={{ readOnly: !canEdit, }}
                                                                defaultChecked={field.value === 1}
                                                            />
                                                        }
                                                        label={
                                                            <Core.Typography color={field.value ? "primary" : "textSecondary"} variant="h6" >
                                                                Trend report
                                                            </Core.Typography>
                                                        }
                                                    />
                                                )}
                                                />

                                                <Controller name="Market_Research" control={control} render={({ field }) => (
                                                    <Core.FormControlLabel
                                                        control={
                                                            <Switch
                                                                color="primary"
                                                                name={field.name}
                                                                inputRef={field.ref}
                                                                onChange={(_, val) => field.onChange(val)}
                                                                inputProps={{ readOnly: !canEdit, }}
                                                                defaultChecked={field.value === 1}
                                                            />
                                                        }
                                                        label={
                                                            <Core.Typography color={field.value ? "primary" : "textSecondary"} variant="h6" >
                                                                Market Research
                                                            </Core.Typography>
                                                        }
                                                    />
                                                )}
                                                />                                                 
                                            </Core.Grid>	
                                        </>
                                )}
                                {subSection === 11 && (
                                    <>
                                        <Controller name="Extra_Information" control={control}
                                            render={({ field }) => (
                                                <Core.TextField
                                                    {...field}
                                                    label="Extra information"
                                                    rows={8}
                                                    multiline fullWidth
                                                    variant="outlined"
                                                    InputProps={{ readOnly: !canEdit }}
                                                />
                                            )}
                                        />
                                    </>
                                )}
                            </Core.Grid>
                        </Core.DialogContent>

                        <Core.DialogActions>
                            <Core.Grid container alignContent="space-between" alignItems="center" justify="space-between" direction="row" style={{ padding: "16px" }}>
                                <Core.Grid item>
                                    <Core.Button
                                        size="large" variant="contained"
                                        onMouseDown={handleBackSection}
                                        onTouchStart={handleBackSection}
                                        onTouchEnd={(e) => e.preventDefault()}
                                        disabled={subSection === 0}
                                        startIcon={<icons.ArrowBackIos />}>
                                        Back
                                    </Core.Button>
                                </Core.Grid>
                                <Core.Grid item>
                                    <Core.Button
                                        style={subSections.length - 1 === subSection ? { background: "rgba(117,137,12,1)", } : undefined}
                                        size="large"
                                        variant="contained"
                                        color="primary"
                                        onMouseDown={handleClick}
                                        onTouchStart={handleClick}
                                        onTouchEnd={(e) => e.preventDefault()}
                                        endIcon={<icons.ArrowForwardIos />}
                                    >
                                        {handleClickLabel()}
                                    </Core.Button>
                                </Core.Grid>
                            </Core.Grid>
                        </Core.DialogActions>
                    </>
                )}
            </Core.Dialog>

            <Core.Dialog open={isReportOpen} onClose={closeReport} fullScreen={fullScreen} fullWidth={true} maxWidth="sm" disableBackdropClick disableEscapeKeyDown >
                <Core.DialogTitle>
                    <div style={{ position: "absolute", right: "5px", top: "5px" }}>
                        <Core.IconButton onClick={closeReport}>
                            <icons.Close fontSize="large" />
                        </Core.IconButton>
                    </div>
                    <div>
                        <Core.Typography variant="h4" color="primary">
                            Report preview
                        </Core.Typography>
                    </div>
                </Core.DialogTitle>

                <Core.DialogContent>
                    <div style={{ backgroundColor: "#fff1cc" }}>
                        <Core.Typography variant="h6" color="textPrimary" style={{ marginLeft: "20px" }}>
                            Please notice that once submitted, you will not be able to modify the content. If you need assistance, please contact to Iberchem team.
                        </Core.Typography>
                    </div>

                    <br />
                    
                    {/* -------------------------------- PROJECT DATA ----------------------------------------------- */}
                    <div>
                        <Core.Typography variant="h5" color="primary">Project Data</Core.Typography>
                        <div>
                            <div className={classes.reportSection}>
                                <Core.Typography variant="body1" color="textSecondary"> Name </Core.Typography>
                                
                                <Core.Typography variant="h6" color="textPrimary">
                                    {FormFunctions.FormatValue(getValues("Name"))}
                                </Core.Typography>
                            </div>
                            
                            <div className={classes.reportSection}>
                                <Core.Typography variant="body1" color="textSecondary"> Client </Core.Typography>
                                
                                <Core.Typography variant="h6" color="textPrimary">
                                    {FormFunctions.FormatValue(getValues("Client_Name"))}
                                </Core.Typography>
                            </div>

                            <div className={classes.reportSection}>
                                <Core.Typography variant="body1" color="textSecondary"> Contact Person </Core.Typography>
                                
                                <Core.Typography variant="h6" color="textPrimary">
                                    {FormFunctions.FormatValue(getValues("Contact_Person"))}
                                </Core.Typography>
                            </div>
                            <div className={classes.reportSection}>
                                <Core.Typography variant="body1" color="textSecondary"> Country </Core.Typography>

                                <Core.Typography variant="h6" color="textPrimary">
                                    {FormFunctions.FormatValue(getValues("Country"), "Country")}
                                </Core.Typography>
                            </div>
                            
                            <div className={classes.reportSection}>
                                <Core.Typography variant="body1" color="textSecondary"> Product type </Core.Typography>
                                
                                <Core.Typography variant="h6" color="textPrimary">
                                    {FormFunctions.FormatValue(getValues("ProductType"))}
                                </Core.Typography>
                            </div>
                        </div>
                    </div>

                    {/* -------------------------------- GENERAL INFORMATION ---------------------------------------- */}
                    <div>
                        <Core.Typography variant="h5" color="primary">General Information</Core.Typography>
                        <div>
                            <div className={classes.reportSection}>
                                <Core.Typography variant="body1" color="textSecondary">Price per kg of Fragrance</Core.Typography>
                                <Core.Typography variant="h6" color="textPrimary">
                                    {FormFunctions.FormatValue(getValues("Price_KG"), "Price_KG")}{FormFunctions.FormatValue(getValues("Price_KG_Currency"))}
                                </Core.Typography>
                            </div>
                            
                            <div className={classes.reportSection}>
                                <Core.Typography variant="body1" color="textSecondary">Fragrance cost per ton of finished product</Core.Typography>
                                <Core.Typography variant="h6" color="textPrimary">
                                    {FormFunctions.FormatValue(getValues("Fragrance_Cost"), "Fragrance_Cost")}{FormFunctions.FormatValue(getValues("Fragrance_Cost_Currency"))}
                                </Core.Typography>
                            </div>
                        
                            <div className={classes.reportSection}>
                                <Core.Typography variant="body1" color="textSecondary">Sample received</Core.Typography>
                                <Core.Typography variant="h6" color="textPrimary">
                                    {FormFunctions.FormatValue(getValues("Sample_Received"))}
                                </Core.Typography>
                            </div>
                            
                            <div className={classes.reportSection}>
                                <Core.Typography variant="body1" color="textSecondary">Product reference</Core.Typography>
                                <Core.Typography variant="h6" color="textPrimary">
                                    {FormFunctions.FormatValue(getValues("Product_Reference"))}
                                </Core.Typography>
                            </div>

                            <div className={classes.reportSection}>
                                <Core.Typography variant="body1" color="textSecondary">Other Comments</Core.Typography>
                                <Core.Typography variant="h6" color="textPrimary">
                                    {FormFunctions.FormatValue(getValues("Price_Purchasing_Extra"))}
                                </Core.Typography>
                            </div>
                        </div>
                    </div>

                    {/* -------------------------------- APPLICATION ------------------------------------------------ */}
                    <div>
                        <Core.Typography variant="h5" color="primary">Application</Core.Typography>
                        <div>

                            <div className={classes.reportSection}>
                                <Core.Typography variant="body1" color="textSecondary">Olfactory profiles</Core.Typography>
                                <Core.Typography variant="h6" color="textPrimary">
                                    {FormFunctions.FormatValue(getValues("Olfactory_Profile"))}
                                </Core.Typography>
                            </div>

                            <div className={classes.reportSection}>
                                <Core.Typography variant="body1" color="textSecondary">Target end customer</Core.Typography>
                                <Core.Typography variant="h6" color="textPrimary">
                                    {FormFunctions.FormatValue(getValues("Target_End_Customer"))}
                                </Core.Typography>
                            </div>

                            <div className={classes.reportSection}>
                                <Core.Typography variant="body1" color="textSecondary">
                                    Target age
                                </Core.Typography>
                                <Core.Typography variant="h6" color="textPrimary">
                                    {FormFunctions.FormatValue(getValues("Target_Age_Range"), "Target_Age_Range", getValues("Unknown_Age"))}
                                </Core.Typography>
                            </div>

                            <div className={classes.reportSection}>
                                <Core.Typography variant="body1" color="textSecondary">Other comments
                                </Core.Typography>
                                <Core.Typography variant="h6" color="textPrimary">
                                    {FormFunctions.FormatValue(getValues("Olfactory_Extra"))}
                                </Core.Typography>
                            </div>
                        </div>
                    </div>

                    {/* -------------------------------- SAMPLES REQUIRED ------------------------------------------- */}
                    <div>
                        <Core.Typography variant="h5" color="primary">Samples required</Core.Typography>
                        <div>
                            <div className={classes.reportSection}>
                                <Core.Typography variant="body1" color="textSecondary">Colour</Core.Typography>
                                <Core.Typography variant="h6" color="textPrimary">
                                    {FormFunctions.FormatValue(getValues("Colour"))}
                                </Core.Typography>
                            </div>
                            <div className={classes.reportSection}>
                                <Core.Typography variant="body1" color="textSecondary">Other comments</Core.Typography>
                                <Core.Typography variant="h6" color="textPrimary">
                                    {FormFunctions.FormatValue(getValues("Colour_Extra"))}
                                </Core.Typography>
                            </div>
                        </div>
                    </div>

                    {/* -------------------------------- SELECTION -------------------------------------------------- */}
                    <div>
                        <Core.Typography variant="h5" color="primary">Selection</Core.Typography>
                        <div>
                            <div className={classes.reportSection}>
                                <Core.Typography variant="body1" color="textSecondary">Finished product apperance</Core.Typography>
                                <Core.Typography variant="h6" color="textPrimary">
                                    {FormFunctions.FormatValue(getValues("Finished_Product_Appearance"))}
                                </Core.Typography>
                            </div>

                            <div className={classes.reportSection}>
                                <Core.Typography variant="body1" color="textSecondary">pH end product</Core.Typography>
                                <Core.Typography variant="h6" color="textPrimary">
                                    {FormFunctions.FormatValue(getValues("PH"), "PH")}
                                </Core.Typography>
                            </div>

                            <div className={classes.reportSection}>
                                <Core.Typography variant="body1" color="textSecondary">Dosage (%)</Core.Typography>
                                <Core.Typography variant="h6" color="textPrimary">
                                    {FormFunctions.FormatValue(getValues("Dosage"))}
                                </Core.Typography>
                            </div>

                            <div className={classes.reportSection}>
                                <Core.Typography variant="body1" color="textSecondary">Customer base</Core.Typography>

                                <Core.Typography variant="h6" color="textPrimary">
                                    {FormFunctions.FormatValue(getValues("Customer_Base"),"Customer_Base")}
                                </Core.Typography>
                            </div>

                            <div className={classes.reportSection}>
                                <Core.Typography variant="body1" color="textSecondary">Customer base quantity</Core.Typography>
                                <Core.Typography variant="h6" color="textPrimary">
                                    {FormFunctions.FormatValue(getValues("Customer_Base_Qty"))}
                                </Core.Typography>
                            </div>

                            <div className={classes.reportSection}>
                                <Core.Typography variant="body1" color="textSecondary">Needs Stability Test</Core.Typography>
                                <Core.Typography variant="h6" color="textPrimary">
                                    {FormFunctions.FormatValue(getValues("Need_Stability_Test"), "Need_Stability_Test")}
                                </Core.Typography>
                            </div>

                            <div className={classes.reportSection}>
                                <Core.Typography variant="body1" color="textSecondary">Oven Temperature</Core.Typography>

                                <Core.Typography variant="h6" color="textPrimary">
                                    {FormFunctions.FormatValue(getValues("Oven_Temperature"),"Oven_Temperature")}
                                </Core.Typography>
                            </div>

                            <div className={classes.reportSection}>
                                <Core.Typography variant="body1" color="textSecondary">Test duration</Core.Typography>
                                <Core.Typography variant="h6" color="textPrimary">
                                    {FormFunctions.FormatValue(getValues("Test_Duration"),"Test_Duration")}
                                </Core.Typography>
                            </div>

                            <div className={classes.reportSection}>
                                <Core.Typography variant="body1" color="textSecondary">Other Comments</Core.Typography>
                                <Core.Typography variant="h6" color="textPrimary">
                                    {FormFunctions.FormatValue(getValues("Stability_Extra"))}
                                </Core.Typography>
                            </div>
                        </div>
                    </div>

                    {/* -------------------------------- REGULATORY ------------------------------------------------- */}
                    <div>
                        <Core.Typography variant="h5" color="primary">Regulatory</Core.Typography>
                        <div>
                            <div className={classes.reportSection}>
                                <Core.Typography variant="body1" color="textSecondary">Cosmos Ecocert</Core.Typography>
                                
                                <Core.Typography variant="h6" color="textPrimary">
                                    {FormFunctions.FormatValue(getValues("Cosmos_Ecocert"), "Cosmos_Ecocert")}
                                </Core.Typography>
                            </div>
                            
                            <div className={classes.reportSection}>
                                <Core.Typography variant="body1" color="textSecondary">Flower Ecolabel</Core.Typography>
                                
                                <Core.Typography variant="h6" color="textPrimary">
                                    {FormFunctions.FormatValue(getValues("Flower_Ecolabel"), "Flower_Ecolabel")}
                                </Core.Typography>
                            </div>
                            
                            <div className={classes.reportSection}>
                                <Core.Typography variant="body1" color="textSecondary">California</Core.Typography>

                                <Core.Typography variant="h6" color="textPrimary">
                                    {FormFunctions.FormatValue(getValues("California"), "California")}
                                </Core.Typography>
                            </div>
                            
                            <div className={classes.reportSection}>
                                <Core.Typography variant="body1" color="textSecondary">South Korea</Core.Typography>
                                
                                <Core.Typography variant="h6" color="textPrimary">
                                    {FormFunctions.FormatValue(getValues("South_Korea"), "South_Korea")}
                                </Core.Typography>
                            </div>
                            
                            <div className={classes.reportSection}>
                                <Core.Typography variant="body1" color="textSecondary">Blauer Engel</Core.Typography>

                                <Core.Typography variant="h6" color="textPrimary">
                                    {FormFunctions.FormatValue(getValues("Blauer_Engel"), "Blauer_Engel")}
                                </Core.Typography>
                            </div>
                            
                            <div className={classes.reportSection}>
                                <Core.Typography variant="body1" color="textSecondary">Nordic Swan</Core.Typography>
                                
                                <Core.Typography variant="h6" color="textPrimary">
                                    {FormFunctions.FormatValue(getValues("Nordic_Swan"), "Nordic_Swan")}
                                </Core.Typography>
                            </div>
                            
                            <div className={classes.reportSection}>
                                <Core.Typography variant="body1" color="textSecondary">ÖKO TEST</Core.Typography>
                                
                                <Core.Typography variant="h6" color="textPrimary">
                                    {FormFunctions.FormatValue(getValues("Oko_Test"), "Oko_Test")}
                                </Core.Typography>
                            </div>
                            
                            <div className={classes.reportSection}>
                                <Core.Typography variant="body1" color="textSecondary">Bra Miljöval</Core.Typography>
                                
                                <Core.Typography variant="h6" color="textPrimary">
                                    {FormFunctions.FormatValue(getValues("Bra_Miljoval"), "Bra_Miljoval")}
                                </Core.Typography>
                            </div>
                            
                            <div className={classes.reportSection}>
                                <Core.Typography variant="body1" color="textSecondary">IFRA</Core.Typography>
                                
                                <Core.Typography variant="h6" color="textPrimary">
                                    {FormFunctions.FormatValue(getValues("IFRA"), "IFRA")}
                                </Core.Typography>
                            </div>
                            
                            <div className={classes.reportSection}>
                                <Core.Typography variant="body1" color="textSecondary">VOC</Core.Typography>
                                
                                <Core.Typography variant="h6" color="textPrimary">
                                    {FormFunctions.FormatValue(getValues("VOC"), "VOC")}
                                </Core.Typography>
                            </div>
                            
                            <div className={classes.reportSection}>
                                <Core.Typography variant="body1" color="textSecondary">Allergen restrictions</Core.Typography>

                                <Core.Typography variant="h6" color="textPrimary">
                                    {FormFunctions.FormatValue(getValues("Allergen_Restrictions"))}
                                </Core.Typography>
                            </div>

                            <div className={classes.reportSection}>
                                <Core.Typography variant="body1" color="textSecondary">Other Comments</Core.Typography>

                                <Core.Typography variant="h6" color="textPrimary">
                                    {FormFunctions.FormatValue(getValues("Regulations_Extra"))}
                                </Core.Typography>
                            </div>
                        </div>
                    </div>

                    {/* -------------------------------- PROJECT DEADLINE ------------------------------------------- */}
                    <div>
                        <Core.Typography variant="h5" color="primary">Project Deadline</Core.Typography>
                        <div>
                            <div className={classes.reportSection}>
                                <Core.Typography variant="body1" color="textSecondary">Deadline</Core.Typography>
                                
                                <Core.Typography variant="h6" color="textPrimary">
                                    {FormFunctions.FormatValue(getValues("Deadline"))}
                                </Core.Typography>
                            </div>

                            <div className={classes.reportSection}>
                                <Core.Typography variant="body1" color="textSecondary">Maximum Fragrance samples to submit (per variant)</Core.Typography>
                                
                                <Core.Typography variant="h6" color="textPrimary">
                                    {FormFunctions.FormatValue(getValues("Maximum_Samples_Submit"))}
                                </Core.Typography>
                            </div>

                            <div className={classes.reportSection}>
                                <Core.Typography variant="body1" color="textSecondary">How much fragrance oil do you want to receive from us?</Core.Typography>
                                
                                <Core.Typography variant="h6" color="textPrimary">
                                    {FormFunctions.FormatValue(getValues("Fragrance_Qty"),"Fragrance_Qty")}
                                </Core.Typography>
                            </div>

                            <div className={classes.reportSection}>
                                <Core.Typography variant="body1" color="textSecondary">Finished Product</Core.Typography>
                                
                                <Core.Typography variant="h6" color="textPrimary">
                                    {FormFunctions.FormatValue(getValues("Finished_Product"), "Finished_Product")}
                                </Core.Typography>
                            </div>
                        </div>
                    </div>

                    {/* -------------------------------- MARKETING -------------------------------------------------- */}
                    <div>
                        <Core.Typography variant="h5" color="primary">Marketing</Core.Typography>
                        <div>
                            <div className={classes.reportSection}>
                                <Core.Typography variant="body1" color="textSecondary">Olfactory pyramid</Core.Typography>
                                
                                <Core.Typography variant="h6" color="textPrimary">
                                    {FormFunctions.FormatValue(getValues("Olfactory_Piramid"), "Olfactory_Piramid")}
                                </Core.Typography>
                            </div>
                            <div className={classes.reportSection}>
                                <Core.Typography variant="body1" color="textSecondary">Customized presentation</Core.Typography>
                                
                                <Core.Typography variant="h6" color="textPrimary">
                                    {FormFunctions.FormatValue(getValues("Custom_Presentation"), "Custom_Presentation")}
                                </Core.Typography>
                            </div>
                            <div className={classes.reportSection}>
                                <Core.Typography variant="body1" color="textSecondary">Update of existing presentation</Core.Typography>
                                
                                <Core.Typography variant="h6" color="textPrimary">
                                    {FormFunctions.FormatValue(getValues("Update_Presentation"), "Update_Presentation")}
                                </Core.Typography>
                            </div>
                            <div className={classes.reportSection}>
                                <Core.Typography variant="body1" color="textSecondary">Trend report</Core.Typography>
                                
                                <Core.Typography variant="h6" color="textPrimary">
                                    {FormFunctions.FormatValue(getValues("Trend_Report"), "Trend_Report")}
                                </Core.Typography>
                            </div>
                            <div className={classes.reportSection}>
                                <Core.Typography variant="body1" color="textSecondary">Market Research</Core.Typography>
                                
                                <Core.Typography variant="h6" color="textPrimary">
                                    {FormFunctions.FormatValue(getValues("Market_Research"), "Market_Research")}
                                </Core.Typography>
                            </div>
                        </div>
                    </div>

                    {/* -------------------------------- EXTRA INFO ------------------------------------------------- */}
                    <div>
                        <Core.Typography variant="h5" color="primary">Extra Info</Core.Typography>
                        <div>
                            <div className={classes.reportSection}>
                                <Core.Typography variant="body1" color="textSecondary">
                                    Extra Information
                                </Core.Typography>
                                <Core.Typography variant="h6" color="textPrimary">
                                    {FormFunctions.FormatValue(getValues("Extra_Information"))}
                                </Core.Typography>
                            </div>
                        </div>
                    </div>
                </Core.DialogContent>

                <Core.DialogActions>
                    <Core.Grid container alignContent="space-between" alignItems="center" justify="space-between" direction="row" style={{ padding: "16px" }}>
                        <Core.Grid item>
                            <Core.Button size="large" variant="contained" disabled={isSendingMail} onMouseDown={closeReport} onTouchStart={closeReport} onTouchEnd={(e) => e.preventDefault()}>
                                Edit
                            </Core.Button>
                        </Core.Grid>
                        <Core.Grid item>
                            <Core.Button onClick={handleMailProject} size="large" disabled={isSendingMail} variant="contained" color="primary" type="submit" style={{ background: "rgba(117,137,12,1)", }}>
                                Send report
                            </Core.Button>
                        </Core.Grid>
                    </Core.Grid>
                </Core.DialogActions>
            </Core.Dialog>

            <Core.Backdrop style={{ zIndex: 9999 }} open={isSendingMail}>
                <Core.CircularProgress color="primary" />
            </Core.Backdrop>

            <Core.Dialog
                open={isCompletedOpen}
                onClose={handleCloseCompleted}
                fullScreen={fullScreen}
                fullWidth={true}
                maxWidth="sm"
                disableBackdropClick
                disableEscapeKeyDown
            >
                <Core.DialogTitle>
                    <div style={{ position: "absolute", right: "5px", top: "5px" }}>
                        <Core.IconButton onClick={handleCloseCompleted}>
                            <icons.Close fontSize="large" />
                        </Core.IconButton>
                    </div>
                </Core.DialogTitle>
                <Core.DialogContent>
                    <Core.Grid container alignContent="center" alignItems="stretch" justify="space-evenly" direction="column" style={{ padding: "16px", height: "100%", minHeight: 420 }}>
                        <Core.Grid item>
                            <LogoOk style={{ width: "100%", height: "100%", maxHeight: 250, }} />
                        </Core.Grid>
                        <Core.Grid item>
                            <Core.Typography variant="h3" color="primary" align="center" paragraph>
                                Completed
                            </Core.Typography>
                            <Core.Typography variant="body1" align="center" color="textSecondary">
                                Your project has been sent successfully
                            </Core.Typography>
                        </Core.Grid>
                    </Core.Grid>
                </Core.DialogContent>
                <Core.DialogActions style={{ padding: "30px" }}>
                    <Core.Grid container alignContent="center" alignItems="center" justify="center" direction="row">
                        <Core.Grid item>
                            <Core.Button onClick={handleCloseCompleted} size="large" variant="contained" color="primary" type="submit" >
                                Back to home
                            </Core.Button>
                        </Core.Grid>
                    </Core.Grid>
                </Core.DialogActions>
            </Core.Dialog>
        </>
    );
};

const ProjectFormDialogsM = React.memo(ProjectFormDialogs);

export default ProjectFormDialogsM;