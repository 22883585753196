import React from "react";
import { Link } from "react-router-dom";
import * as Core from "@material-ui/core";
import LoginForm from "./LoginForm";
import Logo from "../../../Assets/Img/Iberchem_app_logo.png";
import Register from "./Register";
import ForgotPassword from "./ForgotPassword";

const useStyles = Core.makeStyles((theme: Core.Theme) =>
    Core.createStyles({
        root: {
            height: "100%",
            backgroundColor: "#FFFFFF",
            minHeight: 600,
        },
        login: {},
        logoContainer: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            // marginBottom: theme.spacing(3),
            [theme.breakpoints.down("xs")]: {
                width: "150px",
            },
            [theme.breakpoints.up("sm")]: {
                width: "175px",
            },
        },
        logo: {
            width: "65%",
            // marginBottom: theme.spacing(1),
        },
        logoDbrief: {
            width: "100%",
        },
        register: {
            // margin: theme.spacing(1),
        },
        footer: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            [theme.breakpoints.down("xs")]: {
                width: "75px",
            },
            [theme.breakpoints.up("sm")]: {
                width: "100px",
            },
        },
        logoByIberchem: {
            marginTop: 10,
            width: 50,
        },
    })
);

export default function Login(): JSX.Element {
    const classes = useStyles();

    const [registerOpen, setRegisterOpen] = React.useState<boolean>(false);
    const [forgotPasswordOpen, setForgotPasswordOpen] = React.useState<boolean>(false);

    const handleOpenRegister = () => {
        setRegisterOpen(() => true);
    };

    const handleCloseRegister = () => {
        setRegisterOpen(() => false);
    };

    const handleOpenForgotPassword = () => {
        setForgotPasswordOpen(() => true);
    };

    const handleCloseForgotPassword = () => {
        setForgotPasswordOpen(() => false);
    };

    const handleRegisterSuccess = () => {};

    const handleForgotPasswordSuccess = () => {};

    return (
        <Core.Grid
            container
            direction="column"
            alignItems="center"
            alignContent="center"
            justify="space-around"
            className={classes.root}
        >
            <Core.Grid item>
                <div className={classes.logoContainer}>
                    <img src={Logo} className={classes.logo} alt="logo" />
                </div>
            </Core.Grid>
            <Core.Grid item>
                <LoginForm />
                <div style={{ textAlign: "center" }}>
                    <Core.Button onClick={handleOpenRegister}>Sign up</Core.Button>
                </div>
            </Core.Grid>
            <Core.Grid item>
                <Core.Typography color="textSecondary" variant="subtitle1">
                    Iberchem {new Date().getFullYear()} - <Link to="/privacy">Privacy policy</Link>
                </Core.Typography>
                <Core.Typography color="textSecondary" variant="subtitle1">
                    Part of Croda International Plc
                </Core.Typography>
            </Core.Grid>
            <Core.Grid item>
                <div style={{ textAlign: "center" }}>
                    <Core.Button onClick={handleOpenForgotPassword}>Forgot Password</Core.Button>
                </div>
            </Core.Grid>
            <Register
                isFormOpen={registerOpen}
                closeForm={handleCloseRegister}
                handleSuccess={handleRegisterSuccess}
            />
            <ForgotPassword
                isFormOpen={forgotPasswordOpen}
                closeForm={handleCloseForgotPassword}
                handleSuccess={handleForgotPasswordSuccess}
            />
        </Core.Grid>
    );
}
