import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { createStyles, makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import {
    TextField,
    Button,
    useMediaQuery,
    Typography,
    IconButton,
    InputAdornment,
    CardContent,
    CardActions,
    Card,
    Grid,
} from "@material-ui/core";
import { Controller, useForm } from "react-hook-form";
import { LockOpen, Lock } from "@material-ui/icons";
import { AlertNotification } from "../Common/Components/Error/AlertNotification";
import Loading from "../Common/Components/Loading";
import { ResetPasswordCommand } from "../../Api/Models/UserModels";
import { resetPasswordService } from "../../Api/Services/ResetPasswordService";
import Logo from "../../../Assets/Img/Iberchem_app_logo.svg";
import LogoDbrief from "../../../Assets/Img/Iberchem_app_dbrief.svg";
import LogoByIberchem from "../../../Assets/Img/Iberchem_app_byiberchem.svg";
import { ApplicationPaths } from "../../Auth/ApiAuthorizationConstants";

interface IFormValues {
    email: string;
    password: string;
    confirmPassword: string;
    token: string;
}

interface IUrlParamas {
    token: string;
    email: string;
}

const InitialValues: IFormValues = {
    email: "",
    password: "",
    confirmPassword: "",
    token: "",
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            height: "100%",
            backgroundColor: "#FFFFFF",
            minHeight: 600,
        },
        logoContainer: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            // marginBottom: theme.spacing(3),
            [theme.breakpoints.down("xs")]: {
                width: "150px",
            },
            [theme.breakpoints.up("sm")]: {
                width: "175px",
            },
        },
        logo: {
            width: "65%",
            // marginBottom: theme.spacing(1),
        },
        logoDbrief: {
            width: "100%",
        },
        logoByIberchem: {
            marginTop: 10,
            width: 50,
        },
    })
);

export default function ResetPassword(): JSX.Element {
    const history = useHistory();
    const classes = useStyles();
    const { token, email } = useParams<IUrlParamas>();
    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.down("xs"));
    const { t } = useTranslation("app");
    const { enqueueSnackbar } = useSnackbar();

    const { handleSubmit, reset, watch, control, register } = useForm<IFormValues>({
        defaultValues: { ...InitialValues, email, token },
    });

    const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);

    const renderPassword = () => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const [showPassword, setShowPassword] = React.useState<boolean>(false);

        const handleShowPassword = () => {
            setShowPassword((prev) => !prev);
        };

        return (
            <>
                <Controller
                    name="password"
                    control={control}
                    render={({ field, fieldState }) => (
                        <TextField
                            {...field}
                            type={showPassword ? "text" : "password"}
                            fullWidth
                            variant="outlined"
                            label={t("resetPassword.password")}
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message}
                            disabled={isSubmitting}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleShowPassword}
                                        >
                                            {showPassword ? <LockOpen /> : <Lock />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    )}
                    rules={{
                        required: "Password is required",
                        minLength: { value: 6, message: "Password must be at least 6 charactes" },
                        maxLength: { value: 12, message: "Password must be at max 12 charactes" },
                        validate: (val) =>
                            val === watch("confirmPassword") || "Passwords don't match.",
                    }}
                />
                <Controller
                    name="confirmPassword"
                    control={control}
                    render={({ field, fieldState }) => (
                        <TextField
                            {...field}
                            fullWidth
                            variant="outlined"
                            label={t("resetPassword.confirmPassword")}
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message}
                            disabled={isSubmitting}
                            type={showPassword ? "text" : "password"}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleShowPassword}
                                        >
                                            {showPassword ? <LockOpen /> : <Lock />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    )}
                    rules={{
                        required: "Confirm password is required",
                        minLength: { value: 6, message: "Password must be at least 6 charactes" },
                        maxLength: { value: 12, message: "Password must be at max 12 charactes" },
                        validate: (val) => val === watch("password") || "Passwords don't match.",
                    }}
                />
                <input hidden {...register("token")} />
            </>
        );
    };

    const handleSubmitResetPassword = (data: IFormValues) => {
        setIsSubmitting(() => true);

        const resetPasswordCommand: ResetPasswordCommand = {
            email: data.email,
            password: data.password,
            password_confirmation: data.confirmPassword,
            token: data.token,
        };

        resetPasswordService
            .resetPassword(resetPasswordCommand)
            .then((response) => {
                AlertNotification(
                    t("resetPassword.notifications.success"),
                    t("resetPassword.notifications.resetPassword"),
                    enqueueSnackbar,
                    {
                        variant: "success",
                    }
                );
                history.push(ApplicationPaths.Login);
            })
            .catch((error) => {
                // eslint-disable-next-line no-debugger
                debugger;
                AlertNotification(
                    error,
                    t("resetPassword.notifications.resetPassword"),
                    enqueueSnackbar
                );
            })
            .finally(() => {
                setIsSubmitting(() => false);
            });
    };

    return (
        <>
            {isSubmitting && (
                <Loading label={t("general.processing")} useCapital useAbsolute useBackground />
            )}

            <Grid
                container
                direction="column"
                alignItems="center"
                alignContent="center"
                justify="space-around"
                className={classes.root}
            >
                <Grid item>
                    <div className={classes.logoContainer}>
                        <img src={Logo} className={classes.logo} alt="logo" />
                        <img src={LogoDbrief} className={classes.logoDbrief} alt="logofont" />
                        <img
                            src={LogoByIberchem}
                            className={classes.logoByIberchem}
                            alt="logoIberchem"
                        />
                    </div>
                </Grid>
                <Grid item>
                    <Typography color="primary" variant="h4" align="center">
                        {t("resetPassword.title")}
                    </Typography>
                </Grid>
                <Grid item>
                    <form onSubmit={handleSubmit(handleSubmitResetPassword)}>
                        <Card elevation={0}>
                            <CardContent>
                                <Controller
                                    name="email"
                                    control={control}
                                    render={({ field, fieldState }) => (
                                        <TextField
                                            {...field}
                                            fullWidth
                                            variant="outlined"
                                            label={t("resetPassword.email")}
                                            error={!!fieldState.error}
                                            helperText={fieldState.error?.message}
                                            disabled={isSubmitting}
                                        />
                                    )}
                                    rules={{
                                        required: "User email is required",
                                        pattern: {
                                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                            message: t("restPassword.notifications.invalidEmail"),
                                        },
                                    }}
                                />
                                {renderPassword()}
                            </CardContent>
                            <CardActions>
                                <div style={{ padding: "8px", flex: 1 }}>
                                    <Button
                                        type="submit"
                                        disabled={isSubmitting}
                                        color="primary"
                                        variant="contained"
                                        size="large"
                                        fullWidth
                                    >
                                        {t("resetPassword.resetPassword")}
                                    </Button>
                                </div>
                            </CardActions>
                        </Card>
                    </form>
                </Grid>
            </Grid>
        </>
    );
}
