import React from "react";
import { Record } from "../GenericInterfaces/GenericInterfaces";

export function useCudDialogs<T extends Record>() {
    const recordToEdit = React.useRef<T>();

    const [isEditFormOpen, setIsEditFormOpen] = React.useState<boolean>(false);
    const [isSummaryFormOpen, setIsSummaryFormOpen] = React.useState<boolean>(false);
    const openForm = React.useCallback(() => setIsEditFormOpen(() => true), []);
    const openSummaryForm = React.useCallback(() => setIsSummaryFormOpen(() => true), []);

    const onNew = React.useCallback(() => {
        recordToEdit.current = undefined;
        openForm();
    }, []);

    //#region EDIT DIALOG
    const onEdit = React.useCallback((record: T) => {
        recordToEdit.current = record;
        openForm();
    }, []);

    const closeEditForm = React.useCallback(() => {
        setIsEditFormOpen(() => false);
    }, []);
    //#endregion 

    //#region SUMMARY DIALOG
    const closeSummaryForm = React.useCallback(() => {
        setIsSummaryFormOpen(() => false);
    }, []);

    const onSummary = React.useCallback((record: T) => {
        recordToEdit.current = record;
        openSummaryForm();
    }, []);
    //#endregion 

    //#region DELETE DIALOG
    const [isDeleteOpen, setIsDeleteOpen] = React.useState<boolean>(false);

    const onDelete = React.useCallback((record: T) => {
        recordToEdit.current = record;
        setIsDeleteOpen(() => true);
    }, []);

    const closeDelete = React.useCallback(() => {
        setIsDeleteOpen(() => false);
    }, []);
    //#endregion DELETE DIALOG

    //#region ARCHIVE DIALOG
    const [isArchiveOpen, setIsArchiveOpen] = React.useState<boolean>(false);

    const onArchive = React.useCallback((record: T) => {
        recordToEdit.current = record;
        setIsArchiveOpen(() => true);
    }, []);

    const closeArchive = React.useCallback(() => {
        setIsArchiveOpen(() => false);
    }, []);
    //#endregion

    //#region UNARCHIVE DIALOG
    const [isUnarchiveOpen, setIsUnarchiveOpen] = React.useState<boolean>(false);

    const onUnarchive = React.useCallback((record: T) => {
        recordToEdit.current = record;
        setIsUnarchiveOpen(() => true);
    }, []);

    const closeUnarchive = React.useCallback(() => {
        setIsUnarchiveOpen(() => false);
    }, []);
    //#endregion

   //#region DUPLICATE DIALOG
    // const [isDuplicateOpen, setIsDuplicateOpen] = React.useState<boolean>(false);

    // const onDuplicate = React.useCallback((record: T) => {
    //     recordToEdit.current = record;
    //     setIsDuplicateOpen(() => true);
    // }, []);

    // const closeDuplicate = React.useCallback(() => {
    //     setIsDuplicateOpen(() => false);
    // }, []);
    //#endregion

    //#region BLOCK DIALOG
    const [isBlockOpen, setIsBlockOpen] = React.useState<boolean>(false);

    const onBlock = React.useCallback((record: T) => {
        recordToEdit.current = record;
        setIsBlockOpen(true);
    }, []);

    const closeBlock = React.useCallback(() => {
        setIsBlockOpen(false);
    }, []);
    //#endregion

    //#region UNARCHIVE DIALOG
    const [isUnBlockOpen, setIsUnBlockOpen] = React.useState<boolean>(false);

    const onUnBlock = React.useCallback((record: T) => {
        recordToEdit.current = record;
        setIsUnBlockOpen(true);
    }, []);

    const closeUnBlock = React.useCallback(() => {
        setIsUnBlockOpen(false);
    }, []);
    //#endregion

    return {
        recordToEdit,
        onNew,
        onEdit,
        closeEditForm,
        onDelete,
        onArchive,
        onSummary,
        closeDelete,
        isEditFormOpen,
        isDeleteOpen,
        isSummaryFormOpen,
        closeSummaryForm,
        isArchiveOpen,
        closeArchive,
        isUnarchiveOpen,
        onUnarchive,
        closeUnarchive,
        isBlockOpen,
        onBlock,
        closeBlock,
        isUnBlockOpen,
        onUnBlock,
        closeUnBlock,
        // isDuplicateOpen,
        // setIsDuplicateOpen,
        // onDuplicate,
        // closeDuplicate
    };
}
