import React from "react";
import { Box, Typography } from "@material-ui/core";

const CategoryHeader = ({
    title,
    subtitle,
    size,
}: {
    title?: string;
    subtitle?: string;
    size?: string;
}): JSX.Element => {
    return (
        <Box>
            {title && <Typography variant="h4">{title}</Typography>}
            {subtitle && <Typography variant="h6">{subtitle}</Typography>}
        </Box>
    );
};

export default CategoryHeader;
