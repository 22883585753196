import React from "react";
import { useTranslation } from "react-i18next";
import * as Core from "@material-ui/core";
import { IProjectRecordsList } from "../../Common/GenericInterfaces/GenericInterfaces";
import { ProjectDTO } from "../../../Api/Models/ProjectModels";
import { EnhancedTable, Header } from "../../Common/Components/EnhancedTable";
import ProjectCard from "./ProjectCard";
import Loading from "../../Common/Components/Loading";

const ProjectsList = ({
    records,
    onEdit,
    onDelete,
    onArchive,
    onUnArchive,
    onSummary,
    onBlock,
    onUnBlock,
    // onDuplicate,
}: IProjectRecordsList<ProjectDTO>): JSX.Element => {
    const theme = Core.useTheme();
    const smDown = Core.useMediaQuery(theme.breakpoints.down("sm"));
    const { t } = useTranslation("app");

    const rowRenderer = React.useCallback(
        (record: ProjectDTO) => {
            if (smDown) {
                return (
                    <Core.TableRow key={record.id}>
                        <Core.TableCell component="th" scope="row">
                            <ProjectCard
                                key={record.id}
                                project={record}
                                onSummary={onSummary}
                                onEdit={onEdit}
                                onDelete={onDelete}
                                onArchive={onArchive}
                                onUnArchive={onUnArchive}
                                onBlock={onBlock}
                                onUnBlock={onUnBlock}                                
                                //onDuplicate={onDuplicate}
                                //onDuplicate={() => {Duplicate(record)}}
                            />
                        </Core.TableCell>
                    </Core.TableRow>
                );
            }
            return (
                <ProjectCard
                    key={record.id}
                    project={record}
                    onSummary={onSummary}
                    onEdit={onEdit}
                    onDelete={onDelete}
                    onArchive={onArchive}
                    onUnArchive={onArchive}
                    onBlock={onBlock}
                    onUnBlock={onUnBlock}  
                    //onDuplicate={onDuplicate}
                    //onDuplicate={() => {Duplicate(record)}}
                />
            );
        },
        [onEdit, onDelete, smDown]
    );

    const header: Header<ProjectDTO> = React.useMemo(() => {
        if (smDown) {
            return [
                {
                    id: "Name",
                    label: "Name",
                    alignRight: false,
                    disablePadding: false,
                },
            ];
        }

        return [
            {
                id: "Name",
                label: "Name",
                alignRight: false,
                disablePadding: false,
            },
            {
                id: "created_at",
                label: "Started",
                alignRight: false,
                disablePadding: false,
            },
            {
                label: "Completed",
                alignRight: false,
                disablePadding: false,
            },
            {
                label: "Blocked",
                alignRight: false,
                disablePadding: false,
            },            
            {
                alignRight: false,
                disablePadding: false,
            },
        ];
    }, [smDown]);

    if (records === undefined) return <Loading />;

    return (
        <EnhancedTable
            initOrderBy="Name"
            header={header}
            rows={records}
            rowRender={rowRenderer}
            isXs={smDown}
        />
    );
};

const ProjectsListM = React.memo(ProjectsList);

export default ProjectsListM;
