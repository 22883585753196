import React from "react";
import { Switch, Route } from "react-router-dom";
import { ApplicationPaths } from "./Auth/ApiAuthorizationConstants";
import AuthRoutes from "./Auth/AuthRoutes";
import AuthRoute from "./Auth/AuthRoute";
import Login from "./Dbrief/Login/Login";
import Privacy from "./Dbrief/Login/Privacy";
import Dbrief from "./Dbrief/Dbrief";
import ResetPassword from "./Dbrief/Login/ResetPassword";

function App(): JSX.Element {
    return (
        <Switch>
            <Route path="/privacy" component={Privacy} />
            <Route path={ApplicationPaths.Login} component={Login} />
            <Route path={ApplicationPaths.ResetPassword} component={ResetPassword} />
            <Route path={ApplicationPaths.ApiAuthorizationPrefix} component={AuthRoutes} />
            <AuthRoute path="/" component={Dbrief} />
            <Route path="*">{/* TODO 404 component */}</Route>
        </Switch>
    );
}

export default App;
