import { generatePath } from "react-router-dom";
import { UserRolesType } from "../Api/ApiConstants";

const routePrefix = "/app";
const administrationPath = `${routePrefix}/administration`;

const projectPath = `${routePrefix}/project`;
const projectIdPath = `${routePrefix}/project/:projectId`;

export const AppRoutes = {
    Prefix: routePrefix,
    Home: routePrefix,
    Dashboard: `${routePrefix}`,
    Administration: {
        Profile: `${administrationPath}/profile`,
        Projects: `${administrationPath}/projects`,
        Users: `${administrationPath}/users`,
    },
    Projects: `${routePrefix}/projects`,
    Project: {
        Project: {
            Path: projectIdPath,
            Builder: (projectId?: string): string => {
                if (projectId) {
                    return generatePath(projectIdPath, { projectId });
                }
                return projectPath;
            },
        },
        Dashboard: {
            Path: `${projectPath}/dashboard`,
            Builder: (projectId: string): string =>
                generatePath(`${projectIdPath}/dashboard`, { projectId }),
        },
    },
};

export const UserRoleValues = Object.values(UserRolesType);

export interface IPlatformType {
    id: number;
    key: string;
}

type IUserTypes = {
    [key in UserRolesType]: IPlatformType;
};

export const UserTypes: IUserTypes = {
    [UserRolesType.Administrator]: {
        id: UserRolesType.Administrator,
        key: "administrator",
    },
    [UserRolesType.Manager]: {
        id: UserRolesType.Manager,
        key: "manager",
    },
    [UserRolesType.User]: {
        id: UserRolesType.User,
        key: "user",
    },
    [UserRolesType.Communications]: {
        id: UserRolesType.Communications,
        key: "communications",
    },
};
