import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import * as Core from "@material-ui/core";
import { Lock, LockOpen } from "@material-ui/icons";
import { useForm } from "react-hook-form";
import { useSnackbar } from "notistack";
import { authService } from "../../Api/Services/AuthService";
import { ApplicationPaths } from "../../Auth/ApiAuthorizationConstants";
import { IUserMailData, LoginUserCommand } from "../../Api/Models/UserModels";
import { AlertNotification } from "../Common/Components/Error/AlertNotification";

interface IFormValues {
    email: string;
    password: string;
}

const INITIAL_VALUES: IFormValues = {
    email: "",
    password: "",
};

const LoginForm = (): JSX.Element => {
    const { t } = useTranslation("app");
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();
    const [showPassword, setShowPassword] = useState<boolean>(false);

    const { handleSubmit, register, formState: { errors },} = useForm<IFormValues>({defaultValues: INITIAL_VALUES,});

    const { ref: emailRef, ...emailInputProps } = register("email", {
        required: "Required",
        pattern: {
            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
            message: "invalid email address",
        },
    });

    const { ref: passwordRef, ...passwordInputProps } = register("password", {
        required: "Required",
    });

    const handleShowPassword = () => {
        setShowPassword((prev) => !prev);
    };

    function handleSendVerificationMail(email: string) {
        const userEmail: IUserMailData = {
            email,
        };

        // //console.log(userEmail);
        authService
            .sendVerificationMail(userEmail)
            .then((response) => {
                AlertNotification(
                    t("login.notifications.verificationSent"),
                    t("login.notifications.sendVerification"),
                    enqueueSnackbar,
                    {
                        variant: "success",
                    }
                );
            })
            .catch((error) => {
                AlertNotification(
                    error,
                    t("login.notifications.sendVerification"),
                    enqueueSnackbar,
                    {
                        variant: "warning",
                    }
                );
            });
    }

    function handleLoginUser(data: IFormValues) {
        history.push(ApplicationPaths.LoginCallback);

        const loginUserCommand: LoginUserCommand = {
            email: data.email,
            password: data.password,
        };
        authService.login(loginUserCommand, () => {history.push(ApplicationPaths.DefaultLoginRedirectPath);})
            .then((response) => {history.push(ApplicationPaths.DefaultLoginRedirectPath);})
            .catch((error) => {
                AlertNotification(error, "Login", enqueueSnackbar, {variant: "warning",});

                if (error.response) 
                {
                    const errorsAuth = error.response.data.errors.Auth;
                    if (Array.isArray(errorsAuth) && errorsAuth.includes("Mail not validated"))
                    {
                        AlertNotification(" ", " ", // t("login.notifications.sendVerification"),
                            enqueueSnackbar,
                            {
                                action: (
                                    <Core.Button onClick={() => {handleSendVerificationMail(data.email);}} color="primary">
                                        {t("login.notifications.resendMail")}
                                    </Core.Button>
                                ),
                                variant: "warning",
                                autoHideDuration: 7500,
                                resumeHideDuration: 4500,
                            }
                        );   
                    }
                }
                history.push(ApplicationPaths.DefaultLoginRedirectPath);
            });
    }

    return (
        <form onSubmit={handleSubmit(handleLoginUser)}>
            <Card elevation={0}>
                <Core.CardContent>
                    <Core.TextField
                        inputRef={emailRef}
                        {...emailInputProps}
                        id="email"
                        label={t("login.email")}
                        helperText={errors?.email?.message}
                        error={!!errors.email}
                        fullWidth
                        variant="outlined"
                    />
                    <Core.TextField
                        inputRef={passwordRef}
                        {...passwordInputProps}
                        type={showPassword ? "text" : "password"}
                        id="password"
                        label={t("login.password")}
                        helperText={errors?.password?.message}
                        error={!!errors.password}
                        fullWidth
                        variant="outlined"
                        style={{marginTop: "20px"}}
                        InputProps={{
                            endAdornment: (
                                <Core.InputAdornment position="end">
                                    <Core.IconButton aria-label="toggle password visibility" onClick={handleShowPassword}>
                                        {showPassword ? <LockOpen /> : <Lock />}
                                    </Core.IconButton>
                                </Core.InputAdornment>
                            ),
                        }}
                    />
                </Core.CardContent>
                <Core.CardActions>
                    <div style={{ padding: "8px", flex: 1 }}>
                        <Core.Button size="large" fullWidth variant="contained" type="submit" color="primary">
                            {t("login.login")}
                        </Core.Button>
                    </div>
                </Core.CardActions>
            </Card>
        </form>
    );
};

const LoginFormM = React.memo(LoginForm);

export default LoginFormM;
