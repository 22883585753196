import axios from "../AxiosConfig";
import * as Constants from "../ApiConstants";
import * as Models from "../Models/ProjectModels";

export class ProjectService {
    static async getProjects(archived: number) {
        return axios
            .get(`${Constants.APIHost}${Constants.APIProject}/getArchived/${archived}`)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                ////console.log(error);
                if (error.response) 
                {
                    console.log(error.response);
                }
                throw error;
            });
    }

    static async getProject(id: string) {        
        return axios
        .get<Models.ProjectFullDTO>(`${Constants.APIHost}${Constants.APIProject}/get/${id}`, {
                headers: {
                    "Content-Type": "application/json",
                },
            })
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                ////console.log(error);
                throw error;
            });
    }

    static async getLastProject() {
        return axios
            .get<Models.ProjectDTO>(`${Constants.APIHost}${Constants.APIProject}/last`)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                ////console.log(error);
                if (error.response) 
                {
                    ////console.log(error.response);
                }
                throw error;
            });
    }

    static async createProject(data: Models.CreateProjectCommand) {
        return axios
            .post<string>(`${Constants.APIHost}${Constants.APIProject}/create`, data)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                //console.log(error);
                if (error.response) 
                {
                    //console.log(error.response);
                }
                throw error;
            });
    }

    static async updateProject(data: Models.EditProjectCommand) {
        return axios
            .put<Models.ProjectFullDTO>(`${Constants.APIHost}${Constants.APIProject}/update`, data)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                ////console.log(error);
                if (error.response) 
                {
                    //console.log(error.response);
                }
                throw error;
            });
    }

    static async deleteProject(data: Models.DeleteProjectCommand) {
        return axios
            .delete<void>(`${Constants.APIHost}${Constants.APIProject}/delete`, {
                params: data,
            })
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                ////console.log(error);
                if (error.response) 
                {
                    ////console.log(error.response);
                }
                throw error;
            });
    }

    static async mailProject(data: Models.IProjectIdData) {
        return axios
            .post<void>(`${Constants.APIHost}${Constants.APIProject}/mail`, data)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                ////console.log(error);
                if (error.response) 
                {
                    console.log(error.response);
                }
                throw error;
            });
    }

    static async archiveProject(data: Models.ArchiveProjectCommand) {
        return axios
            .put<void>(`${Constants.APIHost}${Constants.APIProject}/setArchived`, data)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                ////console.log(error);
                if (error.response) 
                {
                    ////console.log(error.response);
                }
                throw error;
            });
    }

    static async unarchiveProject(data: Models.ArchiveProjectCommand) {
        return axios
            .put<void>(`${Constants.APIHost}${Constants.APIProject}/setArchived`, data)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                ////console.log(error);
                if (error.response) 
                {
                    ////console.log(error.response);
                }
                throw error;
            });
    }
 
    static async duplicateProject(data: Models.ProjectFullDTO) {
        return axios
        .post<Models.ProjectFullDTO>(`${Constants.APIHost}${Constants.APIProject}/duplicate`, data)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            ////console.log(error);
            if (error.response) 
            {
                //console.log(error.response);
            }
            throw error;
        });
    }

    static async blockProject(data: Models.BlockProjectCommand) {
        return axios
            .put<void>(`${Constants.APIHost}${Constants.APIProject}/setBlocked`, data)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                ////console.log(error);
                if (error.response) 
                {
                    ////console.log(error.response);
                }
                throw error;
            });
    }

    static async unblockProject(data: Models.UnblockProjectCommand) {
        return axios
            .put<void>(`${Constants.APIHost}${Constants.APIProject}/setBlocked`, data)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                ////console.log(error);
                if (error.response) 
                {
                    ////console.log(error.response);
                }
                throw error;
            });
    }

}
