import React from "react";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { useTheme } from "@material-ui/core/styles";
import * as Core from "@material-ui/core";
import { Controller, useForm } from "react-hook-form";
import { Lock, LockOpen } from "@material-ui/icons";
import { AlertNotification } from "../Common/Components/Error/AlertNotification";
import Loading from "../Common/Components/Loading";
import { RegisterUserCommand } from "../../Api/Models/UserModels";
import { authService } from "../../Api/Services/AuthService";

interface IFormValues {
    name: string;
    email: string;
    password: string;
    password_confirm: string;
}

const InitialValues: IFormValues = {
    name: "",
    email: "",
    password: "",
    password_confirm: "",
};

interface IRegisterFormProps {
    isFormOpen: boolean;
    closeForm: () => void;
    handleSuccess: () => void;
}

const Register = ({ isFormOpen, closeForm, handleSuccess }: IRegisterFormProps): JSX.Element => {
    const theme = useTheme();
    const isXs = Core.useMediaQuery(theme.breakpoints.down("xs"));
    const { t } = useTranslation("app");
    const { enqueueSnackbar } = useSnackbar();
    const [showPassword, setShowPassword] = React.useState<boolean>(false);

    const { handleSubmit, reset, watch, control } = useForm<IFormValues>({
        defaultValues: InitialValues,
    });

    const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);

    const handleCloseForm = () => {
        reset(InitialValues);
        closeForm();
    };

    const handleRegisterSuccess = () => {
        AlertNotification(
            t("login.notifications.userRegistered"),
            t("login.notifications.registerUser"),
            enqueueSnackbar,
            {
                variant: "success",
            }
        );
        handleSuccess();
        closeForm();
    };

    const handleRegisterUser = (data: IFormValues) => {
        setIsSubmitting(() => true);

        const registerUserCommand: RegisterUserCommand = {
            name: data.name,
            email: data.email,
            password: data.password,
        };

        authService
            .register(registerUserCommand)
            .then((response) => {
                handleRegisterSuccess();
            })
            .catch((error) => {
                AlertNotification(error, t("login.notifications.registerUser"), enqueueSnackbar);
            })
            .finally(() => {
                setIsSubmitting(() => false);
            });
    };

    const renderPassword = () => {
        const handleShowPassword = () => {setShowPassword((prev) => !prev);};

        return (
            <>
                <Controller name="password" control={control} defaultValue=""
                    render={({ field, fieldState }) => (
                        <Core.TextField
                            {...field}
                            type={showPassword ? "text" : "password"}
                            fullWidth
                            style={{marginTop: "20px"}}
                            variant="outlined"
                            label="Password"
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message}
                            disabled={isSubmitting}
                            InputProps={{
                                endAdornment: (
                                    <Core.InputAdornment position="end">
                                        <Core.IconButton aria-label="toggle password visibility" onClick={handleShowPassword}>
                                            {showPassword ? <LockOpen /> : <Lock />}
                                        </Core.IconButton>
                                    </Core.InputAdornment>
                                ),
                            }}
                        />
                    )}
                    rules={{
                        required: "User password is required",
                        minLength: { value: 6, message: "Password must be at least 6 charactes" },
                        maxLength: { value: 12, message: "Password must be at max 12 charactes" },
                        validate: (val) => val === watch("password_confirm") || "Passwords don't match.",
                        }}
                />
                <Controller name="password_confirm" control={control} defaultValue=""
                    render={({ field, fieldState }) => (
                        <Core.TextField
                            {...field}
                            fullWidth
                            variant="outlined"
                            label="Password confirmation"
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message}
                            disabled={isSubmitting}
                            style={{marginTop: "20px"}}
                            type={showPassword ? "text" : "password"}
                            InputProps={{
                                endAdornment: (
                                    <Core.InputAdornment position="end">
                                        <Core.IconButton aria-label="toggle password visibility" onClick={handleShowPassword}>
                                            {showPassword ? <LockOpen /> : <Lock />}
                                        </Core.IconButton>
                                    </Core.InputAdornment>
                                ),
                            }}
                        />
                    )}
                    rules={{
                        required: "User password is required",
                        minLength: { value: 6, message: "Password must be at least 6 charactes" },
                        maxLength: { value: 12, message: "Password must be at max 12 charactes" },
                        validate: (val) => val === watch("password") || "Passwords don't match.",
                    }}
                />
            </>
        );
    };

    return (
        <Core.Dialog open={isFormOpen} onClose={handleCloseForm} fullScreen={isXs} fullWidth={true} maxWidth="xs" disableBackdropClick disableEscapeKeyDown>
            {isSubmitting && (
                <Loading label={t("general.processing")} useCapital useAbsolute useBackground />
            )}

            <form onSubmit={handleSubmit(handleRegisterUser)}>
                <Core.DialogContent>
                    <Core.DialogContentText>
                        <Core.Typography color="primary" variant="h4">
                            Sign up
                        </Core.Typography>

                        <Core.Typography variant="body1">
                            Once you complete this process this window will close and you'll receive in your inbox a validation email, 
                            in case that you didn't receive it or couldn't find it please check your spam folder.
                        </Core.Typography>
                    </Core.DialogContentText>
                    <Controller name="name" control={control} defaultValue="" render={({ field, fieldState }) => (
                            <Core.TextField
                                {...field}
                                fullWidth
                                variant="outlined"
                                label="Name"
                                error={!!fieldState.error}
                                helperText={fieldState.error?.message}
                                disabled={isSubmitting}
                            />
                        )}
                        rules={{ required: "User name is required" }}
                    />
                    <Controller name="email" control={control} defaultValue="" render={({ field, fieldState }) => (
                            <Core.TextField
                                {...field}
                                fullWidth
                                variant="outlined"
                                label="Email"
                                error={!!fieldState.error}
                                helperText={fieldState.error?.message}
                                disabled={isSubmitting}
                                style={{marginTop: "20px"}}
                            />
                        )}
                        rules={{
                            required: "User email is required",
                            pattern: {
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                message: "Invalid email address",
                            },
                        }}
                    />
                    {renderPassword()}
                </Core.DialogContent>
                <Core.DialogActions style={{ marginTop: 20 }}>
                    <Core.Button disabled={isSubmitting} size="large" onClick={closeForm} variant="contained">
                        {t("general.cancel")}
                    </Core.Button>
                    <Core.Button type="submit" disabled={isSubmitting} color="primary" variant="contained" size="large">
                        Sign Up
                    </Core.Button>
                </Core.DialogActions>
            </form>
        </Core.Dialog>
    );
};

export default Register;
