import React from "react";
import { useTranslation } from "react-i18next";
import * as Core from "@material-ui/core";
import { IUserRecordsList } from "../../Common/GenericInterfaces/GenericInterfaces";
import { UserDTO } from "../../../Api/Models/UserModels";
import { EnhancedTable, Header } from "../../Common/Components/EnhancedTable";
import UserCard from "./UserCard";
import Loading from "../../Common/Components/Loading";

const UsersList = ({ records, onEdit, onDelete }: IUserRecordsList<UserDTO>): JSX.Element => {
    const theme = Core.useTheme();
    const smDown = Core.useMediaQuery(theme.breakpoints.down("sm"));
    const { t } = useTranslation("app");

    const rowRenderer = React.useCallback(
        (record: UserDTO) => {
            if (smDown) {
                return (
                    <Core.TableRow key={record.id}>
                        <Core.TableCell component="th" scope="row">
                            <UserCard
                                key={record.id}
                                user={record}
                                onEdit={onEdit}
                                onDelete={onDelete}
                            />
                        </Core.TableCell>
                    </Core.TableRow>
                );
            }
            return <UserCard key={record.id} user={record} onEdit={onEdit} onDelete={onDelete} />;
        },
        [onEdit, onDelete, smDown]
    );

    const header: Header<UserDTO> = React.useMemo(() => {
        if (smDown) {
            return [
                {
                    id: "name",
                    label: t("users.user.name"),
                    alignRight: false,
                    disablePadding: false,
                },
            ];
        }

        return [
            {
                id: "name",
                label: t("users.user.name"),
                alignRight: false,
                disablePadding: false,
            },
            {
                id: "email",
                label: t("users.user.email"),
                alignRight: false,
                disablePadding: false,
            },
            {
                label: t("users.user.createdAt"),
                alignRight: false,
                disablePadding: false,
            },
            {
                label: t("users.user.userType"),
                alignRight: false,
                disablePadding: false,
            },
            {
                alignRight: false,
                disablePadding: false,
            },
        ];
    }, [smDown]);

    if (records === undefined) return <Loading />;

    return (
        <EnhancedTable
            initOrderBy="name"
            header={header}
            rows={records}
            rowRender={rowRenderer}
            isXs={smDown}
        />
    );
};

const UsersListM = React.memo(UsersList);

export default UsersListM;
