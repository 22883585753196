import React from "react";
import * as Core from "@material-ui/core";
import * as Styles from "@material-ui/core/styles";
import * as Icons from "@mui/icons-material";
import { ProjectDTO, ProjectFullDTO } from "../../../Api/Models/ProjectModels";
import { ProjectService } from "../../../Api/Services/ProjectService";
import { AlertNotification } from "../../Common/Components/Error/AlertNotification";
import { useSnackbar } from "notistack"
import { authService } from "../../../Api/Services/AuthService";

const useStyles = Styles.makeStyles((theme) => ({
    root: {},
    container: {
        alignItems: "center",
        [theme.breakpoints.down("sm")]: {},
    },
    collapse: {
        marginTop: theme.spacing(1),
    },
    expand: {
        transform: "rotate(0deg)",
        marginLeft: "auto",
        padding: "0",
        transition: theme.transitions.create("transform", {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: "rotate(180deg)",
    },
}));

interface ProjectCardProps {
    project: ProjectDTO;
    onEdit, onDelete, onArchive, onUnArchive, onSummary, onBlock, onUnBlock: (project: ProjectDTO) => void;
    //onDuplicate: (project: ProjectDTO) => void;
    // onDuplicate: () => void;
}

const ProjectCard = ({
    project,
    onEdit,
    onDelete,
    onArchive,
    onUnArchive,
    onSummary,
    onBlock,
    onUnBlock
    //onDuplicate
}: ProjectCardProps): JSX.Element => {
    const classes = useStyles();
    const theme = Styles.useTheme();
    const smDown = Core.useMediaQuery(theme.breakpoints.down("sm"));
    const userRole = authService.getUserRole();
    const { enqueueSnackbar } = useSnackbar();
    const [duplicatedRecord, setDuplicateRecord] = React.useState<ProjectFullDTO>();

    const Duplicate = (record: ProjectDTO) => 
    {
        if (record)
        {   
            ProjectService.getProject(record.id).then((res) => { setDuplicateRecord(res) });
        }
    }

    React.useEffect(() => 
    {
        if (duplicatedRecord) 
        {
            const readData = (duplicatedRecord) => {
                const data: ProjectFullDTO = {
                    id: duplicatedRecord!.id,
                    LastStep: 0,
            
                    created_at: new Date,
                    updated_at: new Date,
                    completed_at: new Date,
                    Owner: "",
                    owned: false,
            
                    Archived: duplicatedRecord!.Archived,
                    Blocked: 0,            

                    //Subsection 0
                    Name: duplicatedRecord!.Name + " - CLONED",
                    Client_Name: duplicatedRecord!.Client_Name,
                    Country: duplicatedRecord!.Country,
                    Contact_Person: duplicatedRecord!.Contact_Person,
                    ProductType: duplicatedRecord!.ProductType,
            
                    //Subsection 1
                    Price_KG: duplicatedRecord!.Price_KG,
                    Price_KG_Currency: duplicatedRecord!.Price_KG_Currency,
                    Fragrance_Cost: duplicatedRecord!.Fragrance_Cost,
                    Fragrance_Cost_Currency: duplicatedRecord!.Fragrance_Cost_Currency,
                    Price_Purchasing_Extra: duplicatedRecord!.Price_Purchasing_Extra,
            
                    //Subsection 2
                    Sample_Received: duplicatedRecord!.Sample_Received,
                    Product_Reference: duplicatedRecord!.Product_Reference,
                    Product_Reference_Image: duplicatedRecord!.Product_Reference_Image,
            
                    //Subsection 3
                    Olfactory_Profile: duplicatedRecord!.Olfactory_Profile,
                    Olfactory_Extra: duplicatedRecord!.Olfactory_Extra,
            
                    //Subsection 4
                    Target_End_Customer: duplicatedRecord!.Target_End_Customer,
                    Target_Age_Range: duplicatedRecord!.Target_Age_Range.toString(),
                    Unknown_Age: duplicatedRecord!.Unknown_Age,
            
                    //Subsection 5
                    Colour: duplicatedRecord!.Colour,
                    Colour_Extra: duplicatedRecord!.Colour_Extra,
            
                    //Subsection 6
                    Finished_Product_Appearance: duplicatedRecord!.Finished_Product_Appearance,
                    PH: duplicatedRecord!.PH.toString(),
                    Dosage: duplicatedRecord!.Dosage,
                    Customer_Base: duplicatedRecord!.Customer_Base,
                    Customer_Base_Qty: duplicatedRecord!.Customer_Base_Qty,
            
                    //Subsection 7
                    Need_Stability_Test: duplicatedRecord!.Need_Stability_Test,
                    Oven_Temperature: duplicatedRecord!.Oven_Temperature,
                    Test_Duration: duplicatedRecord!.Test_Duration,
                    Stability_Extra: duplicatedRecord!.Stability_Extra,
            
                    //Subsection 8
                    Cosmos_Ecocert: duplicatedRecord!.Cosmos_Ecocert,
                    Flower_Ecolabel: duplicatedRecord!.Flower_Ecolabel,
                    California: duplicatedRecord!.California,
                    South_Korea: duplicatedRecord!.South_Korea,
                    Blauer_Engel: duplicatedRecord!.Blauer_Engel,
                    Nordic_Swan: duplicatedRecord!.Nordic_Swan,
                    Oko_Test: duplicatedRecord!.Oko_Test,
                    Bra_Miljoval: duplicatedRecord!.Bra_Miljoval,
                    IFRA: duplicatedRecord!.IFRA,
                    VOC: duplicatedRecord!.VOC,
                    Regulations_Extra: duplicatedRecord!.Regulations_Extra,
                    Allergen_Restrictions: duplicatedRecord!.Allergen_Restrictions,
                    
                    //Subsection 9
                    Deadline: duplicatedRecord!.Deadline,
                    Maximum_Samples_Submit: duplicatedRecord!.Maximum_Samples_Submit,
                    Fragrance_Qty: duplicatedRecord!.Fragrance_Qty,
                    Finished_Product: duplicatedRecord!.Finished_Product,

                    // Subsection 10
                    Olfactory_Piramid: duplicatedRecord!.Olfactory_Piramid,
                    Custom_Presentation: duplicatedRecord!.Custom_Presentation,
                    Update_Presentation: duplicatedRecord!.Update_Presentation,
                    Trend_Report: duplicatedRecord!.Trend_Report,
                    Market_Research: duplicatedRecord!.Market_Research,
            
                    //Subsection 11
                    Extra_Information: duplicatedRecord!.Extra_Information,
                };
        
				ProjectService.duplicateProject(data).then((response) => {
                    window.location.reload();
                })
                .catch((error) => {
                    AlertNotification(error, "Could not duplicate project", enqueueSnackbar, {
                        variant: "error",
                    });
                })
            };
            readData(duplicatedRecord)
        }
    },[duplicatedRecord]);

    const renderActions = () => {
        return (
            <Core.Grid container direction="row" justify="flex-end">
                {project.owned && project.Blocked == 0 && (
                    <Core.IconButton onClick={() => onEdit(project)}>
                        <Icons.Edit />
                    </Core.IconButton>
                )}
                <Core.IconButton onClick={() => onDelete(project)}>
                    <Icons.Delete />
                </Core.IconButton>

                <Core.IconButton onClick={() => onSummary(project)}>
                    <Icons.ReadMore />
                </Core.IconButton>

                {project.Archived === 0 && (
                    <Core.IconButton onClick={() => onArchive(project)}>
                        <Icons.Archive />
                    </Core.IconButton>
                )}
                {project.Archived === 1 && (
                    <Core.IconButton onClick={() => onUnArchive(project)}>
                        <Icons.Unarchive />
                    </Core.IconButton>
                )}

                {project.Blocked === 0 && (userRole === "administrator" || userRole === "communications") && (
                    <Core.IconButton onClick={() => onBlock(project)}>
                        <Icons.Key />
                    </Core.IconButton>
                )}
                {project.Blocked === 1 && (userRole === "administrator" || userRole === "communications") && (
                    <Core.IconButton onClick={() => onUnBlock(project)}>
                        <Icons.KeyOff />
                    </Core.IconButton>
                )}
                <Core.IconButton onClick={() => Duplicate(project)}>
                    <Icons.ContentCopy />
                </Core.IconButton>
            </Core.Grid>
        );
    };

    if (smDown) {
        return (
            <Core.Grid container direction="column" className={classes.root}>
                <Core.Grid
                    container
                    direction="row"
                    className={classes.container}
                    alignContent="flex-start"
                    alignItems="flex-end"
                    justify="flex-start"
                >
                    <Core.Grid item xs>
                        <Core.Typography color="primary" variant="h6">
                            {project.Name}
                        </Core.Typography>
                    </Core.Grid>
                    <Core.Grid item>{renderActions()}</Core.Grid>
                </Core.Grid>
                <Core.Grid container direction="column">
                    <Core.Grid container direction="row">
                        <Core.Grid item xs>
                            <Core.Typography color="textSecondary" variant="subtitle2">
                                Created
                            </Core.Typography>
                        </Core.Grid>
                        <Core.Grid item xs>
                            <Core.Typography color="textSecondary" variant="subtitle2">
                                {new Date(project.created_at).toLocaleString()}
                            </Core.Typography>
                        </Core.Grid>
                    </Core.Grid>
                    <Core.Grid container direction="row">
                        <Core.Grid item xs>
                            <Core.Typography color="textSecondary" variant="subtitle2">
                                Completed
                            </Core.Typography>
                        </Core.Grid>
                        <Core.Grid item xs>
                            <Core.Typography color="textSecondary" variant="subtitle2">
                                {project.completed_at ? ( new Date(project.completed_at).toLocaleString()) : (
                                    <Core.Chip label="Not sent" size="small" variant="outlined" />
                                )}
                            </Core.Typography>
                        </Core.Grid>
                    </Core.Grid>
                </Core.Grid>
            </Core.Grid>
        );
    }

    return (
        <Core.TableRow key={project.id}>
            <Core.TableCell>
                <Core.Typography color="primary" variant="body1">
                    {project.Name}
                </Core.Typography>
            </Core.TableCell>
            <Core.TableCell>{new Date(project.created_at).toLocaleString()}</Core.TableCell>
            <Core.TableCell>
                {project.completed_at ? (
                    new Date(project.created_at).toLocaleString()
                ) : (
                    <Core.Chip label="Not sent" size="small" variant="outlined" />
                )}
            </Core.TableCell>
            <Core.TableCell>
                {project.Blocked ? <Core.Chip label="Blocked" size="small" variant="outlined" /> : <Core.Chip label="Not Blocked" size="small" variant="outlined" />}
            </Core.TableCell>
            <Core.TableCell>
                <Core.Grid item xs>
                    {renderActions()}
                </Core.Grid>
            </Core.TableCell>
        </Core.TableRow>
    );
};

export default ProjectCard;
