import React from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import * as Core from "@material-ui/core";
import { UserTypes } from "../../../Helpers/ApplicationConstants";
import { userService } from "../../../Api/Services/UserService";
import { authService } from "../../../Api/Services/AuthService";
import { CreateUserCommand, EditUserCommand, UserDTO } from "../../../Api/Models/UserModels";
import { AlertNotification } from "../../Common/Components/Error/AlertNotification";
import Loading from "../../Common/Components/Loading";

interface IFormValues {
    name: string;
    email: string;
    password?: string;
    password_confirm?: string;
    user_type: number;
    supervisor: string;
}

const InitialValues: IFormValues = {
    name: "",
    email: "",
    password: "",
    password_confirm: "",
    user_type: 3,
    supervisor: "",
};

interface IUserFormProps {
    isFormOpen: boolean;
    closeForm: () => void;
    handleSuccess: () => void;
    userRecord: UserDTO | undefined;
    users: UserDTO[];
}

const UserForm = ({
    isFormOpen,
    closeForm,
    handleSuccess,
    userRecord,
    users,
}: IUserFormProps): JSX.Element => {
    const theme = Core.useTheme();
    const fullScreen = Core.useMediaQuery(theme.breakpoints.down("xs"));
    const { t } = useTranslation("app");
    const { enqueueSnackbar } = useSnackbar();
    const user = userRecord || undefined;
    const {handleSubmit,register,reset,getValues,formState: { errors },} = useForm<IFormValues>({defaultValues: InitialValues,});
    const [validateAccount, setValidateAccount] = React.useState<boolean>(false);

    React.useEffect(() => {
        const defaultValues: IFormValues = user ?  {name: user.name, email: user.email, user_type: user.rol?.id || 3, supervisor: user.supervisor_id || "",} : InitialValues;
        reset(defaultValues);

        if (userRecord && userRecord?.supervisor_id === null)
        {
            setValidateAccount(true);
        }
    },[userRecord, reset]);

    const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);

    const passwordValidatorOptions = {
        required: "Required",
        minLength: { value: 6, message: "Password must be at least 6 charactes" },
        maxLength: { value: 12, message: "Password must be at max 12 charactes" },
    };

    const passwordConfirmValidatorOptions = {validate: (value: string | undefined) => (value && value === getValues().password) || "Passwords does not match",};

    const { ref: nameRef, ...nameInputProps } = register("name", {required: "Required",});
    const { ref: emailRef, ...emailInputProps } = register("email", {
        required: "Required",
        pattern: {
            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
            message: "invalid email address",
        },
    });
    const { ref: passwordRef, ...passwordInputProps } = register("password", user ? undefined : passwordValidatorOptions);
    const { ref: passwordConfirmRef, ...passwordConfirmInputProps } = register("password_confirm", user ? undefined : passwordConfirmValidatorOptions);
    const { ref: userTypeRef, ...userTypeInputProps } = register("user_type");
    const { ref: supervisorRef, ...supervisorInputProps } = register("supervisor");

    const handleCloseForm = () => {reset(InitialValues); closeForm();};

    const handleCreateUser = (data: IFormValues) => {
        if (data.password === undefined) {
            throw new Error("Can not create user without password");
        }

        setIsSubmitting(() => true);

        const createUserCommand: CreateUserCommand = {
            name: data.name,
            email: data.email,
            password: data.password,
            user_type: data.user_type,
            supervisor: data.supervisor,
        };

        userService
            .createUser(createUserCommand)
            .then((response) => {
                AlertNotification(
                    t("users.notifications.userCreated"),
                    t("users.notifications.createUser"),
                    enqueueSnackbar,
                    {
                        variant: "success",
                    }
                );

                handleSuccess();
                closeForm();
            })
            .catch((error) => {
                AlertNotification(error, t("users.notifications.createUser"), enqueueSnackbar);
            })
            .finally(() => {
                setIsSubmitting(() => false);
            });
    };

    const handleEditUser = (data: IFormValues) => {
        if (user === undefined) {
            throw new Error("Editing undefined user");
        }

        setIsSubmitting(() => true);

        const editUserCommand: EditUserCommand = {
            id: user.id,
            name: data.name,
            email: data.email,
            user_type: data.user_type,
            supervisor: data.supervisor,
        };

        userService
            .updateUser(editUserCommand)
            .then((response) => {})
            .catch((error) => {
                AlertNotification(error, t("users.notifications.updateUser"), enqueueSnackbar);
            })
            .finally(() => {
                setIsSubmitting(() => false);
            });

            if (validateAccount)
            {
                authService.sendVerificationMail(editUserCommand)
                .then(() =>
                    {
                        AlertNotification(t("users.notifications.userUpdated"),t("users.notifications.updateUser"),enqueueSnackbar,{variant: "success",})
                        handleSuccess();
                        closeForm();
                    }
                )
                .catch((error) => {
                    AlertNotification(error, "Error sending the verification email", enqueueSnackbar);
                })
            }
            else 
            {
                AlertNotification(t("users.notifications.userUpdated"),t("users.notifications.updateUser"),enqueueSnackbar,{variant: "success",})
                handleSuccess();
                closeForm();
            }        
    };

    const renderPassword = () => {
        return (
            <>
                <Core.TextField
                    inputRef={passwordRef}
                    {...passwordInputProps}
                    id="password"
                    label={t("users.user.password")}
                    helperText={errors?.password?.message}
                    error={!!errors.password}
                    fullWidth
                    variant="outlined"
                    inputProps={{ readOnly: isSubmitting }}
                    style={{marginTop: "20px"}}
                />
                <Core.TextField
                    inputRef={passwordConfirmRef}
                    {...passwordConfirmInputProps}
                    id="password_confirm"
                    label={t("users.user.passwordConfirmation")}
                    helperText={errors?.password_confirm?.message}
                    error={!!errors.password_confirm}
                    fullWidth
                    variant="outlined"
                    inputProps={{ readOnly: isSubmitting }}
                    style={{marginTop: "20px"}}
                />
            </>
        );
    };

    const renderUserType = () => {
        return (
            <Core.FormControl variant="outlined" fullWidth style={{marginTop: "20px"}}>
                <Core.InputLabel id="userTypeLabel">{t("users.user.userType")}</Core.InputLabel>
                <Core.Select
                    inputRef={userTypeRef}
                    {...userTypeInputProps}
                    label={t("users.user.userType")}
                    labelId="userTypeLabel"
                    error={!!errors.user_type}
                    fullWidth
                    variant="outlined"
                    defaultValue={getValues("user_type")}
                    inputProps={{ readOnly: isSubmitting }}
                >
                    {Object.values(UserTypes).map((userType) => (
                        <Core.MenuItem key={userType.id} value={userType.id}>
                            {userType.key}
                        </Core.MenuItem>
                    ))}
                </Core.Select>
                <Core.FormHelperText>{errors?.email?.message}</Core.FormHelperText>
            </Core.FormControl>
        );
    };

    const renderManager = () => {
        return (
            <Core.FormControl variant="outlined" fullWidth style={{marginTop: "20px"}}>
                <Core.InputLabel id="supervisorLabel">Coordinator</Core.InputLabel>
                <Core.Select
                    inputRef={supervisorRef}
                    {...supervisorInputProps}
                    label="Coordinator"
                    labelId="supervisorLabel"
                    error={!!errors.supervisor}
                    fullWidth
                    variant="outlined"
                    defaultValue={getValues("supervisor")}
                    inputProps={{ readOnly: isSubmitting }}
                >
                    {users.map((u) => (
                        <Core.MenuItem key={u.id} value={u.id}>
                            {u.email}
                        </Core.MenuItem>
                    ))}
                </Core.Select>
                <Core.FormHelperText>{errors?.supervisor?.message}</Core.FormHelperText>
            </Core.FormControl>
        );
    };

    return (
        <Core.Dialog
            open={isFormOpen}
            onClose={handleCloseForm}
            fullScreen={fullScreen}
            fullWidth={true}
            maxWidth="xs"
            disableBackdropClick={isSubmitting}
            disableEscapeKeyDown={isSubmitting}
        >
            {isSubmitting && (
                <Loading label={t("general.processing")} useCapital useAbsolute useBackground />
            )}

            <form onSubmit={handleSubmit(user ? handleEditUser : handleCreateUser)}>
                <Core.DialogContent>
                    <Core.DialogContentText>
                        {user ? t("users.editUserHelp") : t("users.newUserHelp")}
                    </Core.DialogContentText>
                    <Core.TextField
                        inputRef={nameRef}
                        {...nameInputProps}
                        id="name"
                        label={t("users.user.name")}
                        helperText={errors?.name?.message}
                        error={!!errors.name}
                        fullWidth
                        variant="outlined"
                        inputProps={{ readOnly: isSubmitting }}
                    />
                    <Core.TextField
                        inputRef={emailRef}
                        {...emailInputProps}
                        id="email"
                        label={t("users.user.email")}
                        helperText={errors?.email?.message}
                        error={!!errors.email}
                        fullWidth
                        variant="outlined"
                        inputProps={{ readOnly: isSubmitting }}
                        style={{marginTop: "20px"}}
                    />
                    {user ? null : renderPassword()}
                    {renderUserType()}
                    {renderManager()}
                </Core.DialogContent>
                <Core.DialogActions style={{ marginTop: "20px" }}>
                    <Core.Button disabled={isSubmitting} onClick={closeForm} variant="contained">
                        {t("general.cancel")}
                    </Core.Button>
                    <Core.Button type="submit" disabled={isSubmitting} color="primary" variant="contained">
                        {t("general.save")}
                    </Core.Button>
                </Core.DialogActions>
            </form>
        </Core.Dialog>
    );
};

const UserFormM = React.memo(UserForm);

export default UserFormM;
