import React, { Component } from "react";
import { Route, Redirect, RouteProps } from "react-router-dom";
import { ApplicationPaths } from "./ApiAuthorizationConstants";
import { authService } from "../Api/Services/AuthService";

export default class AuthRoute extends Component<
    RouteProps,
    { ready: boolean; authenticated: boolean }
> {
    constructor(props: RouteProps) {
        super(props);

        this.state = {
            ready: false,
            authenticated: false,
        };
    }

    componentDidMount(): void {
        this.getAuthState();
    }

    componentWillUnmount(): void {}

    async getAuthState(): Promise<void> {
        const authenticated = await authService.isAuthenticated();
        this.setState({ ready: true, authenticated });
    }

    render(): JSX.Element {
        const { ready, authenticated } = this.state;

        const redirectUrl = ApplicationPaths.Login;

        const { component: ComponentToRender, ...rest } = this.props;

        if (!ready) {
            return <div />;
        }

        return (
            <Route
                {...rest}
                render={(props) => {
                    if (authenticated && ComponentToRender) {
                        return <ComponentToRender {...props} />;
                    }
                    return <Redirect to={redirectUrl} />;
                }}
            />
        );
    }
}
