import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
// import emptyList from "../../../../assets/images/empty_list.svg";

const useStyles = makeStyles((theme) => ({
    item: {
        textAlign: "center",
    },
    logo: {
        maxHeight: "40vh",
        width: "70vw",
        maxWidth: "400px",
        opacity: "0.3",
        marginBottom: "50px",
        marginTop: "50px",
    },
}));

const EmptyList = (): JSX.Element => {
    const { t } = useTranslation("app");
    const classes = useStyles();

    return (
        <Grid
            container
            alignContent="center"
            alignItems="center"
            justify="center"
            direction="column"
        >
            <Grid item className={classes.item}>
                {/* <img className={classes.logo} src={emptyList} alt="no elements pic" /> */}
            </Grid>
            <Grid item className={classes.item}>
                <Typography variant="h5" paragraph>
                    {t("general.list.noElements")}
                </Typography>
            </Grid>
        </Grid>
    );
};

export default EmptyList;
