/* eslint-disable  @typescript-eslint/no-explicit-any */
import React from "react";
import clsx from "clsx";
import * as Core from "@material-ui/core";
import * as Icons from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { AppRoutes } from "../../../Helpers/ApplicationConstants";
import { authService } from "../../../Api/Services/AuthService";

export const sidePanelSize = 50;
export const subSidePanelSize = 35;

const useStyles = Core.makeStyles((theme: Core.Theme) =>
    Core.createStyles({
        root: {
            height: "100%",
        },
        rootBottomNavigation: {
            marginBottom: sidePanelSize,
            overflow: "hidden",
        },
        sidePanel: {
            height: "100%",
            backgroundColor: "#FFFFFF",
            borderRightStyle: "solid",
            borderRightColor: "#DDD",
            borderRightWidth: 1,
        },
        sidePanelItem: {
            width: "85px",
            height: "85px",
        },
        sidePanelLink: {
            textDecoration: "none",
            color: theme.palette.text.secondary,
            borderBottom: "1px solid #DDDDDD",
            outline: "none",
        },
        activeSidePanelItem: {
            width: "85px",
            color: theme.palette.primary.main,
            borderLeftColor: theme.palette.primary.main,
            borderLeftWidth: 5,
            borderLeftStyle: "solid",
        },
        bottomNavigation: {
            position: "fixed",
            height: sidePanelSize,
            width: "100%",
            bottom: "0",
            zIndex: 1,
            boxShadow: `0  -1px 3px 0px ${theme.palette.background.default}`,
        },
    })
);

interface ISidePanelItem {
    path: string;
    value: string;
    icon: JSX.Element;
    label: string;
    onClick?: (value: string) => void;
    exact?: boolean;
}

const SidePanelItem = ({ path, value, icon, label, onClick, exact }: ISidePanelItem) => {
    const classes = useStyles();

    return (
        <Core.ButtonBase>
            <NavLink to={path} exact={exact} className={classes.sidePanelLink} activeClassName={classes.activeSidePanelItem} onClick={() => {onClick?.(value);}}>
                <Core.Grid className={classes.sidePanelItem} container direction="column" alignContent="center" alignItems="center" justify="center">
                    <Core.Grid item>{icon}</Core.Grid>
                    <Core.Grid item>
                        <Core.Typography variant="subtitle2">{label}</Core.Typography>
                    </Core.Grid>
                </Core.Grid>
            </NavLink>
        </Core.ButtonBase>
    );
};

const SidePanelItemM = React.memo(SidePanelItem);

export interface SidePanelProps {
    children: JSX.Element;
}

export const SidePanel = ({ children }: SidePanelProps): JSX.Element => {
    const theme = Core.useTheme();
    const classes = useStyles();
    const isXs = Core.useMediaQuery(theme.breakpoints.down("xs"));
    const { t } = useTranslation("app");

    const userRole = authService.getUserRole();
    const [value, setValue] = React.useState("dashboard");
    const handleSidePanelItemChange = (newValue: string) => {setValue(() => newValue);};
    const handleBottomNavigationChange = (event: any, newValue: string) => {setValue(() => newValue);};

    const renderUserSecction = () => {
        if (userRole !== "user") {
            if (isXs) {
                return (
                    <Core.BottomNavigationAction
                        label={t("appbar.users")}
                        value="users"
                        icon={<Icons.Group />}
                        component={NavLink}
                        to={AppRoutes.Administration.Users}
                    />
                );
            }

            return (
                <Core.Grid item>
                    <SidePanelItemM
                        path={AppRoutes.Administration.Users}
                        value="users"
                        icon={<Icons.Group color="inherit" />}
                        label={t("appbar.users")}
                        onClick={handleSidePanelItemChange}
                    />
                </Core.Grid>
            );
        }
        return null;
    };

    return (
        <>
            <Core.Grid className={clsx(classes.root, {[classes.rootBottomNavigation]: isXs,})} container alignContent="stretch" alignItems="stretch" wrap="nowrap">
                {!isXs && (
                    <Core.Grid item>
                        <Core.Grid className={classes.sidePanel} container direction="column" alignContent="center" alignItems="center">
                            <Core.Grid item>
                                <SidePanelItemM
                                    path={AppRoutes.Dashboard}
                                    exact
                                    value="dashboard"
                                    icon={<Icons.Home color="inherit" />}
                                    label={t("appbar.home")}
                                    onClick={handleSidePanelItemChange}
                                />
                            </Core.Grid>
                            <Core.Grid item>
                                <SidePanelItemM
                                    path={AppRoutes.Projects}
                                    value="projects"
                                    icon={<Icons.Ballot color="inherit" />}
                                    label={t("appbar.projects")}
                                    onClick={handleSidePanelItemChange}
                                />
                            </Core.Grid>
                            {renderUserSecction()}
                        </Core.Grid>
                    </Core.Grid>
                )}
                <Core.Grid item xs>
                    {children}
                </Core.Grid>
            </Core.Grid>
            {isXs && (
                <Core.BottomNavigation showLabels className={classes.bottomNavigation} value={value} onChange={handleBottomNavigationChange}>
                    <Core.BottomNavigationAction
                        label={t("appbar.home")}
                        value="dashboard"
                        icon={<Icons.Home />}
                        component={NavLink}
                        to={AppRoutes.Dashboard}
                    />
                    <Core.BottomNavigationAction
                        label={t("appbar.projects")}
                        value="projects"
                        icon={<Icons.Ballot />}
                        component={NavLink}
                        to={AppRoutes.Projects}
                    />
                    {renderUserSecction()}
                </Core.BottomNavigation>
            )}
        </>
    );
};

const SidePanelM = React.memo(SidePanel);

export default SidePanelM;
