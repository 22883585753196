import React from "react";
import Loading from "../Dbrief/Common/Components/Loading";
import { LoginActions, LogoutActions } from "./ApiAuthorizationConstants";

export class AuthRedirect extends React.PureComponent<
    { action: string },
    { message: string | null | undefined }
> {
    constructor(props: { action: string }) {
        super(props);

        this.state = {
            message: undefined,
        };
    }

    render(): JSX.Element {
        const { action } = this.props;
        const { message } = this.state;

        if (message) {
            return <div>{message}</div>;
        }
        switch (action) {
            case LoginActions.LoginCallback:
                return <Loading label="Logging in" />;
            case LogoutActions.LogoutCallback:
                return <Loading label="Logging out" />;
            case LoginActions.Register:
                return <div />;
            default:
                throw new Error(`Invalid action '${action}'`);
        }
    }
}
