import React, { Suspense } from "react";
import { useHistory } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { Grid, Typography, TextField, Button, Container, useMediaQuery } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";

import { authService } from "../../Api/Services/AuthService";
import { UserService } from "../../Api/Services/UserService";
import { EditUserOwnCommand, UserDTO } from "../../Api/Models/UserModels";

import { AlertNotification } from "../Common/Components/Error/AlertNotification";
import Loading from "../Common/Components/Loading";
import SubPanelM from "../Common/Layout/SubPanel";
import { sidePanelSize } from "../Common/Layout/SidePanel";

const useStyles = makeStyles((theme) => ({
    root: {
        [theme.breakpoints.down("xs")]: {
            paddingTop: theme.spacing(1),
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
            paddingBottom: sidePanelSize,
        },
    },
    buttonHolder: {
        marginTop: theme.spacing(2),
    },
    button: {
        marginLeft: theme.spacing(1),
    },
}));

interface IFormValues {
    name: string;
    email: string;
    password: string;
    password_confirm: string;
}

const InitialValues: IFormValues = {
    name: "",
    email: "",
    password: "",
    password_confirm: "",
};

const Profile = (): JSX.Element => {
    const classes = useStyles();
    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.down("xs"));
    const history = useHistory();
    const { t } = useTranslation("app");
    const { enqueueSnackbar } = useSnackbar();

    const { handleSubmit, reset, control, watch } = useForm<IFormValues>({
        defaultValues: InitialValues,
        mode: "onSubmit",
    });

    const userStored = authService.getUser();
    const [user, setUser] = React.useState<UserDTO | undefined>(undefined);

    function GetUser() {
        UserService.getUser(userStored.id)
            .then((response) => {
                setUser(response);
            })
            .catch((error) => {
                AlertNotification(error, "GetUser", enqueueSnackbar, { variant: "warning" });
            });
    }

    React.useEffect(() => {
        GetUser();
    }, []);

    React.useEffect(() => {
        const defaultValues: IFormValues = user
            ? {
                  name: user.name,
                  email: user.email,
                  password: "",
                  password_confirm: "",
              }
            : InitialValues;

        reset(defaultValues);
    }, [user, reset]);

    const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);

    const handleCancel = () => {
        reset();
        history.goBack();
    };

    const handleEditSuccess = () => {
        AlertNotification(
            t("profile.notifications.profileUpdated"),
            t("profile.notifications.updateProfile"),
            enqueueSnackbar,
            {
                variant: "success",
            }
        );

        GetUser();
    };

    const handleEditUser = (data: IFormValues) => {
        if (user === undefined) {
            throw new Error("Editing undefined user");
        }

        setIsSubmitting(() => true);

        const editUserOwnCommand: EditUserOwnCommand = {
            id: user.id,
            name: data.name,
            email: data.email,
            password: data.password,
        };

        UserService.updateUserOwn(editUserOwnCommand)
            .then((response) => {
                const localUser = authService.getUser();
                authService.setUserData({
                    ...localUser,
                    name: editUserOwnCommand.name,
                    email: editUserOwnCommand.email,
                });
                handleEditSuccess();
            })
            .catch((error) => {
                AlertNotification(error, t("profile.notifications.updateProfile"), enqueueSnackbar);
            })
            .finally(() => {
                setIsSubmitting(() => false);
            });
    };

    const renderPassword = () => {
        return (
            <>
                <Controller
                    name="password"
                    control={control}
                    defaultValue=""
                    render={({ field, fieldState }) => (
                        <TextField
                            {...field}
                            fullWidth
                            variant="outlined"
                            label="Password"
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message}
                            disabled={isSubmitting}
                            style={{marginTop: "20px"}}
                        />
                    )}
                    rules={{
                        required: "User password is required",
                        minLength: { value: 6, message: "Password must be at least 6 charactes" },
                        maxLength: { value: 12, message: "Password must be at max 12 charactes" },
                        validate: (val) =>
                            val === watch("password_confirm") || "Passwords don't match.",
                    }}
                />

                <Controller
                    name="password_confirm"
                    control={control}
                    defaultValue=""
                    render={({ field, fieldState }) => (
                        <TextField
                            {...field}
                            fullWidth
                            variant="outlined"
                            label="Password confirmation"
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message}
                            disabled={isSubmitting}
                            style={{marginTop: "20px"}}
                        />
                    )}
                    rules={{
                        required: "User password is required",
                        minLength: { value: 6, message: "Password must be at least 6 charactes" },
                        maxLength: { value: 12, message: "Password must be at max 12 charactes" },
                        validate: (val) => val === watch("password") || "Passwords don't match.",
                    }}
                />
            </>
        );
    };

    const renderForm = () => {
        return (
            <Suspense fallback={<Loading />}>
                <form onSubmit={handleSubmit(handleEditUser)}>
                    {isXs && (
                        <Grid item>
                            <Typography variant="h4" color="primary">
                                {t("appbar.profile")}
                            </Typography>
                        </Grid>
                    )}
                    <Grid item>
                        <Controller
                            name="name"
                            control={control}
                            defaultValue=""
                            render={({ field, fieldState }) => (
                                <TextField
                                    {...field}
                                    fullWidth
                                    variant="outlined"
                                    label="Name"
                                    error={!!fieldState.error}
                                    helperText={fieldState.error?.message}
                                    disabled={isSubmitting}
                                    style={{marginTop: "20px"}}
                                />
                            )}
                            rules={{ required: "User name is required" }}
                        />
                    </Grid>
                    <Grid item>
                        <Controller
                            name="email"
                            control={control}
                            defaultValue=""
                            render={({ field, fieldState }) => (
                                <TextField
                                    {...field}
                                    fullWidth
                                    variant="outlined"
                                    label="Email"
                                    error={!!fieldState.error}
                                    helperText={fieldState.error?.message}
                                    disabled={isSubmitting}
                                    style={{marginTop: "20px"}}
                                />
                            )}
                            rules={{
                                required: "User email is required",
                                pattern: {
                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                    message: "Invalid email address",
                                },
                            }}
                        />
                    </Grid>
                    {renderPassword()}
                    {isXs && (
                        <Grid
                            container
                            direction="row"
                            className={classes.buttonHolder}
                            alignContent="flex-end"
                            alignItems="flex-end"
                            justify="flex-end"
                            style={{ marginTop: 40 }}
                        >
                            <Button
                                className={classes.button}
                                disabled={isSubmitting}
                                onClick={handleCancel}
                                variant="contained"
                                size="large"
                            >
                                {t("general.cancel")}
                            </Button>
                            <Button
                                className={classes.button}
                                type="submit"
                                disabled={isSubmitting}
                                color="primary"
                                variant="contained"
                                size="large"
                            >
                                {t("general.save")}
                            </Button>
                        </Grid>
                    )}
                </form>
            </Suspense>
        );
    };

    const subPanelItems = () => [
        {
            action: () => {
                handleCancel();
            },
            label: t("general.cancel"),
        },
        {
            action: handleSubmit(handleEditUser),
            label: t("general.save"),
        },
    ];

    const renderSubPanel = () => {
        if (isXs) {
            return renderForm();
        }
        return (
            <SubPanelM title={t("appbar.profile")} itemsList={subPanelItems()} padContent>
                {renderForm()}
            </SubPanelM>
        );
    };

    return (
        <Container maxWidth="md" className={classes.root}>
            {renderSubPanel()}
        </Container>
    );
};

export default Profile;
