/* eslint-disable */
import axios from "../AxiosConfig";
import { APIHost, APIResetPassword } from "../ApiConstants";
import { ForgotPasswordCommand, ResetPasswordCommand } from "../Models/UserModels";

export class ResetPasswordService {

    async resetPassword(data: ResetPasswordCommand) {
        // //console.log(data);
        return await axios
            .post(`${APIHost}${APIResetPassword}/reset-password`, data, {
                headers: {
                    "Content-Type": "application/json",
                },
            })
            .then((response) => {
                return response;
            })
            .catch((error) => {
                //console.log(error);
                if (error.response) 
                {
                    //console.log(error.response);
                }
            });
    }

    async sendForgotPasswordMail(data: ForgotPasswordCommand) {
        // //console.log(data);
        return await axios
            .post(`${APIHost}${APIResetPassword}/checkEmail`, data, {
                headers: {
                    "Content-Type": "application/json",
                },
            })
            .then((response) => {
                return response;
            })
            .catch((error) => {
                //console.log(error);
                if (error.response) 
                {
                    //console.log(error.response);
                }
            });
    }
}

export const resetPasswordService = new ResetPasswordService();